import React from "react"
import NavBar from "./NavBar"
import NavLeft from "./NavLeft"
import { ROUTES } from "../../utils/constants"
import { useLocation } from "react-router-dom"
import Socket from "../socket/Socket"

interface Props{
    children: React.ReactNode
}

const TemplateLogined = React.memo((props: Props) =>{
    const route = useLocation();
    const isWorkingRoute = [ROUTES.ROOT, ROUTES.DOING, ROUTES.TODO, ROUTES.WAITING, ROUTES.PERSONAL].includes(route.pathname)

    return(
        <>
            {isWorkingRoute && <NavLeft />}
            <div className={`nav-center ${isWorkingRoute ? '': 'no-margin'}`}>
                <NavBar />
                <div className='main-content'>
                    {props.children}
                </div>
            </div>
            <Socket />
        </>
    )
})

export default TemplateLogined