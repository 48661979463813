import { createStore, applyMiddleware, combineReducers, Middleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';
import { persistStore } from 'redux-persist'
import state from '../reducers';
import rootSaga from '../sagas';

const mainLog = createLogger({
    collapsed: true,
    duration: true,
});

const sagaMiddleware = createSagaMiddleware()

const GLOBAL_STATE = combineReducers(state);
const MIDDLEWARE: Middleware[] = [sagaMiddleware];

if (process.env.NODE_ENV !== 'production') {
    MIDDLEWARE.push(mainLog);
}

const createStoreWithMiddleware = process.env.NODE_ENV !== 'production'
    ? compose(applyMiddleware(...MIDDLEWARE))(createStore)
    : applyMiddleware(...MIDDLEWARE)(createStore);

export const store = createStoreWithMiddleware(GLOBAL_STATE, undefined);

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof GLOBAL_STATE>;
