import axios from 'axios';
import { END_POINTS } from '..';
import location from '../../models/locaion';

export const getListProvinceApi = () =>{
    const url = END_POINTS.getListProvince;
	return axios.get(url).then((res: { data: location.ProvinceResponse }) => res.data);
}

export const getListDistrictApi = (params: {province_id: string}) =>{
    const url = END_POINTS.getListDistrict;
	return axios.get(url, {params}).then((res: { data: location.DistrictResponse }) => res.data);
}

export const getListWardApi = (params: {district_id: string}) =>{
    const url = END_POINTS.getListWard;
	return axios.get(url, {params}).then((res: { data: location.WardResponse }) => res.data);
}