import React, { memo } from 'react';
import Lottie from 'react-lottie';
import { LOTTIE } from '../../assets';

const defaultOptions = {
    loop: false,
    autoplay: true, 
    animationData: LOTTIE.EMPTY
};

type Props = {
    title?: string
}

const Empty = ({title}: Props) =>{
    return(
        <div className="item-center">
            <Lottie options={defaultOptions}
                height='20vh'
                width='20vh'
            />
            <p className='text=large'>{title || 'Không có dữ liệu'}</p>
        </div>
    )
}

export default memo(Empty);