import axios from 'axios';
import { END_POINTS } from '..';
import newfeed from '../../models/newfeed';

export const getListNewfeedApi = (params: newfeed.NewfeedRequest) =>{
    const url = END_POINTS.getListNewfeed;
	return axios.get(url, {params}).then((res: { data: newfeed.NewfeedResponse }) => res.data);
}

export const getListNewfeedAdminApi = (params: {page_id: number}) =>{
    const url = END_POINTS.getListNewfeedAdmin;
	return axios.get(url, {params}).then((res: { data: newfeed.NewfeedResponse }) => res.data);
}