import { Button, Modal } from "antd";
import { PROP } from "../../utils/constants";

type Props = {
    showModal: boolean;
    onClose: () => void;
    title: string;
    onConfirm: () => void
}

const ModalConfirm = (props: Props) =>{
    const { showModal, onClose, title, onConfirm } = props;

    const handleConfirm = () =>{
        onConfirm();
        onClose()
    }

    return(
        <Modal
            {...PROP.MODAL}
            bodyStyle={{background: 'red'}}
            open={showModal}
            onCancel={onClose}
        >
            <p className="text-center text-huge mgt-4 color-white">{title}</p>
            <div className="item-center">
                <Button className="mgt-4" onClick={handleConfirm}>Xác nhận</Button> 
            </div>
        </Modal>
    )
}

export default ModalConfirm