import { combineReducers } from "redux";
import user from "../../models/user";
import persistReducerUtil from "../../utils/common";
import { userActions, userActionTypes } from "../actions/user";

export const USER_INITIAL_STATE: user.State = {
    userData: {
        user_id: '',
        avatar: '',
        phone_number: '',
        role_id: '1',
        is_block: '',
        cr_date_time: '',
        branch_id: '',
        user_name: '',
        birthday: '',
        address: '',
        join_date: '',
        position_name: ''
    },
    countData:{
        countPersonal: '0',
        countSchoolCompany: '--',
        countSchoolTodo: '--',
        countSchoolDoing: '--',
        countSchoolWaiting: '--',
    }
};

const userData = (
    state: user.User = USER_INITIAL_STATE.userData,
    action: userActionTypes.getUserDataSuccessActionType | userActionTypes.changeBranchSuccessActionType
) => {
    switch (action.type) {
        case userActions.GET_USER_DATA_SUCCESS:
            return action.payload;
        case userActions.CHANGE_BRANCH_SUCCESS:
            return {
                ...state,
                branch_id: action.payload
            };
        default:
            return state;
    }
}

const countData = (
    state: user.CountData = USER_INITIAL_STATE.countData,
    action: userActionTypes.getCountDataSuccessActionType
) => {
    switch (action.type) {
        case userActions.GET_COUNT_DATA_SUCCESS:
            return action.payload;
        default:
            return state;
    }
}

const userReducers = combineReducers<user.State>({
    userData,
    countData
});

export default persistReducerUtil(
    'user',
    userReducers,
    ['userData', 'countData'],
);
