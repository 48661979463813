import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import ScrollToTop from './components/common/ScrollToTop';
import LobbyRoute from './components/route/LobbyRoute';
import PrivateRoute from './components/route/PrivateRoute';
import { ROUTES } from './utils/constants';
import Lobby from './components/route/Lobby';
import Private from './components/route/Private';
import { PersistGate } from 'redux-persist/integration/react'
import { persistor, store } from './redux/store';

import "react-datepicker/dist/react-datepicker.css";
import 'react-quill/dist/quill.snow.css';

//config axios header token
import Cookies from 'js-cookie';
import { KEY } from './utils/constants';
import axios from 'axios';
import MobileDrawer from './components/common/MobileDrawer';
axios.defaults.headers.common['Auth'] = Cookies.get(KEY.TOKEN);
//

function App() {

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
          <Router>
          <MobileDrawer>
            <ScrollToTop />
            <Switch>
              <LobbyRoute path={ROUTES.LOGIN}>
                <Lobby />
              </LobbyRoute>
              <PrivateRoute path={ROUTES.ROOT}>
                <Private />
              </PrivateRoute>
            </Switch>
            </MobileDrawer>
          </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
