import axios from 'axios';
import { END_POINTS } from '..';
import models from '../../models';
import user from '../../models/user';
const qs = require('qs');

export const getListUserApi = (params: user.UserSearchRequest) =>{
    const url = END_POINTS.getListUser;
	return axios.get(url, {params}).then((res: { data: user.UserSearchResponse }) => res.data);
}

export const getUserDataApi = () =>{
    const url = END_POINTS.getUserData;
	return axios.get(url).then((res: { data: user.UserResponse }) => res.data);
}

export const getDataTooltipUserApi = ({user_id}: {user_id: string}) =>{
    const url = END_POINTS.getDataTooltipUser;
	return axios.get(url, {params: {user_id}}).then((res: { data: user.UserResponse }) => res.data);
}

export const changePasswordApi = (params: user.ChangePassParams) =>{
    const url = END_POINTS.changePassword;
	return axios.post(url, qs.stringify(params)).then((res: { data: models.DefaultResponse }) => res.data);
}

export const getUserDataExtendApi = () =>{
    const url = END_POINTS.getUserDataExtend;
	return axios.get(url).then((res: { data: user.UserExtendResponse }) => res.data);
}

export const updateUserApi = (params: user.AddUserRequest) =>{
    const url = END_POINTS.updateUser;
	return axios.post(url, qs.stringify(params)).then((res: { data: models.DefaultResponse }) => res.data);
}

export const getListUserFromManagerApi = (params: user.UserSearchRequest) =>{
    const url = END_POINTS.getListUserFromManager;
	return axios.get(url, {params}).then((res: { data: user.UserFromManagerResponse }) => res.data);
}

export const updateBranchApi = (params: user.AddBranchRequest) =>{
    const url = END_POINTS.updateBranch;
	return axios.post(url, qs.stringify(params)).then((res: { data: models.DefaultResponse }) => res.data);
}

export const getListManagerFromGeneralManagerApi = (params: user.UserSearchRequest) =>{
    const url = END_POINTS.getListManagerFromGeneralManager;
	return axios.get(url, {params}).then((res: { data: user.UserFromManagerResponse }) => res.data);
}

export const toggleBlockUserApi = (params: user.ToggleBlockUserRequest) =>{
    const url = END_POINTS.toggleBlockUser;
	return axios.post(url, qs.stringify(params)).then((res: { data: models.DefaultResponse }) => res.data);
}

export const resetPasswordApi = (params: {user_id: string}) =>{
    const url = END_POINTS.resetPassword;
	return axios.post(url, qs.stringify(params)).then((res: { data: models.DefaultResponse }) => res.data);
}

export const uploadAvatarApi = (params: {avatar: string}) =>{
    const url = END_POINTS.uploadAvatar;
	return axios.post(url, qs.stringify(params)).then((res: { data: user.UploadAvatarResponse }) => res.data);
}

export const changeBranchApi = (params: {branch_id: string}) =>{
    const url = END_POINTS.changeBranch;
	return axios.post(url, qs.stringify(params)).then((res: { data: user.UserResponse }) => res.data);
}

export const getCountDataApi = () =>{
    const url = END_POINTS.getCountData;
	return axios.get(url).then((res: { data: user.CountDataResponse }) => res.data);
}