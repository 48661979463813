import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import models from "../models";
import user from "../models/user";
import { userDataSelector } from "../redux/selectors/user";
import { Button, Modal, Switch } from "antd";
import ModalAddUser from "../components/modals/ModalAddUser";
import { getListUserFromManagerApi, resetPasswordApi, toggleBlockUserApi } from "../apis/user";
import { ADD_USER_COLLAPSE_TYPE, GENDER_NAME, MESSAGES, PROP, RELATIONSHIP_NAME, RESPONSE_CODE, USER_PROPERTY_NAME, USER_ROLE_NAME } from "../utils/constants";
import { showNotification } from "../utils/notification";
import Loading from "../components/common/Loading";
import TooltipUser from "../components/common/TooltipUser";
import Icon from 'react-fontawesome';
import { convertDate } from "../utils/common";

interface Props extends RouteComponentProps {
    userData: user.User
}

const AddUser = React.memo((props: Props) => {
    const { userData } = props;
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [isPress, setIsPress] = useState<boolean>(false)
    const [showModalAddUser, setShowModalAddUser] = useState<boolean>(false)

    const [users, setUsers] = useState<user.UserFromManager[]>([])
    const [userSelected, setUserSelected] = useState<user.UserFromManager>({} as user.UserFromManager)
    const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false)
    const [searchText, setSearchText] = useState<string>('')
    const [property, setProperty] = useState<string>('0')

    const [collapseType, setCollapseType] = useState<number>(ADD_USER_COLLAPSE_TYPE.FULL)

    const timeoutRef = useRef<NodeJS.Timeout>()

    const toggleModalAddUser = () => {
        setUserSelected({} as user.UserFromManager)
        setShowModalAddUser(!showModalAddUser)
    }

    const toggleModalConfirm = () => {
        setShowModalConfirm(!showModalConfirm)
        setUserSelected({} as user.UserFromManager)
    }

    const getListUser = async (text: string = '', property = '0') => {
        try {
            const response = await getListUserFromManagerApi({ searchText: text, property })
            if (response.code === RESPONSE_CODE.SUCCESS) {
                setIsLoading(false)
                setUsers(response.data)
            }
            else showNotification(response.message, response.code)
        } catch (error) {
            showNotification(MESSAGES.ERROR, RESPONSE_CODE.ERROR)
        }
    }

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSearchText(value)
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
            getListUser(value, property)
        }, 500)
    }

    const handleChangeIsBlock = (user: user.UserFromManager) => async () => {
        try {
            const isBlock = user.is_block === '0' ? '1' : '0';
            setUsers(users.map(i => i.user_id === user.user_id ? { ...i, is_block: isBlock } : i))
            const response = await toggleBlockUserApi({
                user_id: user.user_id,
                is_block: isBlock
            })
            showNotification(response.message, response.code)
            if (response.code !== RESPONSE_CODE.SUCCESS) {
                setUsers(users.map(i => i.user_id === user.user_id ? { ...i, is_block: user.is_block } : i))
            }
        } catch (error) {
            showNotification(MESSAGES.ERROR, RESPONSE_CODE.ERROR)
            setUsers(users.map(i => i.user_id === user.user_id ? { ...i, is_block: user.is_block } : i))
        }
    }

    const handleEdit = (userData: user.UserFromManager) => () => {
        setUserSelected(userData)
        setShowModalAddUser(true)
    }

    const showModalReset = (userData: user.UserFromManager) => () => {
        setUserSelected(userData)
        setShowModalConfirm(true)
    }

    const handleConfirmResetPass = async () => {
        try {
            setIsPress(true)
            const response = await resetPasswordApi({ user_id: userSelected.user_id })
            setIsPress(false)
            showNotification(response.message, response.code)
            if (response.code === RESPONSE_CODE.SUCCESS) {
                setShowModalConfirm(false)
            }
        } catch (error) {
            setIsPress(false)
            showNotification(MESSAGES.ERROR, RESPONSE_CODE.ERROR)
        }
    }

    const handleChangeCollapseType = (type: number) => () => {
        setCollapseType(type)
    }

    const handleSelectProperty = ({ target: { value } }: React.ChangeEvent<HTMLSelectElement>) => {
        setProperty(value)
        getListUser(searchText, value)
    }

    const handleOpenLink = (link: string) => () => {
        window.open(link, '_blank')
    }

    const renderCollapseType = () => {
        if (collapseType === ADD_USER_COLLAPSE_TYPE.FULL) {
            return (
                <>
                    <div className="row row-list-user">
                        <div className="col-md-6 col-sm-12 col-xs-12 flex align-center">
                            <TooltipUser data={userData} />
                            <div className="mgl">
                                <p className="color-white bold">{userData.user_name} - {userData.phone_number} - ID: {userData.user_login} </p>
                                <p className="color-white">{USER_ROLE_NAME[userData.role_id]}</p>
                            </div>
                        </div>
                    </div>
                    <div style={{ height: '4vh' }}></div>
                    {users.map((user) =>
                        <div className="row row-list-user" key={user.user_id}>
                            <div className="col-md-6 col-sm-12 col-xs-12 flex align-center">
                                <TooltipUser data={user} />
                                <div className="mgl">
                                    <p className="color-white">{user.user_name} - {user.phone_number} - ID: {user.user_login} </p>
                                    <p className="color-white">{USER_ROLE_NAME[user.role_id]} - Chi nhánh: {user.branch_name}</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12 col-xs-12 flex align-center justify-end">
                                <p
                                    className="pointer"
                                    onClick={showModalReset(user)}
                                >
                                    <u className="color-white">Reset mật khẩu</u>
                                </p>
                                <p
                                    className="mgl-2 mgr-2 pointer"
                                    onClick={handleEdit(user)}
                                >
                                    <u className="color-white">Sửa</u>
                                </p>
                                <Switch
                                    checked={user.is_block === '0'}
                                    onChange={handleChangeIsBlock(user)}
                                />
                            </div>
                        </div>
                    )}
                </>
            )
        }
        return (
            <>
                <div className="table-user-container">
                    <table>
                        <thead>
                            <tr>
                                <th style={{width: '6vh'}}>STT</th>
                                <th>Tên nhân sự</th>
                                <th style={{width: '16vh'}}>SĐT</th>
                                <th>Tên đăng nhập</th>
                                <th>Phân loại</th>
                                <th>Chức vụ</th>
                                <th>Tính chất nhân sự</th>
                                <th style={{width: '16vh'}}>Ngày sinh</th>
                                <th style={{width: '16vh'}}>Ngày vào công ty</th>
                                <th>Quê quán</th>
                                <th>Bảng lương</th>
                                <th>STK Ngân hàng</th>
                                <th>Giới tính</th>
                                <th>MST cá nhân</th>
                                <th>Số sổ BHXH</th>
                                <th>Số CCCD</th>
                                <th>Chỗ ở hiện nay</th>
                                <th>Hợp đồng lao động</th>
                                <th>Ngày ký HĐ</th>
                                <th>Ngày hết hạn HĐ</th>
                                <th>Tình trạng hôn nhân</th>
                                <th>Số con</th>
                                <th>Hồ sơ xin việc</th>
                                <th>Ngày thôi việc:</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map((user, key) => {
                                return (
                                    <tr
                                        key={user.user_id}
                                        // onClick={handleEdit(user)}
                                    >
                                        <td><p>{key + 1}</p></td>
                                        <td>
                                            <div className="item-center">
                                                <TooltipUser data={user} key={key} className='avatar-list-school' />
                                                <p>{user.user_name}</p>
                                            </div>
                                        </td>
                                        <td><p>{user.phone_number}</p></td>
                                        <td><p>{user.user_login}</p></td>
                                        <td><p>{USER_ROLE_NAME[user.role_id]}</p></td>
                                        <td><p>{user.position_name}</p></td>
                                        <td><p>{+user.user_property ? USER_PROPERTY_NAME[user.user_property] : ''}</p></td>
                                        <td><p>{convertDate(user.birthday)}</p></td>
                                        <td><p>{convertDate(user.join_date)}</p></td>
                                        <td><p>{user.address}</p></td>
                                        <td><p className="pointer" onClick={handleOpenLink(user.salary_link)}>{user.salary_link}</p></td>
                                        <td><p>{user.bank_info}</p></td>
                                        <td><p>{GENDER_NAME[user.gender]}</p></td>
                                        <td><p>{user.mst}</p></td>
                                        <td><p>{user.bhxh}</p></td>
                                        <td><p>{user.cccd}</p></td>
                                        <td><p>{user.address_current}</p></td>
                                        <td><p className="pointer" onClick={handleOpenLink(user.contract_link)}>{user.contract_link}</p></td>
                                        <td><p>{convertDate(user.contract_date)}</p></td>
                                        <td><p>{convertDate(user.contract_expire_date)}</p></td>
                                        <td><p>{RELATIONSHIP_NAME[user.relationship]}</p></td>
                                        <td><p>{user.children_num}</p></td>
                                        <td><p>{convertDate(user.severance_day)}</p></td>
                                        <td><p className="pointer" onClick={handleOpenLink(user.resign_link)}>{user.resign_link}</p></td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </>
        )
    }

    useEffect(() => {
        getListUser()
    }, [])

    return (
        <div>
            <div className="relative">
                <div className="item-center mgt-2">
                    <Button onClick={toggleModalAddUser}>Thêm nhân sự</Button>
                </div>
                <div className="user-select-collapse">
                    <Icon
                        name='list'
                        className={`icon-select-collapse ${collapseType === ADD_USER_COLLAPSE_TYPE.FULL ? 'warning' : ''}`}
                        onClick={handleChangeCollapseType(ADD_USER_COLLAPSE_TYPE.FULL)}
                    />
                    <Icon
                        name='columns'
                        className={`icon-select-collapse ${collapseType === ADD_USER_COLLAPSE_TYPE.TABLE ? 'warning' : ''}`}
                        onClick={handleChangeCollapseType(ADD_USER_COLLAPSE_TYPE.TABLE)}
                    />
                </div>
            </div>
            {isLoading ? <Loading /> :
                <>
                    <div className="row mgt mgb">
                        <div className="col-md-6 col-sm-12 col-xs-12 pd">
                            <input
                                className="form-control"
                                value={searchText}
                                onChange={handleSearch}
                                placeholder="Tìm kiếm theo tên nhân sự"
                            />
                        </div>
                        <div className="col-md-6 col-sm-12 col-xs-12 pd">
                            <select
                                className='form-control'
                                onChange={handleSelectProperty}
                                value={property}
                            >
                                {Object.keys(USER_PROPERTY_NAME).map((key) =>
                                    <option value={key}>{USER_PROPERTY_NAME[key]}</option>
                                )}
                            </select>
                        </div>
                    </div>
                    {renderCollapseType()}
                </>
            }
            <ModalAddUser
                onClose={toggleModalAddUser}
                showModal={showModalAddUser}
                onSuccess={getListUser}
                userSelected={userSelected}
            />
            <Modal
                {...PROP.MODAL}
                open={showModalConfirm}
                onCancel={toggleModalConfirm}
            >
                <div className="flex-column item-center mgt-3">
                    <p className="text-center text-large mgb-2">Xác nhận khôi phục về mật khẩu mặc định {userSelected.user_pass_default}?</p>
                    <TooltipUser data={userSelected} />
                    <p className="mgt-2">{userSelected.user_name}</p>
                    <Button
                        className="mgt-4"
                        loading={isPress}
                        onClick={handleConfirmResetPass}
                    >
                        Xác nhận
                    </Button>
                </div>
            </Modal>
        </div>
    )
})

const mapStateToProps = (state: models.GlobalState) => ({
    userData: userDataSelector(state)
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddUser));