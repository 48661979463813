import { createSelector } from 'reselect';
import models from '../../models';
import school from '../../models/school';
import { SCHOOL_INITIAL_STATE } from '../reducers/school';

const schoolSelector = (state: models.GlobalState) => state.school;

export const schoolsSelector = createSelector(
    schoolSelector,
    data => data.schools as school.SchoolData[] ?? SCHOOL_INITIAL_STATE.schools
)

export const schoolSelectedSelector = createSelector(
    schoolSelector,
    data => data.selectedSchool as school.SchoolData['school_id'] ?? SCHOOL_INITIAL_STATE.selectedSchool
)

export const schoolSelectedTabSelector = createSelector(
    schoolSelector,
    data => data.selectedTab as string ?? SCHOOL_INITIAL_STATE.selectedTab
)

export const branchsSelector = createSelector(
    schoolSelector,
    data => data.branchs as school.Branch[] ?? SCHOOL_INITIAL_STATE.branchs
)