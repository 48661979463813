import { Button, Modal } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { getListMapApi } from "../../apis/school";
import { changePasswordApi } from "../../apis/user";
import { IMAGES } from "../../assets";
import map from "../../models/map";
import school from "../../models/school";
import { openNewTab } from "../../utils/common";
import { MESSAGES, PROP, RESPONSE_CODE, SCHOOL_STATUS_NAME } from "../../utils/constants";
import { showNotification } from "../../utils/notification";

type Props = {
    showModal: boolean;
    onClose: () => void;
}

const ModalChangePassword = (props: Props) =>{
    const { showModal, onClose } = props;
    const [inputOldPassword, setInputOldPassword] = useState<string>('');
    const [inputPassword, setInputPassword] = useState<string>('');
    const [inputConfirmPassword, setInputConfirmPassword] = useState<string>('');
    const [isPress, setIsPress] = useState<boolean>(false)

    const handleChangePassword =  async() =>{
        try {
            if(!isPress){
                if(inputOldPassword.trim() && inputPassword.trim() && inputConfirmPassword.trim() && inputPassword === inputConfirmPassword){
                    setIsPress(true)
                    const response = await changePasswordApi({user_pass: inputOldPassword, new_pass: inputPassword});
                    showNotification(response.message, response.code)
                    setIsPress(false)
                    if(response.code === RESPONSE_CODE.SUCCESS){
                        onClose();
                    }
                }
                else if(!inputOldPassword.trim()) return showNotification('Bạn chưa nhập mật khẩu cũ', RESPONSE_CODE.ERROR);
                else if(!inputPassword.trim()) return showNotification('Bạn chưa nhập mật khẩu mới', RESPONSE_CODE.ERROR);
                else if(!inputConfirmPassword.trim()) return showNotification('Bạn chưa nhập lại mật khẩu mới', RESPONSE_CODE.ERROR);
                else showNotification('Nhập lại mật khẩu mới phải trùng nhau', RESPONSE_CODE.ERROR);
            }
        } catch (error) {
            showNotification(MESSAGES.ERROR, RESPONSE_CODE.ERROR)
            setIsPress(false)
        }
    }

    return(
        <Modal
            {...PROP.MODAL}
            open={showModal}
            onCancel={onClose}
        >
            <p className="black">Mật khẩu cũ</p>
            <input
                className="form-control"
                value={inputOldPassword}
                onChange={({ target: { value } }) => setInputOldPassword(value)}
                placeholder='Nhập mật khẩu cũ'
                type='password'
            />
            <p className="black mgt-2">Mật khẩu mới</p>
            <input
                className="form-control"
                value={inputPassword}
                onChange={({ target: { value } }) => setInputPassword(value)}
                placeholder='Nhập mật khẩu mới'
                type='password'
            />
            <p className="black mgt-2">Xác nhận mật khẩu</p>
            <input
                className="form-control"
                value={inputConfirmPassword}
                onChange={({ target: { value } }) => setInputConfirmPassword(value)}
                placeholder='Nhập lại mật khẩu mới'
                type='password'
            />
            <div className="item-center mgt-3">
                <Button
                    onClick={handleChangePassword}
                    loading={isPress}
                >
                    Xác nhận
                </Button>
            </div>
        </Modal>
    )
}

export default ModalChangePassword