import { all } from 'redux-saga/effects';
import { locationRuntime } from './location';
import { schoolRuntime } from './school';
import { userRuntime } from './user';

export default function* rootSaga() {
    yield all([
        schoolRuntime(),
        locationRuntime(),
        userRuntime()
    ]);
}
