export const IMAGES = {
    CREATE_WORK: require('./images/create_work.png'),
    POST_PHOTO: require('./images/post_photo.png'),
    POST_VIDEO: require('./images/post_video.png'),
    REPORT: require('./images/report.png'),
    DELETE: require('./images/delete.png'),
    ZALO: require('./images/zalo.png'),
    USER_EMPTY: require('./images/user_empty.png'),
    USER: require('./images/user.png'),
    EDIT: require('./images/edit.png'),
    EDIT_WHITE: require('./images/edit-white.png'),
    CHEV_DOWN: require('./images/chev-down.png'),
    CHEV_UP: require('./images/chev-up.png'),
    CHEV_DOWN_BLUE: require('./images/chev-down-blue.png'),
    CHEV_UP_BLUE: require('./images/chev-up-blue.png'),
    CHEV_LEFT: require('./images/chev-left.png'),
    CHEV_RIGHT: require('./images/chev-right.png'),
    PLUS: require('./images/plus.png'),
    SCHOOL_PLACEHOLDER: require('./images/school_placeholder.png'),
    UPLOAD_AVATAR: require('./images/upload_avatar.png'),
    MAP: require('./images/map.png'),
    FOLDER: require('./images/folder.png'),
    LOGO:  require('./images/logo.jpg'),
    UPDOWN:  require('./images/updown.png'),
}

export const LOTTIE = {
    LOADING: require('./lottie/loading.json'),
    EMPTY: require('./lottie/empty.json'),
    COMPLETE: require('./lottie/complete.json'),
}