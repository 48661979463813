import { Tooltip } from "antd";
import React, { useState } from "react";
import { connect } from "react-redux";
import { getDataTooltipUserApi } from "../../apis/user";
import models from "../../models";
import user from "../../models/user";
import { userDataSelector } from "../../redux/selectors/user";
import { convertDate, getAvatar, isTouch } from "../../utils/common";
import { MESSAGES, RESPONSE_CODE, USER_ROLE_NAME } from "../../utils/constants";
import { showNotification } from "../../utils/notification";
import Loading from "./Loading";

type Props = {
    className?: string;
    data: { user_id: string, avatar: string };
    userData: user.User;
    component?: React.ReactNode;
    disabled?: boolean
}

const TooltipUser = (props: Props) => {
    const { className, data, component, disabled } = props;
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [dataTooltip, setDataToolTip] = useState<user.User>()

    const renderAvatar = () => (
        <img
            className={className ? className : 'avatar-guest'}
            key={data.user_id}
            alt='avatar-user'
            src={getAvatar(data.avatar)}
        />
    )

    const handleLoadData = async () => {
        try {
            if (isLoading) {
                const response = await getDataTooltipUserApi({ user_id: data.user_id })
                if (response.code === RESPONSE_CODE.SUCCESS) {
                    setDataToolTip(response.data)
                    setIsLoading(false)
                }
                else showNotification(response.message, response.code)
            }
        } catch (error) {
            showNotification(MESSAGES.ERROR, RESPONSE_CODE.ERROR)
        }
    }

    const renderTooltip = () => {
        if (isLoading) return (
            <div style={{height: '30vh'}}>
                <Loading />
            </div>
        )
        return (
            <div>
                <div className="item-center">
                    {renderAvatar()}
                    <p className="color-white text-large bold mgt">{dataTooltip?.user_name}</p>
                    <p className="color-white">{dataTooltip?.position_name}</p>
                </div>
                <div className="mgt-2">
                    <p className="color-white">• Số điện thoại: {dataTooltip?.phone_number}</p>
                    <p className="color-white">• Ngày sinh: {convertDate(dataTooltip?.birthday)}</p>
                    <p className="color-white">• Quê quán: {dataTooltip?.address}</p>
                    <p className="color-white">• Ngày vào công ty: {convertDate(dataTooltip?.join_date)}</p>
                </div>
            </div>
        )
    }

    if(disabled){
        return(
            <>
                {component || renderAvatar()}
            </>
        )
    }

    return (
        <Tooltip
            overlayClassName='tooltip-user'
            onOpenChange={handleLoadData}
            placement='right'
            trigger={isTouch() ? 'click' : 'hover'}
            title={renderTooltip}
        >
            {component || renderAvatar()}
        </Tooltip>
    )
}

const mapStateToProps = (state: models.GlobalState) => ({
    userData: userDataSelector(state)
})

export default connect(mapStateToProps)(TooltipUser)