import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { locationActionCreators } from '../../redux/actions/location';
import { userActionCreators } from '../../redux/actions/user';
import Doing from '../../screens/Doing';
import Personal from '../../screens/Personal';
import Todo from '../../screens/Todo';
import User from '../../screens/User';
import Waiting from '../../screens/Waiting';
import { ROUTES } from '../../utils/constants';
import TemplateLogined from '../layout';
import Book from '../../screens/Book';
import AddUser from '../../screens/AddUser';
import AddBranch from '../../screens/AddBranch';
import Report from '../../screens/Report';
import Company from '../../screens/Company';

type Props = {
    getListProvince: typeof locationActionCreators.getListProvince;
    getUserData: typeof userActionCreators.getUserData;
    getCountData: typeof userActionCreators.getCountData;
}

const Private = React.memo((props: Props) =>{
    let { path } = useRouteMatch();

    useEffect(() =>{
        props.getListProvince()
        props.getUserData()
        props.getCountData()
    }, [])

    return(
        <TemplateLogined>
            <Switch>
                <Route exact path={path}>
                    <Company />
                </Route>
                <Route path={ROUTES.TODO}>
                    <Todo />
                </Route>
                <Route path={ROUTES.DOING}>
                    <Doing />
                </Route>
                <Route path={ROUTES.WAITING}>
                    <Waiting />
                </Route>
                <Route path={ROUTES.PERSONAL}>
                    <Personal />
                </Route>
                <Route path={ROUTES.USER}>
                    <User />
                </Route>
                <Route path={ROUTES.BOOK}>
                    <Book />
                </Route>
                <Route path={ROUTES.ADD_USER}>
                    <AddUser />
                </Route>
                <Route path={ROUTES.ADD_BRANCH}>
                    <AddBranch />
                </Route>
                <Route path={ROUTES.REPORT}>
                    <Report />
                </Route>
            </Switch>
        </TemplateLogined>
    )
})

const mapDispatchToProps = {
    getListProvince: locationActionCreators.getListProvince,
    getUserData: userActionCreators.getUserData,
    getCountData: userActionCreators.getCountData,
};

export default connect(undefined, mapDispatchToProps)(Private);

