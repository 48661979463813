import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { getListNewfeedApi } from "../../apis/newfeed";
import models from "../../models";
import newfeed from "../../models/newfeed";
import school from "../../models/school";
import user from "../../models/user";
import { userDataSelector } from "../../redux/selectors/user";
import { DEFAULT_PAGE_LIMIT, MESSAGES, RESPONSE_CODE } from "../../utils/constants";
import { showNotification } from "../../utils/notification";
import Loading from "../common/Loading";
import ListNewfeed from './item';
import InfiniteScroll from 'react-infinite-scroller';
import work from "../../models/work";
import { Subscription } from 'rxjs';
import EventBus, { BaseEventType, EventBusName } from "../../services/event-bus";
import log from "../../models/log";
import { Button } from "antd";

type NewfeedBlockProps = {
    data: newfeed.NewfeedData;
    onChange: (data: any) => void
}

const NEWFEED_TAB = {
    ALL: 0,
    DOING: 1,
    DONE: 2,
    HISTORY: 3,
}


export const NewfeedBlock = (props: NewfeedBlockProps) => {
    const { data } = props;
    switch (data.newfeed_type) {
        case '1': // work
            return <ListNewfeed.Work data={data as newfeed.NewfeedData & work.Work} onChange={props.onChange} />
        case '2': // post
            return <ListNewfeed.Post data={data as newfeed.NewfeedData & newfeed.Post} />
        case '3': // video
            return <ListNewfeed.Video data={data as newfeed.NewfeedData & newfeed.Video} />
        case '4': //school log
            return <ListNewfeed.SchoolLog data={data as newfeed.NewfeedData & log.Log} />
        case '5': //book
            return <ListNewfeed.Book data={data as newfeed.NewfeedData & newfeed.Book} />
        default:
            break;
    }
    return null;
}

type NewfeedProps = {
    userData: user.User;
    school: school.SchoolData;
}

const Newfeed = (props: NewfeedProps) => {
    const { school } = props;

    const [newfeedData, setNewfeedData] = useState<newfeed.NewfeedData[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [endList, setEndList] = useState<boolean>(false)
    const pageIdRef = useRef<number>(1)

    const [tab, setTab] = useState<number>(NEWFEED_TAB.DOING)

    const subScription = new Subscription();

    const getListNewfeed = async (type = NEWFEED_TAB.DOING, pageId = 1) => {
        try {
            setTab(type)
            const response = await getListNewfeedApi({
                school_id: school.school_id,
                page_id: pageId,
                type
            });
            pageIdRef.current = pageId;
            setNewfeedData(pageId === 1 ? response.data : [...newfeedData, ...response.data]);
            setIsLoading(false)
            setEndList(response?.data?.length < DEFAULT_PAGE_LIMIT)
        } catch (error) {
            showNotification(MESSAGES.ERROR, RESPONSE_CODE.ERROR)
        }
    }

    const handleLoadMore = () => {
        if (!endList) getListNewfeed(tab, pageIdRef.current + 1)
    }

    const handleChange = (data: any) => {
        const dataChange = newfeedData.map(item => {
            if (item.newfeed_id === data.newfeed_id) {
                return {
                    ...item,
                    ...data
                }
            }
            return item
        })
        setNewfeedData(dataChange)
    }

    const onRegisterEventBus = () => {
        const sub = EventBus.getInstance().events.subscribe((res: BaseEventType) => {
            if (res.type === EventBusName.UPDATE_NEW_FEED) {
                getListNewfeed()
            }
        });
        subScription.add(sub);
    };

    const handleSetTab = (tab: number) => () =>{
        getListNewfeed(tab)
    }

    useEffect(() => {
        getListNewfeed()
    }, [school])

    useEffect(() => {
        onRegisterEventBus();
        return () => {
            subScription?.unsubscribe?.();
        };
    }, [school]);

    if (isLoading) {
        return (
            <Loading />
        )
    }

    return (
        <>
            <div className="flex item-center flex-wrap mgt-2">   
                <Button
                    className={`btn-doing mg ${tab === NEWFEED_TAB.DOING ? 'bg-warning' : ''}`}
                    onClick={handleSetTab(NEWFEED_TAB.DOING)}
                >
                    Việc đang làm
                </Button>
                <Button
                    className={`btn-doing mg ${tab === NEWFEED_TAB.DONE ? 'bg-warning' : ''}`}
                    onClick={handleSetTab(NEWFEED_TAB.DONE)}
                >
                    Việc đã hoàn tất
                </Button>
                <Button
                    className={`btn-doing mg ${tab === NEWFEED_TAB.HISTORY ? 'bg-warning' : ''}`}
                    onClick={handleSetTab(NEWFEED_TAB.HISTORY)}
                >
                    Nhật ký - Bài viết - Video
                </Button>
            </div>
            <InfiniteScroll
                loadMore={handleLoadMore}
                hasMore={!endList}
                loader={<Loading />}
                threshold={20}
            >
                {newfeedData.map((item) =>
                    <div className="box-newfeed" key={item.newfeed_id}>
                        <NewfeedBlock data={item} onChange={handleChange} />
                    </div>
                )}
            </InfiniteScroll>
        </>
    )
}

const mapStateToProps = (state: models.GlobalState) => ({
    userData: userDataSelector(state),
});

export default connect(mapStateToProps)(Newfeed);