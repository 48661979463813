import { useEffect, useState } from "react"
import dayjs from "dayjs"
import range from "lodash-es/range"
import 'dayjs/locale/vi'
import { IMAGES } from "../assets"
import { convertDate, convertDayToVN, getUserRole, groupBy, isPassDate, upperFirst } from "../utils/common"
import ModalCreateBook from "../components/modals/ModalCreateBook"
import { BOOK_COLLAPSE_TYPE, CLASSIFY_NAME, MESSAGES, RESPONSE_CODE, TIME_BOOK_TYPE_NAME, TimeBookType } from "../utils/constants"
import { showNotification } from "../utils/notification"
import { getListBookApi } from "../apis/book"
import book from "../models/book"
import moment from "moment"
import Icon from 'react-fontawesome';
import user from "../models/user"
import TooltipUser from "../components/common/TooltipUser"
import EventBus, { BaseEventType, EventBusName } from "../services/event-bus"
import { Subscription } from "rxjs";
import ModalUnlockBook from "../components/modals/ModalUnlockBook"
dayjs.locale('vi')

const weekDays = ["Thứ 2", "Thứ 3", "Thứ 4", "Thứ 5", "Thứ 6", "Thứ 7", "Chủ nhật"]

const getClassBook = (base_total: string, student_total: string, book_type: "0" | "1") => {
    const isSingle = Number(base_total) === 1 && Number(student_total) < 300;
    if (book_type === "0") {
        return isSingle ? 'line-book-single' : 'line-book-multiple'
    }
    else {
        return isSingle ? 'line-book-expect-single' : 'line-book-expect-multiple'
    }
};

const Book = () => {
    const [dayObj, setDayObj] = useState<dayjs.Dayjs>(dayjs())
    const [showModal, setShowModal] = useState<boolean>(false)
    const [daySelect, setDaySelect] = useState<number>(1)
    const [timeTypeSelect, setTimeTypeSelect] = useState<TimeBookType>(TimeBookType.MORNING)
    const [bookSelect, setBookSelect] = useState<book.BookData>({} as book.BookData)
    const [showModalUnlock, setShowModalUnlock] = useState<boolean>(false)

    const [collapseType, setCollapseType] = useState<number>(BOOK_COLLAPSE_TYPE.FULL)

    const [books, setBooks] = useState<book.BookData[]>([])

    const { isManager } = getUserRole()

    const toggleModal = () => {
        setShowModal(!showModal)
    }

    const toggleModalUnlock = () => {
        setShowModalUnlock(!showModalUnlock)
    }

    const subScription = new Subscription();

    const thisYear = dayObj.year()
    const thisMonth = dayObj.month() // (January as 0, December as 11)
    const daysInMonth = dayObj.daysInMonth()

    const dayObjOf1 = dayjs(`${thisYear}-${thisMonth + 1}-1`)
    const weekDayOf1 = convertDayToVN(dayObjOf1.day()) // (Sunday as 0, Saturday as 6)

    const dayObjOfLast = dayjs(`${thisYear}-${thisMonth + 1}-${daysInMonth}`)
    const weekDayOfLast = convertDayToVN(dayObjOfLast.day())

    const handlePrev = () => {
        setDayObj(dayObj.subtract(1, "month"))
    }

    const handleNext = () => {
        setDayObj(dayObj.add(1, "month"))
    }

    const chooseDay = (day: number, type: TimeBookType, bookChoose = {} as book.BookData) => () => {
        let selection = window.getSelection()?.toString();
        if(selection && selection?.length > 0) return;
        setDaySelect(day)
        setTimeTypeSelect(type)
        setShowModal(true)
        setBookSelect(bookChoose)
    }

    const getListBook = async () => {
        try {
            setBooks([])
            const response = await getListBookApi({ date: `${thisYear}-${thisMonth + 1}-0` })
            if (response.code === RESPONSE_CODE.SUCCESS) {
                setBooks(response.data)
            }
        } catch (error) {
            showNotification(MESSAGES.ERROR, RESPONSE_CODE.ERROR);
        }
    }

    const handleChangeCollapseType = (type: number) => () => {
        setCollapseType(type)
    }

    const handleClickLock = (day: number, type: TimeBookType) => () => {
        if (isManager) {
            setDaySelect(day)
            setTimeTypeSelect(type)
            setShowModalUnlock(true);
        }
        else {
            showNotification("Vui lòng liên hệ giám đốc để mở khóa máy này", RESPONSE_CODE.ERROR)
        }
    }

    const renderTypeBook = (bookMornings: book.BookData[], bookAfternoons: book.BookData[], day: number) => {
        // const isPass = isPassDate(`${thisYear}-${thisMonth + 1}-${day}`);
        const isLockMorning = bookMornings[bookMornings.length - 1]?.is_lock;
        const isLockAfternoons = bookAfternoons[bookAfternoons.length - 1]?.is_lock;
        if (collapseType === BOOK_COLLAPSE_TYPE.COLLAPSE) {
            const listObjectArr = groupBy([...bookMornings, ...bookAfternoons], 'group_id');
            return (
                <>
                    {Object.keys(listObjectArr).sort((a, b) => +listObjectArr[a][0]?.time_type - +listObjectArr[b][0]?.time_type).map((key, index) => {
                        const data = listObjectArr[key][0];
                        const textClass = data.time_type === '1' ? "text-book-green" : "text-book-collapse"
                        return (
                            <div className="book-collapse-container" key={index} onClick={chooseDay(day, data.time_type, listObjectArr[key][0])}>
                                <p className={textClass}>• {data.user_name} - {data.district_name}</p>
                                <p className={textClass}>• {data.name}</p>
                                <p className={textClass}>• {data.contact_info}</p>
                                <p className={textClass}>• {data.student_total}/{data.school_student_total}hs / {data.class_total} lớp / {data.base_total}cs</p>
                                <p className={textClass}>•Giờ đo: {data.time}</p>
                                <div className="book-collapse-title-container">
                                    <p className={textClass}>•KTV: </p>
                                    <div className="book-collapse-tag-container">
                                        {data.list_exec.map((item: user.UserSearch, key: number) =>
                                            <div className="book-collapse-tag-item" key={key}>
                                                <TooltipUser data={item} component={
                                                    <>
                                                        <span className="book-collapse-tag-text">{item.user_name}</span>
                                                    </>
                                                } />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="book-collapse-title-container">
                                    <p className={textClass}>•Phụ đo: </p>
                                    <div className="book-collapse-tag-container">
                                        {data.list_support.map((item: user.UserSearch, key: number) =>
                                            <div className="book-collapse-tag-item" key={key}>
                                                <TooltipUser data={item} component={
                                                    <>
                                                        <span className="book-collapse-tag-text">{item.user_name}</span>
                                                    </>
                                                } />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <p className={textClass}>•Số lượng máy: {Math.round(listObjectArr[key].length / 2)}</p>
                                <p className={textClass}>•Số lượng buổi: {listObjectArr[key].length}</p>
                            </div>
                        )
                    })}
                </>
            )
        }
        return (
            <>
                <div className="line-book-container">
                    <div className="line-book-cell">
                        {bookMornings.map((morningItem, keyMorning) =>
                            <div
                                className={`line-book ${getClassBook(morningItem.base_total, morningItem.student_total, morningItem.book_type)}`}
                                onClick={chooseDay(day, TimeBookType.MORNING, morningItem)}
                                key={keyMorning}
                            >
                                <p className="mgb-0 color-white bold text-center">{keyMorning + 1} - Sáng</p>
                            </div>
                        )}
                        {/* {isPass && */}
                            <>
                                {isLockMorning ?
                                    <div className="line-book flex item-center" onClick={handleClickLock(day, TimeBookType.MORNING)}>
                                        <Icon
                                            name="lock"
                                            className="lock-book-icon"
                                        />
                                    </div>
                                    :
                                    <div className="line-book" onClick={chooseDay(day, TimeBookType.MORNING)}></div>
                                }
                            </>
                        {/* } */}
                    </div>
                    {/* {isPass && */}
                        <div className="hr-line-book"></div>
                    {/* } */}
                    <div className="line-book-cell">
                        {bookAfternoons.map((afternoonItem, keyAfternoon) =>
                            <div
                                className={`line-book ${getClassBook(afternoonItem.base_total, afternoonItem.student_total, afternoonItem.book_type)}`}
                                onClick={chooseDay(day, TimeBookType.AFTERNOON, afternoonItem)}
                                key={keyAfternoon}
                            >
                                <p className="mgb-0 color-white bold text-center">{keyAfternoon + 1} - Chiều</p>
                            </div>
                        )}
                        {/* {isPass && */}
                            <>
                                {isLockAfternoons ?
                                    <div className="line-book flex item-center" onClick={handleClickLock(day, TimeBookType.AFTERNOON)}>
                                        <Icon
                                            name="lock"
                                            className="lock-book-icon"
                                        />
                                    </div>
                                    :
                                    <div className="line-book" onClick={chooseDay(day, TimeBookType.AFTERNOON)}></div>
                                }
                            </>
                        {/* } */}
                    </div>
                </div>
            </>
        )
    }

    const onRegisterEventBus = () => {
        const sub = EventBus.getInstance().events.subscribe((res: BaseEventType) => {
            if (res.type === EventBusName.CHANGE_BRANCH) {
                getListBook()
            }
        });
        subScription.add(sub);
    };

    const renderBookTable = () =>{
        let lastBook: book.BookData;
        const bookFilter = books.filter(item =>{
            if(lastBook?.book_date === item.book_date && lastBook?.school_id === item.school_id) return false;
            lastBook = item;
            return true;
        })
        return(
            <>
                {bookFilter.map((book, key) => {
                    const day = +moment(book.book_date, "YYYY-MM-DD").format("D")
                    return (
                        <tr key={book.book_id} className="pointer" onClick={chooseDay(day, book.time_type as any, book)}>
                            <td><p>{key + 1}</p></td>
                            <td><p>{CLASSIFY_NAME[book.classify]}</p></td>
                            <td><p>{moment(book.book_date).format("D/M")} {TIME_BOOK_TYPE_NAME[book.time_type]}</p></td>
                            <td><p>{book.name}</p></td>
                            <td><p>{book.address}</p></td>
                            <td><p>{book.contact_info}</p></td>
                            <td><p>{book.mst}</p></td>
                            <td><p>{book.student_total}</p></td>
                            <td><p>{book.base_total}</p></td>
                            <td><p>{book.class_total}</p></td>
                            <td><p>{book.province_name}</p></td>
                            <td><p>{book.district_name}</p></td>
                            <td><p>{book.ward_name}</p></td>
                            <td>
                                <div className="item-center">
                                    <TooltipUser data={book} key={key} className='avatar-list-school' />
                                    <p>{book.user_name}</p>
                                </div>
                            </td>
                        </tr>
                    )
                })}
            </>
        )
    }

    useEffect(() => {
        onRegisterEventBus();
        return () => {
            subScription?.unsubscribe?.();
        };
    }, [thisMonth, thisYear]);


    useEffect(() => {
        getListBook()
    }, [thisMonth])

    return (
        <div className="relative">
            <div className="book-select-collapse">
                <Icon
                    name='calendar'
                    className={`icon-select-collapse ${collapseType === BOOK_COLLAPSE_TYPE.FULL ? 'warning' : ''}`}
                    onClick={handleChangeCollapseType(BOOK_COLLAPSE_TYPE.FULL)}
                />
                <Icon
                    name='file-text-o'
                    className={`icon-select-collapse ${collapseType === BOOK_COLLAPSE_TYPE.COLLAPSE ? 'warning' : ''}`}
                    onClick={handleChangeCollapseType(BOOK_COLLAPSE_TYPE.COLLAPSE)}
                />
                {isManager &&
                    <Icon
                        name='columns'
                        className={`icon-select-collapse ${collapseType === BOOK_COLLAPSE_TYPE.TABLE ? 'warning' : ''}`}
                        onClick={handleChangeCollapseType(BOOK_COLLAPSE_TYPE.TABLE)}
                    />
                }
            </div>
            <div className="title-calendar-container">
                <img
                    alt="action-calendar"
                    src={IMAGES.CHEV_LEFT}
                    onClick={handlePrev}
                    className="img-action-calendar"
                />
                <p className="bold text-huge">{upperFirst(dayObj.format("MMMM YYYY"))}</p>
                <img
                    alt="action-calendar"
                    src={IMAGES.CHEV_RIGHT}
                    onClick={handleNext}
                    className="img-action-calendar"
                />
            </div>
            {[BOOK_COLLAPSE_TYPE.COLLAPSE, BOOK_COLLAPSE_TYPE.FULL].includes(collapseType) ?
                <>
                    <div className="week-container mgt-2">
                        {weekDays.map(d => (
                            <div className="week-cell" key={d}>
                                <p className="text-center bold text-large">{d}</p>
                            </div>
                        ))}
                    </div>
                    <div className="day-container">
                        {range(weekDayOf1).map(i => (
                            <div className="day-cell" key={i}></div>
                        ))}
                        {range(daysInMonth).map(i => {
                            const day = i + 1;
                            const bookDays = books.filter(item => {
                                return Number(moment(item.book_date).format('D')) === day
                            })
                            const [bookMornings, bookAfternoons] = [bookDays.filter(k => k.time_type === TimeBookType.MORNING), bookDays.filter(k => k.time_type === TimeBookType.AFTERNOON)]
                            const isSunday = moment(`${thisYear}-${thisMonth + 1}-${day}`).weekday() === 0
                            return (
                                <div
                                    className='day-cell'
                                    key={i}
                                >
                                    <p className="text-day">{i + 1}</p>
                                    {!isSunday && renderTypeBook(bookMornings, bookAfternoons, day)}
                                </div>
                            )
                        })}
                        {range(6 - weekDayOfLast).map(i => (
                            <div
                                className="day-cell"
                                key={i}
                            />
                        ))}
                    </div>
                </>
                :
                <>
                    <div className="table-book-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>STT</th>
                                    <th>Phân loại</th>
                                    <th>Ngày đo</th>
                                    <th>Tên trường</th>
                                    <th>Địa chỉ</th>
                                    <th>Liên hệ</th>
                                    <th>MST</th>
                                    <th>SLHS</th>
                                    <th>Số CS</th>
                                    <th>Số lớp</th>
                                    <th>Tỉnh/TP</th>
                                    <th>Quận huyện</th>
                                    <th>Phường Xã</th>
                                    <th>Sale</th>
                                </tr>
                            </thead>
                            <tbody>
                            {renderBookTable()}
                            </tbody>
                        </table>
                    </div>
                </>
            }
            <ModalUnlockBook
                date={`${thisYear}-${thisMonth + 1}-${daySelect}`}
                timeType={timeTypeSelect}
                showModal={showModalUnlock}
                onClose={toggleModalUnlock}
                onCreateSuccess={getListBook}
            />
            <ModalCreateBook
                showModal={showModal}
                onClose={toggleModal}
                date={`${thisYear}-${thisMonth + 1}-${daySelect}`}
                timeType={timeTypeSelect}
                onCreateSuccess={getListBook}
                bookSelect={bookSelect}
            />
        </div>
    )
}

export default Book