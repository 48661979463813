import React, {useEffect} from 'react';
import { Route, Switch, useRouteMatch, Redirect, useHistory } from 'react-router-dom';
import Login from '../../screens/Login';

const Lobby = React.memo(() =>{
    let { path } = useRouteMatch();
    
    return(
        <Switch>
            <Route exact path={`${path}`}>
                <Login />
            </Route>
            <Route render={() => <Redirect to={`${path}sign-in`}/>}/>
        </Switch>
    )
})

export default Lobby;

