import { Button, Modal } from "antd";
import { useRef, useState } from "react"
import { LIST_WORK, MESSAGES, PROP, RESPONSE_CODE } from "../../utils/constants";
import TagsInput, { TagInputRef } from "../common/TagInput";
import DatePicker from "react-datepicker";
import { createWorkApi } from "../../apis/work";
import moment from "moment";
import { showNotification } from "../../utils/notification";
import school from "../../models/school";
import TextareaAutosize from 'react-textarea-autosize';

interface Props {
    showModal: boolean;
    onClose: () => void;
    onCreateSuccess: () => void;
    school: school.SchoolData;
}

const ModalCreateWork = (props: Props) => {
    const [workName, setWorkName] = useState<string>('')
    const [workType, setWorkType] = useState<string>(LIST_WORK[0].status)
    const [workDesc, setWorkDesc] = useState<string>('')
    const [workResult, setWorkResult] = useState<string>('')
    const [deadlineFrom, setDeadlineFrom] = useState<Date | null>()
    const [deadlineTo, setDeadlineTo] = useState<Date | null>()
    const [isPress, setIsPress] = useState<boolean>(false)

    const { showModal, onClose, onCreateSuccess, school } = props;

    const execInputRef = useRef<TagInputRef>(null)
    const supportInputRef = useRef<TagInputRef>(null)
    const testAndSupportInputRef = useRef<TagInputRef>(null)

    const handleClearInput = () =>{
        setWorkName('');
        setWorkType(LIST_WORK[0].status)
        setWorkDesc('')
        setWorkResult('')
        setDeadlineFrom(null)
        setDeadlineTo(null)
    }

    const handleCreateWork = async () => {
        if (!isPress) {
            try {
                const execInput = execInputRef.current?.getValue();
                const supportInput = supportInputRef.current?.getValue();
                const testAndSupportInput = testAndSupportInputRef.current?.getValue();
                const listExec = execInput?.map(i => i.user_id) || [];
                const listAccept = testAndSupportInput?.map(i => i.user_id) || [];
                const listSupport = supportInput?.map(i => i.user_id) || [];
                let deadlineFromConvert: string | null = moment(deadlineFrom).format("YYYY-MM-DD HH:mm");
                deadlineFromConvert = deadlineFromConvert === 'Invalid date' ? '' : deadlineFromConvert;
                let deadlineToConvert: string | null = moment(deadlineTo).format("YYYY-MM-DD HH:mm");
                deadlineToConvert = deadlineToConvert === 'Invalid date' ? '' : deadlineToConvert;
                if (workName && listExec.length > 0) {
                    setIsPress(true)
                    const response = await createWorkApi({
                        work_name: workName,
                        work_type: workType,
                        work_desc: workDesc,
                        work_result: workResult,
                        deadline_from: deadlineFromConvert,
                        deadline_to: deadlineToConvert,
                        list_exec: listExec,
                        list_accept: listAccept,
                        list_support: listSupport,
                        school_id: school.school_id
                    });
                    setIsPress(false)
                    showNotification(response.message, response.code)
                    if (response.code === RESPONSE_CODE.SUCCESS) {
                        onClose();
                        onCreateSuccess();
                        handleClearInput()
                    }
                }
                else if(!workName) showNotification('Tên công việc không được để trống', 0)
                else if(listExec.length === 0) showNotification('Người thực hiện không được để trống', 0)
            } catch (error) {
                showNotification(MESSAGES.ERROR, RESPONSE_CODE.ERROR)
                setIsPress(false)
            }
        }
    }

    return (
        <Modal
            {...PROP.MODAL}
            open={showModal}
            onCancel={onClose}
            width='120vh'
            centered={false}
            destroyOnClose={true}
        >
            <div className="row">
                <div className="col-md-6 col-sm-12 col-xs-12 pdlr">
                    <p className="black">Tên công việc: <span className="danger">*</span></p>
                    <input
                        className="form-control"
                        value={workName}
                        onChange={({ target: { value } }) => setWorkName(value)}
                    />
                </div>
                <div className="col-md-6 col-sm-12 col-xs-12 pdlr">
                    <p className="black">Phân loại:</p>
                    <select
                        className="form-control"
                        value={workType}
                        onChange={({ target: { value } }) => setWorkType(value)}
                    >
                        {LIST_WORK.map((item, key) =>
                            <option key={key} value={item.status}>{item.name}</option>
                        )}
                    </select>
                </div>
            </div>
            <div className="mgt">
                <p className="black">Mô tả công việc:</p>
                <TextareaAutosize
                    className="textarea-form-control"
                    value={workDesc}
                    onChange={({ target: { value } }) => setWorkDesc(value)}
                />
            </div>
            <div className="mgt">
                <p className="black">Mô tả kết quả:</p>
                <TextareaAutosize
                    className="textarea-form-control"
                    value={workResult}
                    onChange={({ target: { value } }) => setWorkResult(value)}
                />
            </div>
            <div className="row mgt">
                <div className="col-md-4 col-sm-6 col-xs-12 pdlr">
                    <p className="black">Người thực hiện <span className="danger">*</span></p>
                    <TagsInput ref={execInputRef} />
                </div>
                <div className="col-md-4 col-sm-6 col-xs-12 pdlr">
                    <p className="black">Người hỗ trợ</p>
                    <TagsInput ref={supportInputRef} />
                </div>
                <div className="col-md-4 col-sm-12 col-xs-12 pdlr">
                    <p className="black">Người kiểm tra & nghiệm thu</p>
                    <TagsInput ref={testAndSupportInputRef} />
                </div>
            </div>
            <div className="row mgt">
                <div className="col-md-6 col-sm-6 col-xs-12 pdlr">
                    <p className="black">Deadline từ</p>
                    <DatePicker
                        selected={deadlineFrom}
                        onChange={setDeadlineFrom}
                        timeFormat="HH:mm"
                        timeIntervals={60}
                        timeCaption="time"
                        dateFormat="d-M-yyyy HH:mm"
                        minDate={new Date()}
                        showTimeSelect
                    />
                </div>
                <div className="col-md-6 col-sm-6 col-xs-12 pdlr">
                    <p className="black">đến</p>
                    <DatePicker
                        selected={deadlineTo}
                        onChange={setDeadlineTo}
                        timeFormat="HH:mm"
                        timeIntervals={60}
                        timeCaption="time"
                        dateFormat="d-M-yyyy HH:mm"
                        minDate={new Date()}
                        showTimeSelect
                    />
                </div>
            </div>
            <div className="item-center mgt-3">
                <Button
                    onClick={handleCreateWork}
                    loading={isPress}
                >
                    Gửi giao việc
                </Button>
            </div>
        </Modal>
    )
}

export default ModalCreateWork;