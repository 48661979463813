import iziToast from 'izitoast';
import '../assets/css/iziToast.css';

const getTypeNotification = (type: number) =>{
    let typeText = 'error';
    if (type === 1) typeText = 'success';
	if (type === 2) typeText = 'warning';
    if (type === 3) typeText = 'info';
    return typeText;
}

export const showNotification = (msg: string = '', type: number, id: string='#check') => {
	let toastId = document.querySelector(id) ? id : '';
    let typeNotification = getTypeNotification(type);
    //@ts-ignore
    iziToast[typeNotification]({
		timeout: 3000,
		title: '',
		message: msg,
		position: 'bottomRight',
		titleColor:'white',
        messageColor: 'white',
		target: toastId,
		zindex: 9999999999999,
		pauseOnHover: false
	});
}