import { Button } from "antd";
import Axios from "axios";
import Cookies from "js-cookie";
import React, { useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { loginApi } from "../apis/login";
import { KEY, MESSAGES, RESPONSE_CODE } from "../utils/constants";
import { showNotification } from "../utils/notification";
import { applyToken } from "../utils/common";
import { IMAGES } from "../assets";

const Login = React.memo((props: RouteComponentProps) =>{
    const [id, setId] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [isPress, setIsPress] = useState<boolean>(false)

    const handleChangeId = (e: React.ChangeEvent<HTMLInputElement>) =>{
        setId(e.target.value)
    }

    const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) =>{
        setPassword(e.target.value)
    }

    const handleLogin = async() =>{
        try {
            if(!isPress){
                if(!id || !password){
                    showNotification('ID hoặc password không được để trống', RESPONSE_CODE.ERROR)
                    return;
                }
                setIsPress(true);
                const response = await loginApi({user_id: id, user_pass: password});
                if(response.code === RESPONSE_CODE.SUCCESS){
                    applyToken(response.token)
                    showNotification('Đăng nhập thành công', RESPONSE_CODE.SUCCESS)
                    setTimeout(() =>
                        props.history.push('/')
                    , 2000);
                }
                else {
                    showNotification(response.message, RESPONSE_CODE.ERROR);
                    setIsPress(false)
                }
            }
        } catch (error) {
            setIsPress(false)
            showNotification(MESSAGES.ERROR, RESPONSE_CODE.ERROR);
        }
    }

    const handleEnter = (e: React.KeyboardEvent<HTMLDivElement>) =>{
        if(e.keyCode === 13){
            handleLogin()
        }
    }

    return(
        <div className='login-container'>
            <div className="item-center row">
                <div className="col-md-6 col-sm-6">
                    <img
                        src={IMAGES.LOGO}
                        className="logo-login-icon"
                        alt='logo'
                    />
                    <p className="title-login">HAPPY IDEM</p>
                    <input
                        className='form-control'
                        placeholder="ID"
                        value={id}
                        onChange={handleChangeId}
                        onKeyDown={handleEnter}
                        autoFocus
                    />
                    <input
                        className='form-control mgt-2'
                        placeholder="Mật khẩu"
                        value={password}
                        onChange={handleChangePassword}
                        type='password'
                        onKeyDown={handleEnter}
                    />
                    <Button
                        className="mgt-2"
                        onClick={handleLogin}
                        loading={isPress}
                    >
                        Đăng nhập
                    </Button>
                </div>
            </div>
        </div>
    )
})

export default withRouter(Login);