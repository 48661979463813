import { Button, Modal } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { getListMapApi, getListSchoolApi } from "../../apis/school";
import { IMAGES } from "../../assets";
import map from "../../models/map";
import school from "../../models/school";
import { openNewTab } from "../../utils/common";
import { MESSAGES, PROP, RESPONSE_CODE, SCHOOL_STATUS_NAME } from "../../utils/constants";
import SchoolDetail from "../school/SchoolDetail";
import Loading from "../common/Loading";

type Props = {
    showModal: boolean;
    onClose: () => void;
    school: school.SchoolData
}

const ModalSchoolDetail = (props: Props) => {
    const { showModal, onClose, school } = props;
    const [isLoading, setIsLoading] = useState(true);
    const [dataSchool, setDataSchool] = useState<school.SchoolData>({} as school.SchoolData);

    const getDataSchool = async () => {
        const response = await getListSchoolApi({ status: '0', school_id: school.school_id })
        if (response.code === RESPONSE_CODE.SUCCESS && response.data.length > 0) {
            setDataSchool(response.data[0])
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (school.school_id && school.name) {
            setDataSchool(school)
            setIsLoading(false)
        }
        else getDataSchool();
    }, [school.school_id])

    return (
        <Modal
            {...PROP.MODAL}
            open={showModal}
            onCancel={onClose}
            width='120vh'
            destroyOnClose
        >
            {isLoading ?
                <Loading />
                :
                <SchoolDetail school={dataSchool} />
            }
        </Modal>
    )
}

export default ModalSchoolDetail