import axios from 'axios';
import { END_POINTS } from '..';
import models from '../../models';
import map from '../../models/map';
import school from '../../models/school';
const qs = require('qs');

export const getListSchoolApi = (params: school.SchoolRequest) =>{
    const url = END_POINTS.getListSchool;
	return axios.get(url, {params}).then((res: { data: school.SchoolResponse }) => res.data);
}

export const getListMapApi = (params: school.SchoolIdRequest) =>{
    const url = END_POINTS.getListMap;
	return axios.get(url, {params}).then((res: { data: map.MapResponse }) => res.data);
}

export const updateSchoolApi = (params: school.SchoolUpdate) =>{
    const url = END_POINTS.updateSchool;
	return axios.post(url, qs.stringify(params)).then((res: { data: models.DefaultResponse }) => res.data);
}

export const updateProgressStatusApi = (params: school.SchoolStatusUpdate) =>{
    const url = END_POINTS.updateProgessStatus;
	return axios.post(url, qs.stringify(params)).then((res: { data: models.DefaultResponse }) => res.data);
}

export const getListBranchApi = () =>{
    const url = END_POINTS.getListBranch;
	return axios.get(url).then((res: { data: school.BranchResponse }) => res.data);
}

export const changeCuratorsApi = (params: school.SchoolCuratorRequest) =>{
    const url = END_POINTS.changeCurators;
	return axios.post(url, qs.stringify(params)).then((res: { data: models.DefaultResponse }) => res.data);
}

export const searchSchoolApi = (params: school.SearchSchoolParams) =>{
    const url = END_POINTS.searchSchool;
	return axios.get(url, {params}).then((res: { data: school.SchoolResponse }) => res.data);
}

export const addSchoolApi =(params: school.SchoolUpdate) =>{
    const url = END_POINTS.addSchool;
	return axios.post(url, qs.stringify(params)).then((res: { data: models.DefaultResponse }) => res.data);
}