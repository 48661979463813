import { connect } from "react-redux";
import models from "../../../models";
import { userDataSelector } from "../../../redux/selectors/user";
import TooltipUser from "../../common/TooltipUser";
import { convertDate, convertDateTime, getAvatar } from "../../../utils/common";
import { CLASSIFY_NAME, LIST_PROGRESS, SCHOOL_KEY_NAME, SCHOOL_STATUS_NAME } from "../../../utils/constants";
import log from "../../../models/log";
import Icon from 'react-fontawesome'
import map from "../../../models/map";
import { EventBusName, onPushEventBus } from "../../../services/event-bus";

interface Props {
    data: log.Log
}

const ParseData = ({ param, value }: { param: string, value: string }) => {
    switch (param) {
        case 'avatar':
            return (
                <div className="flex align-center mgb">
                    <p className='bold'>• {SCHOOL_KEY_NAME[param]}:</p>
                    <img
                        src={getAvatar(value as string)}
                        alt='avatar-school'
                        className="avatar-school-log mgl-2"
                    />
                </div>
            )
        case 'map':
            const maps: any | map.MapData[] = value;
            return (
                <>
                    <p><u>Bản đồ</u></p>
                    {maps.map((item: any, key: number) =>
                        <p className='bold' key={key}>•{item.map_name}: {item.map_name}</p>
                    )}
                </>
            )
        case 'status':
            return (
                <p className='bold'>• Trạng thái: {SCHOOL_STATUS_NAME[value]}</p>
            )
        case 'next_date':
            return (
                <p className='bold'>• Ngày đo gần nhất: {convertDate(value)}</p>
            )
        case 'next_estimate_date':
            return (
                <p className='bold'>• Ngày dự kiến đo lại: {convertDate(value)}</p>
            )
        case 'classify':
            return (
                <p className='bold'>• Phân loại: {CLASSIFY_NAME[value]}</p>
            )
        case 'progress_status':
            return (
                <p className='bold'>• Tiến trình: {LIST_PROGRESS.find(i => i.status === value)?.name}</p>
            )
    }
    return <p className='bold'>• {SCHOOL_KEY_NAME[param]}: {typeof value === 'string' && value}</p>
}

const SchoolLog = (props: Props) => {
    const { data } = props;

    const handleClickSchool = (schoolId: string) => () =>{
        onPushEventBus(EventBusName.SELECT_SCHOOL_MODAL, schoolId)
    }

    return (
        <div>
            <div className="flex-row">
                <TooltipUser data={data} />
                <div className='flex-column mgl-2'>
                    <p>
                        <span className="black text-large bold">{data.user_name}</span>
                        <span className="black"> thay đổi thông tin trường</span>
                    </p>
                    <p className="text-time silver">{convertDateTime(data.cr_date_time)}</p>
                    <p
                        className="mgb-0 pointer"
                        onClick={handleClickSchool(data.school_id)}
                    >
                        <span className="gray">Tại </span>
                        <u><span className="bold">{data.name}</span></u>
                    </p>
                </div>
            </div>
            {data.data &&
                <div className="row align-center mgt-2">
                    <div className="col-md-5 col-sm-12 col-xs-12 box-log">
                        {data.data.old && Object.keys(data.data.old).map((key, i) =>
                            <ParseData key={i} param={key} value={data.data.old[key]} />
                        )}
                    </div>
                    <div className="col-md-2 col-sm-12 col-xs-12 item-center">
                        <Icon
                            name='long-arrow-right'
                            className="arrow-school-log"
                        />
                    </div>
                    <div className="col-md-5 col-sm-12 col-xs-12 box-log">
                        {data.data.new && Object.keys(data.data.new).map((key, i) =>
                            <ParseData key={i} param={key} value={data.data.new[key]} />
                        )}
                    </div>
                </div>
            }
        </div>
    )
}

const mapStateToProps = (state: models.GlobalState) => ({
    userData: userDataSelector(state)
})

export default connect(mapStateToProps)(SchoolLog)