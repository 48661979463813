import { Button, Modal } from "antd";
import { convertDate, getUserRole, openNewTab } from "../../utils/common";
import { BOOK_TYPE_NAME, MESSAGES, PROP, RESPONSE_CODE, TIME_BOOK_TYPE_NAME, TimeBookType, USER_ROLE_TYPE } from "../../utils/constants";
import models from "../../models";
import { userDataSelector } from "../../redux/selectors/user";
import { connect } from "react-redux";
import user from "../../models/user";
import SearchSchool from "../common/SearchSchool";
import { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import { setMinutes, setHours } from "date-fns";
import school from "../../models/school";
import { addBookExecAndSupportApi, deleteBookApi, updateBookApi } from "../../apis/book";
import moment from "moment";
import { showNotification } from "../../utils/notification";
import book from "../../models/book";
import TagsInput, { TagInputRef } from "../common/TagInput";

type Props = {
    showModal: boolean;
    onClose: () => void;
    date: string;
    timeType: TimeBookType;
    userData: user.User;
    onCreateSuccess: () => void;
    bookSelect: book.BookData
}

const ModalCreateBook = (props: Props) => {
    const { showModal, onClose, date, timeType, userData, onCreateSuccess, bookSelect } = props;
    const searchSchoolRef: any = useRef()

    const [timeSelect, setTimeSelect] = useState<string>()
    const [studentTotal, setStudentTotal] = useState<string>('')
    const [classTotal, setClassTotal] = useState<string>('')
    const [baseTotal, setBaseTotal] = useState<string>('');
    const [bookType, setBookType] = useState<string>(Object.keys(BOOK_TYPE_NAME)[0]);
    const [isPress, setIsPress] = useState<boolean>(false)

    const { isManager } = getUserRole();

    const execInputRef = useRef<TagInputRef>(null)
    const supportInputRef = useRef<TagInputRef>(null)

    const isEnableEdit = Boolean(!bookSelect.user_id || bookSelect.user_id === userData.user_id)

    useEffect(() => {
        setStudentTotal(bookSelect.student_total || '');
        setClassTotal(bookSelect.class_total || '');
        setBaseTotal(bookSelect.base_total || '')
        setBookType(bookSelect.book_type || '')
        const isValidDate = moment(`${bookSelect.book_date} ${bookSelect.time}`).isValid()
        setTimeSelect(isValidDate ? moment(`${bookSelect.book_date} ${bookSelect.time}`).format('H:mm') : '')
        if (searchSchoolRef.current) {
            searchSchoolRef.current.setValue(bookSelect.name || '')
        }
    }, [bookSelect])

    const handleConfirm = async () => {
        try {
            const schoolId = searchSchoolRef.current?.getValue()?.school_id || bookSelect.school_id;
            if (schoolId && studentTotal && classTotal && baseTotal && timeSelect) {
                setIsPress(true)
                const response = await updateBookApi({
                    book_id: bookSelect.group_id,
                    book_date: date,
                    school_id: schoolId,
                    class_total: classTotal,
                    time: timeSelect || '',
                    student_total: studentTotal,
                    time_type: `${timeType}`,
                    base_total: baseTotal,
                    book_type: bookType
                })
                setIsPress(false)
                showNotification(response.message, response.code)
                if (response.code === RESPONSE_CODE.SUCCESS) {
                    onCreateSuccess()
                    onClose()
                }
            }
            else if (!schoolId) showNotification('Bạn chưa chọn tên trường', 0)
            else if (!studentTotal) showNotification('Bạn chưa điền SL HS Đăng ký', 0)
            else if (!classTotal) showNotification('Bạn chưa điền Số lớp', 0)
            else if (!baseTotal) showNotification('Bạn chưa điền Số cơ sở', 0)
            else if (!timeSelect) showNotification('Bạn chưa điền Giờ hẹn đo', 0)
        } catch (error) {
            showNotification(MESSAGES.ERROR, RESPONSE_CODE.ERROR)
            setIsPress(false)
        }
    }

    const handleDeleteBook = async () => {
        try {
            if (window.confirm("Xác nhận hủy")) {
                const response = await deleteBookApi({ book_id: bookSelect.group_id });
                showNotification(response.message, response.code)
                if (response.code === RESPONSE_CODE.SUCCESS) {
                    onCreateSuccess()
                    onClose()
                }
            }
        } catch (error) {
            showNotification(MESSAGES.ERROR, RESPONSE_CODE.ERROR)
        }
    }

    const handleSelectListExec = (currentTags: user.UserSearch[]) => {
        const supportInput = supportInputRef.current?.getValue();
        const listSupport = supportInput?.map(i => i.user_id) || [];
        const listExec = currentTags?.map(i => i.user_id) || [];
        addBookExecAndSupport(listExec, listSupport)
    }

    const handleSelectListSupport = (currentTags: user.UserSearch[]) => {
        const execInput = execInputRef.current?.getValue();
        const listExec = execInput?.map(i => i.user_id) || [];
        const listSupport = currentTags?.map(i => i.user_id) || [];
        addBookExecAndSupport(listExec, listSupport)
    }

    const addBookExecAndSupport = async (list_exec: string[], list_support: string[]) => {
        try {
            const response = await addBookExecAndSupportApi({
                list_exec,
                list_support,
                book_id: bookSelect.group_id
            })
            showNotification(response.message, response.code)
            if (response.code === RESPONSE_CODE.SUCCESS) {
                onCreateSuccess()
            }
        } catch (error) {
            showNotification(MESSAGES.ERROR, RESPONSE_CODE.ERROR)
        }
    }

    return (
        <Modal
            {...PROP.MODAL}
            width='90vh'
            open={showModal}
            onCancel={onClose}
        >
            <p className="text-huge text-center bold mgt-2">
                {TIME_BOOK_TYPE_NAME[timeType]} {convertDate(moment(date, "YYYY-MM-DD").format("YYYY-MM-DD"))}
            </p>
            <div className="row align-center mgt-3">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <p>Người đặt lịch:</p>
                </div>
                <div className="col-md-8 col-sm-12 col-xs-12">
                    <input
                        className="form-control"
                        disabled
                        value={bookSelect.user_name || userData.user_name}
                    />
                </div>
            </div>
            <div className="row align-center mgt">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <p>Kiểu đặt lịch:</p>
                </div>
                <div className="col-md-8 col-sm-12 col-xs-12">
                    <select
                        className="form-control"
                        style={{ textAlignLast: 'left' }}
                        value={bookType}
                        onChange={({ target: { value } }) => setBookType(value)}
                        disabled={!isEnableEdit}
                    >
                        {Object.keys(BOOK_TYPE_NAME).map((item, key) =>
                            <option value={item} key={key}>{BOOK_TYPE_NAME[item]}</option>
                        )}
                    </select>
                </div>
            </div>
            <div className="row align-center mgt">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <p>Tên trường:</p>
                </div>
                <div className="col-md-8 col-sm-12 col-xs-12">
                    <SearchSchool
                        ref={searchSchoolRef}
                        status="2"
                        disabled={!isEnableEdit}
                    />
                </div>
            </div>
            <div className="row align-center mgt">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <p>SL HS Đăng ký:</p>
                </div>
                <div className="col-md-8 col-sm-12 col-xs-12">
                    <input
                        className="form-control"
                        type="number"
                        value={studentTotal}
                        onChange={({ target: { value } }) => setStudentTotal(value)}
                        disabled={!isEnableEdit}
                    />
                </div>
            </div>
            <div className="row align-center mgt">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <p>Số lớp:</p>
                </div>
                <div className="col-md-8 col-sm-12 col-xs-12">
                    <input
                        className="form-control"
                        type="number"
                        value={classTotal}
                        onChange={({ target: { value } }) => setClassTotal(value)}
                        disabled={!isEnableEdit}
                    />
                </div>
            </div>
            <div className="row align-center mgt">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <p>Số cơ sở:</p>
                </div>
                <div className="col-md-8 col-sm-12 col-xs-12">
                    <input
                        className="form-control"
                        value={baseTotal}
                        onChange={({ target: { value } }) => setBaseTotal(value)}
                        disabled={!isEnableEdit}
                    />
                </div>
            </div>
            <div className="row align-center mgt">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <p>Giờ hẹn đo:</p>
                </div>
                <div className="col-md-8 col-sm-12 col-xs-12 col-book-select-time">
                    <select
                        className="form-control"
                        style={{ textAlignLast: 'left' }}
                        value={timeSelect}
                        onChange={({ target: { value } }) => setTimeSelect(value)}
                        disabled={!isEnableEdit}
                    >
                        <option value=''>Chọn giờ hẹn đo</option>
                        {Array(11).fill(undefined).map((_, key) =>
                            <option value={`${key + 7}:00`} key={key}>{key + 7}:00</option>
                        )}
                    </select>
                </div>
            </div>
            <div className="row align-center mgt">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <p>Người đo:</p>
                </div>
                <div className="col-md-8 col-sm-12 col-xs-12">
                    <TagsInput
                        data={bookSelect.list_exec || []}
                        disabled={Boolean(!isManager || !bookSelect.book_id)}
                        onSelect={handleSelectListExec}
                        ref={execInputRef}
                    />
                </div>
            </div>
            <div className="row align-center mgt">
                <div className="col-md-4 col-sm-12 col-xs-12">
                    <p>Người hỗ trợ:</p>
                </div>
                <div className="col-md-8 col-sm-12 col-xs-12">
                    <TagsInput
                        data={bookSelect.list_support || []}
                        disabled={Boolean(!isManager || !bookSelect.book_id)}
                        onSelect={handleSelectListSupport}
                        ref={supportInputRef}
                    />
                </div>
            </div>
            <div className="item-center row mgt-3">
                {(bookSelect.user_id === userData.user_id) ?
                    <>
                        <Button
                            className="mgr"
                            onClick={handleConfirm}
                            loading={isPress}
                        >
                            Update lịch
                        </Button>
                        <Button
                            className="mgl bg-danger"
                            onClick={handleDeleteBook}
                        >
                            Huỷ lịch
                        </Button>
                    </>
                    :
                    <>
                        {!bookSelect.book_id &&
                            <Button
                                className="mgr"
                                onClick={handleConfirm}
                                loading={isPress}
                            >
                                Đặt lịch
                            </Button>
                        }
                    </>
                }
            </div>
        </Modal>
    )
}

const mapStateToProps = (state: models.GlobalState) => ({
    userData: userDataSelector(state)
})

export default connect(mapStateToProps)(ModalCreateBook)