import { Button } from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { getListMapApi, updateProgressStatusApi } from "../../apis/school";
import { IMAGES } from "../../assets";
import map from "../../models/map";
import school from "../../models/school";
import { getAvatar, openNewTab } from "../../utils/common";
import { CLASSIFY_NAME, LIST_PROGRESS, MESSAGES, RESPONSE_CODE, SCHOOL_STATUS, SCHOOL_STATUS_NAME } from "../../utils/constants";
import { showNotification } from "../../utils/notification";
import ModalMap from "../modals/ModalMap";
import Icon from 'react-fontawesome';
import ModalEditSchool from "../modals/ModalEditSchool";
import { schoolActionCreators } from "../../redux/actions/school";
import { connect } from "react-redux";
import SchoolCreate from "./SchoolCreate";
import Newfeed from "../newfeed";
import { EventBusName, onPushEventBus } from "../../services/event-bus";
import ModalChangeStatusSchool from "../modals/ModalChangeStatusSchool";
import Complete from "../common/Complete";
import ModalConfirm from "../modals/ModalConfirm";

interface Props {
    school: school.SchoolData;
    updateSchool: typeof schoolActionCreators.updateSchool;
}

const SchoolDetail = React.memo((props: Props) => {
    const { school, updateSchool } = props;

    const [showModalMap, setShowModalMap] = useState<boolean>(false)
    const [showModalEditSchool, setShowModalEditSchool] = useState<boolean>(false)
    const [showModalEditStatus, setShowModalEditStatus] = useState<boolean>(false)
    const [isEditStatus, setIsEditStatus] = useState<boolean>(false)
    const [maps, setMaps] = useState<map.MapData[]>([])
    const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false)
    const [progressSelect, setProgressSelect] = useState<string>('')
    const isPressRef = useRef(false)

    const isCompany = school?.status === SCHOOL_STATUS.COMPANY

    const checkOpenMap = () => {
        if (maps?.length === 1) {
            openNewTab(maps[0].map_link)
        }
        else toggleModalMap()
    }

    const toggleModalMap = () => {
        setShowModalMap(!showModalMap)
    }

    const toggleModalEditSchool = () => {
        setShowModalEditSchool(!showModalEditSchool)
    }

    const openDataLink = (link: string) => () => {
        openNewTab(link)
    }

    const getListMap = async () => {
        try {
            const response = await getListMapApi({ school_id: school.school_id });
            if (response.code === RESPONSE_CODE.SUCCESS) {
                setMaps(response.data)
            }
            else {
                showNotification(response.message, RESPONSE_CODE.ERROR);
            }
        }
        catch (error) {
            showNotification(MESSAGES.ERROR, RESPONSE_CODE.ERROR);
        }
    }

    const handleUpdateSuccess = (mapList: map.MapData[]) => {
        setShowModalEditSchool(false);
        setMaps(mapList);
    }

    const handleChangeProgess = async () => {
        if (!isPressRef.current) {
            const oldProgressStatus = school.progress_status;
            try {
                updateSchool({
                    ...school,
                    progress_status: progressSelect
                })
                isPressRef.current = true
                const response = await updateProgressStatusApi({ progress_status: progressSelect, school_id: school.school_id });
                showNotification(response.message, response.code);
                isPressRef.current = false
                if (response.code === RESPONSE_CODE.SUCCESS) {
                    onPushEventBus(EventBusName.UPDATE_NEW_FEED);
                }
                else {
                    updateSchool({
                        ...school,
                        progress_status: oldProgressStatus
                    })
                }
            } catch (error) {
                isPressRef.current = false
                showNotification(MESSAGES.ERROR, RESPONSE_CODE.ERROR);
                updateSchool({
                    ...school,
                    progress_status: oldProgressStatus
                })
            }
        }
    }

    const handleCreateSuccess = () => {
        onPushEventBus(EventBusName.UPDATE_NEW_FEED);
    }

    const toggleModalEditStatus = (type: boolean) => () => {
        setIsEditStatus(type)
        setShowModalEditStatus(!showModalEditStatus)
    }

    const toggleModalConfirm = (status: string = '') => () => {
        setProgressSelect(status)
        setShowModalConfirm(!showModalConfirm)
    }

    useEffect(() => {
        if (school?.school_id) getListMap()
    }, [school?.school_id])

    if (!school) {
        return (
            <Complete />
        )
    }

    return (
        <>
            {isCompany ?
                <>
                    <div className="flex flex-column relative item-center">
                        <p className="bold text-huge text-center">{school.name}</p>
                        <div className="flex company-action-container mgt">
                            <Button
                                className="button-company-switch bg-warning mgr-2"
                                onClick={toggleModalEditStatus(true)}
                            >
                                {SCHOOL_STATUS_NAME[school.status]}
                                <img
                                    src={IMAGES.UPDOWN}
                                    alt="updown"
                                    className="updown-button"
                                />
                            </Button>
                            {school.zalo_link &&
                                <img
                                    src={IMAGES.ZALO}
                                    alt='zalo-img'
                                    className="zalo-img"
                                    onClick={openDataLink(school.zalo_link)}
                                />
                            }
                            {maps?.length > 0 &&
                                <img
                                    src={IMAGES.MAP}
                                    alt='icon-school-action-img'
                                    className="icon-school-action-img"
                                    onClick={checkOpenMap}
                                />
                            }
                            {school.data_link &&
                                <img
                                    src={IMAGES.FOLDER}
                                    alt='icon-school-action-img'
                                    className="icon-school-action-img"
                                    onClick={openDataLink(school.data_link)}
                                />
                            }
                            <div
                                className="button-edit-company mgl"
                                onClick={toggleModalEditSchool}
                            >
                                <Icon name="pencil" className="icon-edit-school" />
                            </div>
                        </div>
                    </div>
                </>
                :
                <>
                    <div className="school-detail-container row align-center">
                        <div className="col-md-4 col-sm-12 col-xs-12 item-center flex-column">
                            <div className="avatar-school-container">
                                <img
                                    src={getAvatar(school.avatar)}
                                    className="avatar-school-detail"
                                    alt="avatar-school-detail"
                                />
                                {school.status === SCHOOL_STATUS.WAITING &&
                                    <div className="flex mgt">
                                        <Button className="button-right-detail flex-1 mgr" onClick={toggleModalEditStatus(true)}>
                                            {moment(school.next_date).isValid() ? moment(school.next_date).format("DD/MM/YYYY") : 'Chọn ngày tái triển khai'}
                                        </Button>
                                        {moment(school.next_date).isValid() &&
                                            <Button className="button-right-detail flex-1 mgl" onClick={toggleModalEditStatus(false)}>
                                                {moment(school.next_estimate_date).isValid() ? moment(school.next_estimate_date).format("DD/MM/YYYY") : 'Chọn ngày tái triển khai'}
                                            </Button>
                                        }
                                    </div>
                                }
                                <Button
                                    className="button-right-detail bg-warning mgt"
                                    onClick={toggleModalEditStatus(true)}
                                >
                                    {SCHOOL_STATUS_NAME[school.status]}
                                    <img
                                        src={IMAGES.UPDOWN}
                                        alt="updown"
                                        className="updown-button"
                                    />
                                </Button>
                                <div className="mgt flex align-center space-evenly">
                                    {school.zalo_link &&
                                        <img
                                            src={IMAGES.ZALO}
                                            alt='zalo-img'
                                            className="zalo-img"
                                            onClick={openDataLink(school.zalo_link)}
                                        />
                                    }
                                    {maps?.length > 0 &&
                                        <img
                                            src={IMAGES.MAP}
                                            alt='icon-school-action-img'
                                            className="icon-school-action-img"
                                            onClick={checkOpenMap}
                                        />
                                    }
                                    {school.data_link &&
                                        <img
                                            src={IMAGES.FOLDER}
                                            alt='icon-school-action-img'
                                            className="icon-school-action-img"
                                            onClick={openDataLink(school.data_link)}
                                        />
                                    }
                                    <div
                                        className="button-edit-school"
                                        onClick={toggleModalEditSchool}
                                    >
                                        <Icon name="pencil" className="icon-edit-school" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8 col-sm-12 col-xs-12">
                            <div className="flex relative">
                                <p className="bold text-huge">{school.name}</p>
                            </div>
                            <p className="bold">{school.address}</p>
                            <p className="bold">• MST: {school.mst}</p>
                            <p className="bold">• Số lượng cơ sở: {school.base_total}</p>
                            <p className="bold">• Số lớp: {school.class_total}</p>
                            <p className="bold">• SL học sinh: {school.student_total}</p>
                            <p className="bold">• Xã/Phường: {school.ward_name}</p>
                            <p className="bold">• Quận/Huyện: {school.district_name}</p>
                            <p className="bold">• Tỉnh/TP: {school.province_name}</p>
                            <p className="bold">• Thông tin liên hệ: {school.contact_info}</p>
                        </div>
                    </div>
                    <div className="row-list-progress-switch-school">
                        {LIST_PROGRESS.map((item, key) =>
                            <Button
                                className={`button-action-switch ${school.progress_status === item.status ? 'bg-warning' : ''}`}
                                key={key}
                                onClick={toggleModalConfirm(item.status)}
                            >
                                {item.name}
                            </Button>
                        )}
                    </div>
                </>
            }
            <SchoolCreate
                school={school}
                onCreateSuccess={handleCreateSuccess}
            />
            <Newfeed school={school} />
            <ModalMap
                showModal={showModalMap}
                onClose={toggleModalMap}
                maps={maps}
            />
            <ModalEditSchool
                showModal={showModalEditSchool}
                onClose={toggleModalEditSchool}
                school={school}
                maps={maps}
                onUpdateSuccess={handleUpdateSuccess}
            />
            <ModalChangeStatusSchool
                showModal={showModalEditStatus}
                onClose={toggleModalEditStatus(true)}
                school={school}
                maps={maps}
                isEditStatus={isEditStatus}
            />
            <ModalConfirm
                showModal={showModalConfirm}
                onClose={toggleModalConfirm()}
                title="Bạn có chắc chắn mình đủ thẩm quyền thay đổi trạng thái này hay không?"
                onConfirm={handleChangeProgess}
            />
        </>
    )
})

const mapDispatchToProps = {
    updateSchool: schoolActionCreators.updateSchool,
};

export default connect(undefined, mapDispatchToProps)(SchoolDetail);