import axios from 'axios';
import { END_POINTS } from '..';
import models from '../../models';
import newfeed from '../../models/newfeed';
const qs = require('qs');


export const publishVideoApi = (params: newfeed.VideoRequest) =>{
    const url = END_POINTS.publishVideo;
	return axios.post(url, qs.stringify(params)).then((res: { data: models.DefaultResponse }) => res.data);
}
