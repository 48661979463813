import models from "../../models";
import school from "../../models/school";

class SchoolsActions {
    public readonly GET_LIST_SCHOOL = 'GET_LIST_SCHOOL';
    public readonly GET_LIST_SCHOOL_SUCCESS = 'GET_LIST_SCHOOL_SUCCESS';
    public readonly GET_LIST_SCHOOL_FAILURE = 'GET_LIST_SCHOOL_FAILURE';

    public readonly UPDATE_SCHOOL = 'UPDATE_SCHOOL';

    public readonly SELECT_SCHOOL = 'SELECT_SCHOOL';

    public readonly GET_LIST_BRANCH = 'GET_LIST_BRANCH';
    public readonly GET_LIST_BRANCH_SUCCESS = 'GET_LIST_BRANCH_SUCCESS';
    public readonly GET_LIST_BRANCH_FAILURE = 'GET_LIST_BRANCH_FAILURE';
}

export declare namespace schoolActionTypes {
    type getListSchoolActionType = models.TypedAction<typeof schoolActions.GET_LIST_SCHOOL, school.SchoolRequest>;
    type getListSchoolSuccessActionType = models.TypedAction<typeof schoolActions.GET_LIST_SCHOOL_SUCCESS, school.SchoolData[]>;
    type getListSchoolFailureActionType = models.TypedActionError<typeof schoolActions.GET_LIST_SCHOOL_FAILURE, string>;

    type updateSchoolActionType = models.TypedAction<typeof schoolActions.UPDATE_SCHOOL, school.SchoolData>;

    type selectSchoolActionType = models.TypedAction<typeof schoolActions.SELECT_SCHOOL, string>;

    type getListBranchActionType = models.TypedActionEmpty<typeof schoolActions.GET_LIST_BRANCH>;
    type getListBranchSuccessActionType = models.TypedAction<typeof schoolActions.GET_LIST_BRANCH_SUCCESS, school.Branch[]>;
    type getListBranchFailureActionType = models.TypedActionError<typeof schoolActions.GET_LIST_BRANCH_FAILURE, string>;
}

class SchoolActionCreators {
    public getListSchool = (payload: school.SchoolRequest): schoolActionTypes.getListSchoolActionType => ({ type: schoolActions.GET_LIST_SCHOOL, payload });
    public getListSchoolSuccess = (payload: school.SchoolData[]): schoolActionTypes.getListSchoolSuccessActionType => ({ type: schoolActions.GET_LIST_SCHOOL_SUCCESS, payload });
    public getListSchoolFailure = (error: string): schoolActionTypes.getListSchoolFailureActionType => ({ type: schoolActions.GET_LIST_SCHOOL_FAILURE, error });

    public updateSchool = (payload: school.SchoolData): schoolActionTypes.updateSchoolActionType => ({ type: schoolActions.UPDATE_SCHOOL, payload });

    public selectSchool = (payload: string): schoolActionTypes.selectSchoolActionType => ({ type: schoolActions.SELECT_SCHOOL, payload });

    public getListBranch = (): schoolActionTypes.getListBranchActionType => ({ type: schoolActions.GET_LIST_BRANCH });
    public getListBranchSuccess = (payload: school.Branch[]): schoolActionTypes.getListBranchSuccessActionType => ({ type: schoolActions.GET_LIST_BRANCH_SUCCESS, payload });
    public getListBranchFailure = (error: string): schoolActionTypes.getListBranchFailureActionType => ({ type: schoolActions.GET_LIST_BRANCH_FAILURE, error });
}

export const schoolActions = new SchoolsActions();
export const schoolActionCreators = new SchoolActionCreators();