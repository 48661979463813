import { Button, Modal } from "antd";
import React, { useRef, useState } from "react"
import { MESSAGES, PROP, RESPONSE_CODE } from "../../utils/constants";
import school from "../../models/school";
import Icon from 'react-fontawesome'
import { dataURLtoFile, getImage64, uuidv4 } from "../../utils/common";
import { publishPostApi } from "../../apis/post";
import { showNotification } from "../../utils/notification";
import ReactQuill from "react-quill";

type Props = {
    showModal: boolean;
    onClose: () => void;
    onCreateSuccess: () => void;
    school: school.SchoolData;
}

const ModalCreatePost = (props: Props) => {
    const { showModal, onClose, onCreateSuccess, school } = props;
    const [images, setImages] = useState<string[]>([])
    const [inputTitle, setInputTitle] = useState<string>('')
    const [inputContent, setInputContent] = useState<string>('')
    const [isPress, setIsPress] = useState<boolean>()

    const inputImageRef = useRef<HTMLInputElement>(null)
    const imagesRef = useRef<string[]>([])
    const inputContentRef = useRef<any>('');
    const imageTimeoutRef = useRef<NodeJS.Timeout>()

    const handleChooseImage = () => {
        inputImageRef.current?.click()
    }

    const handleChangeImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
        imagesRef.current = [];
        const files = event?.target?.files;
        if (files && files?.length > 0) {
            for (var i = 0; i < files.length; i++) {
                getImage64(files[i], image64 => {
                    imagesRef.current.push(image64)
                    if (imageTimeoutRef.current) clearTimeout(imageTimeoutRef.current)
                    setTimeout(() => {
                        setImages(imagesRef.current)
                    }, 700)
                });
            }
            setTimeout(() => {
                setImages(imagesRef.current)
            }, 1200)
        }
    }

    const handleUploadImage = async () => {
        try {
            if (!isPress) {
                const formData = new FormData();
                if (images.length > 0) {
                    const files = images.map((item) => dataURLtoFile(item, `${uuidv4()}.png`))
                    files.forEach((file, index) => {
                        formData.append(`images[${index}]`, file);
                    });
                }
                formData.append('title', inputTitle)
                formData.append('content', inputContent)
                formData.append('school_id', school.school_id);
                setIsPress(true)
                const response = await publishPostApi(formData);
                setIsPress(false)
                showNotification(response.message, response.code)
                if (response.code === RESPONSE_CODE.SUCCESS) {
                    onClose();
                    onCreateSuccess();
                    setImages([]);
                    setInputTitle('')
                }
            }
        } catch (error) {
            showNotification(MESSAGES.ERROR, RESPONSE_CODE.ERROR)
            setIsPress(false)
        }
    }

    return (
        <Modal
            {...PROP.MODAL}
            open={showModal}
            onCancel={onClose}
            width='120vh'
            centered={false}
            destroyOnClose={true}
        >
            <input
                className="form-control mgt-3"
                value={inputTitle}
                placeholder='Nhập tiêu đề'
                onChange={({ target: { value } }) => setInputTitle(value)}
            />
            <div
                className='mgt-2'
                style={{ cursor: 'text' }}
                onClick={() => inputContentRef.current.focus()}
            >
                <ReactQuill
                    ref={inputContentRef}
                    value={inputContent}
                    onChange={setInputContent}
                    modules={{ toolbar: [['bold', 'italic', 'underline']] }}
                    placeholder='Nhập nội dung'
                />
            </div>
            <div
                className="box-upload-image"
                onClick={handleChooseImage}
            >
                <Icon name='file-image-o' className="icon-image-upload" />
                <p className="mgt-3">Duyệt ảnh</p>
            </div>
            <div className="image-grid-upload">
                {images.map((item, key) =>
                    <img
                        src={item}
                        key={key}
                        className='image-grid-upload-item'
                        alt=''
                    />
                )}
            </div>
            <div className="item-center mgt-3">
                <Button
                    loading={isPress}
                    onClick={handleUploadImage}
                >
                    Đăng nội dung
                </Button>
            </div>
            <input
                type="file"
                multiple
                accept="image/*"
                ref={inputImageRef}
                hidden={true}
                onChange={handleChangeImage}
            />
        </Modal>
    )
}

export default ModalCreatePost