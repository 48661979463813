import React, { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { PROP } from "../../utils/constants";
import school from "../../models/school";
import { type } from "os";
import { TagInputRef } from "./TagInput";
import { searchSchoolApi } from "../../apis/school";
import { getAvatar } from "../../utils/common";

type Props = {
    status: string;
    disabled?: boolean
}

export type SearchSchoolRef = {
    getValue: () => school.SchoolData
}

const SearchSchool = (props: Props, ref: React.Ref<SearchSchoolRef>) => {
    const [itemSearch, setItemSearch] = useState<school.SchoolData[]>([]);
    const [schoolSelect, setSchoolSelect] = useState<school.SchoolData>();
    const [key, setKey] = useState(0);
    const [value, setValue] = useState('');
    const inputRef = useRef<any>(null)

    const { status, disabled } = props

    useImperativeHandle(ref, () => ({
        getValue: () => {
            return schoolSelect as school.SchoolData;
        },
        setValue: (data: string) =>{
            setValue(data);
            setKey(key + 1);
        }
    }));

    const handleSearch = async (search_text: string) => {
        const response = await searchSchoolApi({ search_text, status })
        if(Array.isArray(response.data)) setItemSearch(response.data)
        else {
            setItemSearch([])
            setSchoolSelect(undefined)
        }
    }

    const handleSelected = (selected: school.SchoolData[]) => {
        setSchoolSelect(selected[0])
    }

    return (
        <AsyncTypeahead
            {...PROP.TYPE_AHEAD}
            labelKey='name'
            filterBy={['name']}
            renderMenuItemChildren={(option: any) => (
                <div className='flex-row item-center'>
                    <div className='flex-row search-user'>
                        <img alt='avatar' className='avatar-guest-small' src={getAvatar(option.avatar)} />
                        <p className='search-user-text'>{option.name}</p>
                    </div>
                </div>
            )}
            onSearch={handleSearch}
            options={itemSearch}
            onChange={handleSelected}
            ref={inputRef}
            className="school form-control"
            minLength={1}
            defaultInputValue={value}
            key={key}
            disabled={disabled}
        />
    )
}

export default forwardRef(SearchSchool)