import React, { useState } from "react";
import { connect } from "react-redux";
import models from "../../../models";
import { userDataSelector } from "../../../redux/selectors/user";
import newfeed from "../../../models/newfeed";
import TooltipUser from "../../common/TooltipUser";
import { array_move, convertDateTime } from "../../../utils/common";
import { URI_PATH } from "../../../utils/constants";
import FsLightbox from 'fslightbox-react';
import { EventBusName, onPushEventBus } from "../../../services/event-bus";

interface Props {
    data: newfeed.Post
}

const Post = (props: Props) => {
    const { data } = props;
    const images = data.list_images.map(item => `${URI_PATH}/${item.post_image}`)
    const [imagesShow, setImagesShow] = useState(images)

    const [showPreviewImage, setShowPreviewImage] = useState<boolean>(false)

    const hidePreviewImage = () => {
        setShowPreviewImage(false)
    }

    const openPreviewImage = (key: number) => {
        setImagesShow(array_move(images, key, 0))
        setShowPreviewImage(true)
    }

    const handleClickSchool = (schoolId: string) => () =>{
        onPushEventBus(EventBusName.SELECT_SCHOOL_MODAL, schoolId)
    }

    return (
        <div>
            <div className="flex-row">
                <TooltipUser data={data} />
                <div className='flex-column mgl-2'>
                    <p className="black text-large bold">{data.user_name}</p>
                    <p className="text-time silver">{convertDateTime(data.cr_date_time)}</p>
                    <p
                        className="mgb-0 pointer"
                        onClick={handleClickSchool(data.school_id)}
                    >
                        <span className="gray">Tại </span>
                        <u><span className="bold">{data.name}</span></u>
                    </p>
                </div>
            </div>
            <p className="mgt-2 text-large bold">{data.post_title}</p>
            {data.post_content &&
                <div className='text-left' dangerouslySetInnerHTML={{ __html: data.post_content }} />
            }
            {images.length > 0 &&
                <div className="image-grid-upload">
                    {images.map((item, key) =>
                        <img
                            src={item}
                            key={key}
                            className='image-grid-upload-item'
                            alt=''
                            onClick={() => openPreviewImage(key)}
                        />
                    )}
                </div>
            }
            {showPreviewImage &&
                <FsLightbox
                    toggler={showPreviewImage}
                    sources={imagesShow}
                    openOnMount={true}
                    onClose={hidePreviewImage}
                    key={0}
                    type='image'
                />
            }
        </div>
    )
}

const mapStateToProps = (state: models.GlobalState) => ({
    userData: userDataSelector(state)
})

export default connect(mapStateToProps)(Post)