import { all, apply, put, takeEvery, takeLatest } from "redux-saga/effects";
import { changeBranchApi, getCountDataApi, getUserDataApi } from "../../apis/user";
import user from "../../models/user";
import { KEY, MESSAGES, RESPONSE_CODE } from "../../utils/constants";
import { userActionCreators, userActionTypes, userActions } from "../actions/user";
import Axios from "axios";
import Cookies from "js-cookie";
import { applyToken } from "../../utils/common";
import { EventBusName, onPushEventBus } from "../../services/event-bus";
import { showNotification } from "../../utils/notification";

export function* userRuntime() {
    yield all([
        takeEvery(userActions.GET_USER_DATA, getUserDataRuntime),
        takeEvery(userActions.GET_COUNT_DATA, getCountDataRuntime),
        takeLatest(userActions.CHANGE_BRANCH, changeBranchRuntime)
    ]);
}

function* getUserDataRuntime(){
    try {
        const response: user.UserResponse = yield getUserDataApi();
        if(response.code === 1){
            applyToken(response.token!);
            yield put(userActionCreators.getUserDataSuccess(response.data));
        }
        else if(response.code === -3){
            Cookies.remove(KEY.TOKEN);
            const win: Window = window;
            win.location = "/";
        }
        else put(userActionCreators.getUserDataFailure(response.message));
    } catch (err) {
        yield put(userActionCreators.getUserDataFailure(MESSAGES.ERROR));
    }
}

function* changeBranchRuntime(action: userActionTypes.changeBranchActionType){
    try {
        const branch_id = action.payload;
        const response: user.UserResponse = yield changeBranchApi({branch_id})
        if(response.code === 1){
            yield put(userActionCreators.changeBranchSuccess(branch_id));
            yield put(userActionCreators.getCountData());
            applyToken(response.token!);
            onPushEventBus(EventBusName.CHANGE_BRANCH)
        }
        else showNotification(MESSAGES.ERROR, RESPONSE_CODE.ERROR)
    } catch (err) {
        showNotification(MESSAGES.ERROR, RESPONSE_CODE.ERROR)
    }
}

function* getCountDataRuntime(){
    try {
        const response: user.CountDataResponse = yield getCountDataApi();
        if(response.code === 1){
            yield put(userActionCreators.getCountDataSuccess(response.data));
        }
        else showNotification(MESSAGES.ERROR, RESPONSE_CODE.ERROR)
    } catch (err) {
        showNotification(MESSAGES.ERROR, RESPONSE_CODE.ERROR)
    }
}