import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Loading from "../components/common/Loading";
import SchoolDetail from "../components/school/SchoolDetail";
import models from "../models";
import school from "../models/school";
import { schoolActionCreators } from "../redux/actions/school";
import { schoolSelectedSelector, schoolsSelector } from "../redux/selectors/school";
import { SCHOOL_STATUS } from "../utils/constants";

interface Props extends RouteComponentProps{
    schools: school.SchoolData[];
    selectedSchool: school.SchoolData['school_id'];
    getListSchool: typeof schoolActionCreators.getListSchool;
    selectSchool: typeof schoolActionCreators.selectSchool;
}

const Todo = React.memo((props: Props) => {
    const { schools, selectedSchool, getListSchool, selectSchool } = props;
    const [isLoading, setIsLoading] = useState<boolean>(true)

    const school = (selectedSchool ? schools.find(i => i.school_id === selectedSchool) : undefined) as school.SchoolData;

    useEffect(() =>{
        setTimeout(() => setIsLoading(false), 200)
        getListSchool({
            status: SCHOOL_STATUS.TODO,
        })

        return(() =>{
            selectSchool('')
        })
    }, [])

    if(isLoading) return <Loading />

    return (
        <div>
            <SchoolDetail school={school} />
        </div>
    )
})

const mapStateToProps = (state: models.GlobalState) => ({
    schools: schoolsSelector(state),
    selectedSchool: schoolSelectedSelector(state),
});

const mapDispatchToProps = {
    getListSchool: schoolActionCreators.getListSchool,
    selectSchool: schoolActionCreators.selectSchool
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Todo));