import { Button, Modal } from "antd";
import { useState } from "react";
import { MESSAGES, PROP, RESPONSE_CODE } from "../../utils/constants";
import Rating from 'react-rating';
import { markWorkApi } from "../../apis/work";
import { showNotification } from "../../utils/notification";

type Props = {
    showModal: boolean;
    onClose: () => void;
    workId: string;
    onSuccess: (point: number)=> void
}

const ModalMarkWork = (props: Props) => {
    const { showModal, onClose, workId, onSuccess } = props;
    const [point, setPoint] = useState<number>(0)
    const [isPress, setIsPress] = useState<boolean>(false)

    const handleConfirm = async() =>{
        try {
            if(!isPress){
                const response = await markWorkApi({work_id: workId, point: `${point}`});
                showNotification(response.message, response.code)
                if(response.code === RESPONSE_CODE.SUCCESS){
                    onSuccess(point);
                }
            }
        } catch (error) {
            showNotification(MESSAGES.ERROR, RESPONSE_CODE.ERROR)
            setIsPress(false)
        }

    }

    return (
        <Modal
            {...PROP.MODAL}
            open={showModal}
            onCancel={onClose}
        >
            <p className="mgt-2 text-center bold text-large">Đánh giá mức độ hài lòng</p>
            <div className="flex-column item-center mgt-2">
                {/* @ts-ignore */}
                <Rating
                    emptySymbol='fa fa-star-o fa-3x mgr'
                    fullSymbol='fa fa-star fa-3x mgr'
                    initialRating={point}
                    onChange={setPoint}
                    className='mgb-3'
                />
                <Button
                    className="mgt-4"
                    onClick={handleConfirm}
                >
                    Xác nhận
                </Button>
            </div>
        </Modal>
    )
}

export default ModalMarkWork