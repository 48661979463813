import { Button, Modal } from "antd";
import moment from "moment";
import { convertDate } from "../../utils/common";
import { MESSAGES, PROP, RESPONSE_CODE, TIME_BOOK_TYPE_NAME, TimeBookType } from "../../utils/constants";
import { useState } from "react";
import { unlockBookApi } from "../../apis/book";
import { showNotification } from "../../utils/notification";

type Props = {
    showModal: boolean;
    onClose: () => void;
    date: string;
    timeType: TimeBookType;
    onCreateSuccess: () => void;
}

const ModalUnlockBook = (props: Props) =>{
    const { showModal, onClose, timeType, date, onCreateSuccess } = props;

    const [isPress, setIsPress] = useState(false)

    const handleUnlock = async() =>{
        try {
            if(!isPress){
                setIsPress(true)
                const response = await unlockBookApi({
                    time_type: `${timeType}`,
                    book_date: date,
                })
                setIsPress(false)
                showNotification(response.message, response.code)
                if (response.code === RESPONSE_CODE.SUCCESS) {
                    onCreateSuccess()
                    onClose()
                }
            }
        } catch (error) {
            showNotification(MESSAGES.ERROR, RESPONSE_CODE.ERROR)
            setIsPress(false)
        }
    }

    return(
        <Modal
            {...PROP.MODAL}
            open={showModal}
            onCancel={onClose}
        >
            <p className="text-center mgt-2">Xác nhận mở khóa book máy vào <span className="bold">{TIME_BOOK_TYPE_NAME[timeType]} {convertDate(moment(date, "YYYY-MM-DD").format("YYYY-MM-DD"))}</span></p>
            <div className="item-center mgt-3">
                <Button loading={isPress} onClick={handleUnlock}>Mở khóa</Button>
            </div>
        </Modal>
    )
}

export default ModalUnlockBook