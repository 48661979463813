import { createSelector } from 'reselect';
import models from '../../models';
import user from '../../models/user';
import { USER_INITIAL_STATE } from '../reducers/user';

const userSelector = (state: models.GlobalState) => state.user;

export const userDataSelector = createSelector(
    userSelector,
    data => data.userData as user.User ?? USER_INITIAL_STATE.userData
)

export const countDataSelector = createSelector(
    userSelector,
    data => data.countData as user.CountData ?? USER_INITIAL_STATE.countData
)
