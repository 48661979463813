import { Button, Modal } from "antd";
import React, { useEffect, useRef, useState } from "react"
import { MESSAGES, PROP, RESPONSE_CODE } from "../../utils/constants";
import school from "../../models/school";
import Icon from 'react-fontawesome'
import { dataURLtoFile, getImage64, getYoutubeId, uuidv4 } from "../../utils/common";
import { publishPostApi } from "../../apis/post";
import { showNotification } from "../../utils/notification";
import { publishVideoApi } from "../../apis/video";

type Props = {
    showModal: boolean;
    onClose: () => void;
    onCreateSuccess: () => void;
    school: school.SchoolData;
}

const ModalCreateVideo = (props: Props) => {
    const { showModal, onClose, onCreateSuccess, school } = props;
    const [inputTitle, setInputTitle] = useState<string>('')
    const [inputUrl, setInputUrl] = useState<string>('')
    const [isPress, setIsPress] = useState<boolean>()
    const [showPreviewVideo, setShowPreviewVideo] = useState<boolean>(false)

    const handleChangeInputUrl = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
        setInputUrl(value);
        setShowPreviewVideo(getYoutubeId(value) ? true : false)
    }

    const handlePublishVideo = async () => {
        try {
            if (!isPress) {
                if (!inputTitle) return showNotification('Bạn chưa nhập tiêu đề của video.', RESPONSE_CODE.ERROR);
                if (!getYoutubeId(inputUrl)) return showNotification('Link video từ youtube không đúng.', RESPONSE_CODE.ERROR);
                setIsPress(true)
                const response = await publishVideoApi({
                    video_title: inputTitle,
                    video_url: inputUrl,
                    school_id: school.school_id
                })
                setIsPress(false)
                showNotification(response.message, response.code)
                if (response.code === RESPONSE_CODE.SUCCESS) {
                    onClose();
                    onCreateSuccess();
                    setInputTitle('');
                    setInputUrl('');
                    setShowPreviewVideo(false)
                }
            }
        } catch (error) {

        }
    }

    return (
        <Modal
            {...PROP.MODAL}
            open={showModal}
            onCancel={onClose}
            width='120vh'
            centered={false}
            destroyOnClose={true}
        >
            <p className="mgt-2">Tiêu đề:</p>
            <input
                className="form-control"
                value={inputTitle}
                placeholder='Nhập tiêu đề video'
                onChange={({ target: { value } }) => setInputTitle(value)}
            />
            <p className='mgt-3'>Link video từ youtube:</p>
            <input
                className="form-control"
                value={inputUrl}
                placeholder='Nhập link từ youtube:'
                onChange={handleChangeInputUrl}
            />
            {showPreviewVideo &&
                <div className='item-center mgt'>
                    <iframe
                        src={"https://www.youtube.com/embed/" + getYoutubeId(inputUrl)}
                        allow="encrypted-media"
                        allowFullScreen={true}
                        style={{ width: '100%', height: '60vh', borderRadius: '2vh' }}
                        title='youtube'
                    />
                </div>
            }
            <div className="item-center">
                <Button
                    className="mgt-3"
                    loading={isPress}
                    onClick={handlePublishVideo}
                >
                    Đăng video
                </Button>
            </div>
        </Modal>
    )
}

export default ModalCreateVideo