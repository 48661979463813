import axios from 'axios';
import { END_POINTS } from '..';
import models from '../../models';

export const publishPostApi = (params: FormData) =>{
    const url = END_POINTS.publishPost;
	return axios.post(url, params, {headers: {
        "Content-Type": "multipart/form-data"
    }}).then((res: { data: models.DefaultResponse }) => res.data);
}
