//@ts-ignore
import Drawer, { DrawerContainer, MainContentContainer } from "react-swipeable-drawer";
import NavLeft from "../layout/NavLeft";

type Props = {
    children: React.ReactNode
}

const MobileDrawer = (props: Props) => {
    return (
        <Drawer position="right" size={90}>
            {({
                position,
                size,
                swiping,
                translation,
                mainContentScroll,
                toggleDrawer,
                handleTouchStart,
                handleTouchMove,
                handleTouchEnd,
            }: any) => (
                <>
                    <DrawerContainer
                        position={position}
                        size={size}
                        swiping={swiping}
                        translation={translation}
                        toggleDrawer={toggleDrawer}
                        handleTouchStart={handleTouchStart}
                        handleTouchMove={handleTouchMove}
                        handleTouchEnd={handleTouchEnd}
                        drawerContent={
                            <div className="drawer-content">
                                <NavLeft toggleDrawer={toggleDrawer} isMobile={true} />
                            </div>
                        }
                    />
                    <MainContentContainer
                        translation={translation}
                        mainContentScroll={mainContentScroll}
                    >
                        {props.children}
                    </MainContentContainer>
                </>
            )
            }
        </Drawer>
    )
}

export default MobileDrawer