import React from 'react';
import Lottie from 'react-lottie';
import { LOTTIE } from '../../assets';

const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: LOTTIE.LOADING
};

const Loading = () =>{
    return(
        <div className="item-center">
            <Lottie options={defaultOptions}
                height='10vh'
                width='10vh'
            />
        </div>
    )
}

export default Loading;