import models from "../../models";
import location from "../../models/locaion";

class LocationActions {
    public readonly GET_LIST_PROVINCE = 'GET_LIST_PROVINCE';
    public readonly GET_LIST_PROVINCE_SUCCESS = 'GET_LIST_PROVINCE_SUCCESS';
    public readonly GET_LIST_PROVINCE_FAILURE = 'GET_LIST_PROVINCE_FAILURE';
}

export declare namespace locationActionTypes {
    type getListProvinceActionType = models.TypedActionEmpty<typeof locationActions.GET_LIST_PROVINCE>;
    type getListProvinceSuccessActionType = models.TypedAction<typeof locationActions.GET_LIST_PROVINCE_SUCCESS, location.Province[]>;
    type getListProvinceFailureActionType = models.TypedActionError<typeof locationActions.GET_LIST_PROVINCE_FAILURE, string>;
}

class LocationActionCreators {
    public getListProvince = (): locationActionTypes.getListProvinceActionType => ({ type: locationActions.GET_LIST_PROVINCE });
    public getListProvinceSuccess = (payload: location.Province[]): locationActionTypes.getListProvinceSuccessActionType => ({ type: locationActions.GET_LIST_PROVINCE_SUCCESS, payload });
    public getListProvinceFailure = (error: string): locationActionTypes.getListProvinceFailureActionType => ({ type: locationActions.GET_LIST_PROVINCE_FAILURE, error });
}

export const locationActions = new LocationActions();
export const locationActionCreators = new LocationActionCreators();