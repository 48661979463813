import { all, put, takeEvery } from "redux-saga/effects";
import { getListProvinceApi } from "../../apis/location";
import location from "../../models/locaion";
import { MESSAGES } from "../../utils/constants";
import { locationActionCreators, locationActions } from "../actions/location";

export function* locationRuntime() {
    yield all([
        takeEvery(locationActions.GET_LIST_PROVINCE, getListProvinceRuntime),
    ]);
}

function* getListProvinceRuntime(){
    try {
        const response: location.ProvinceResponse = yield getListProvinceApi();
        if(response.code === 1){
            yield put(locationActionCreators.getListProvinceSuccess(response.data));
        }
        else put(locationActionCreators.getListProvinceFailure(response.message));
    } catch (err) {
        yield put(locationActionCreators.getListProvinceFailure(MESSAGES.ERROR));
    }
}