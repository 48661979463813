import { Modal, Tooltip } from "antd";
import React, { useEffect, useRef, useState } from "react";
import school from "../../models/school";
import { MESSAGES, PROP, RESPONSE_CODE, URI_PATH } from "../../utils/constants";
import Icon from 'react-fontawesome'
import Emoji from "../common/Emoji";
import { getListChatApi, sendChatApi } from "../../apis/work";
import { showNotification } from "../../utils/notification";
import { getAvatar, getImage64 } from "../../utils/common";
import work from "../../models/work";
import Loading from "../common/Loading";
import { connect } from "react-redux";
import models from "../../models";
import { userDataSelector } from "../../redux/selectors/user";
import user from "../../models/user";
import TooltipUser from "../common/TooltipUser";
import FsLightbox from "fslightbox-react";
import { socket } from "../socket/Socket";
import EventBus, { BaseEventType, EventBusName } from "../../services/event-bus";
import { Subscription } from "rxjs";
import { userActionCreators } from "../../redux/actions/user";

type Props = {
    showModal: boolean;
    onClose: () => void;
    workId: string;
    userData: user.User;
    onChat: (data: work.Chat) => void;
    getCountData: typeof userActionCreators.getCountData
}

const ModalWorkChat = (props: Props) => {
    const { showModal, onClose, workId, userData, onChat, getCountData } = props;

    const [inputChat, setinputChat] = useState<string>('')
    const [showEmoji, setShowEmoji] = useState<boolean>(false)
    const [isSend, setIsSend] = useState<boolean>(false)

    const [listChat, setListChat] = useState<work.Chat[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [imagePreview, setImagePreview] = useState<string>('')

    const inputImageRef = useRef<HTMLInputElement>(null)

    const toggleShowEmoji = () => setShowEmoji(!showEmoji)

    const subScription = new Subscription();

    const handlePickEmoji = (event: any) => {
        const emoji = event.target.firstChild.nodeValue;
        setinputChat(inputChat + emoji)
    }

    const handleSend = async (message: string, is_image: string = '') => {
        try {
            setIsSend(true)
            const response = await sendChatApi({ message, is_image, work_id: workId })
            setIsSend(false)
            setShowEmoji(false)
            if (response.code === RESPONSE_CODE.SUCCESS) {
                setinputChat('')
                const dataSend: work.Chat = {
                    work_chat_id: response.workChatId,
                    work_id: workId,
                    user_id: userData.user_id,
                    message: is_image === '' ? message : response.imageUrl,
                    type: is_image === '' ? '0' : '1',
                    list_user_id_read: [userData.user_id],
                    cr_date_time: 'Vừa xong',
                    avatar: userData.avatar,
                    user_name: userData.user_name,
                    count_chat: `${listChat.length + 1}`
                };
                setListChat([
                    dataSend,
                    ...listChat,
                ])
                onChat(dataSend)
                socket.emit('send_chat_work_happyidem', dataSend)
            }
            else {
                showNotification(response.message, response.code);
            }
        } catch (error) {
            setIsSend(false)
            showNotification(MESSAGES.ERROR, RESPONSE_CODE.ERROR)
        }
    }

    const sendChat = () => {
        const input = inputChat.trim();
        if (!isSend && input !== '') {
            handleSend(inputChat);
        }
    }

    const handleUploadImage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event?.target?.files?.[0] as File;
        getImage64(file, image64 => {
            handleSend('', image64);
        });
        if (inputImageRef.current) inputImageRef.current.value = '';
    }

    const getListChat = async () => {
        try {
            const response = await getListChatApi({ work_id: workId })
            if (response.code === RESPONSE_CODE.SUCCESS) {
                setListChat(response.data)
                setIsLoading(false);
                getCountData()
            }
            else showNotification(MESSAGES.ERROR, RESPONSE_CODE.ERROR)
        } catch (error) {
            showNotification(MESSAGES.ERROR, RESPONSE_CODE.ERROR)
        }
    }

    const showPreviewImage = (image: string) => () => {
        setImagePreview(getAvatar(image))
    }

    const onRegisterEventBus = () => {
        const sub = EventBus.getInstance().events.subscribe((res: BaseEventType) => {
            if (res.type === EventBusName.RECEIVE_SOCKET_WORK_CHAT) {
                if(res.payload.work_id === workId){
                    setListChat([
                        res.payload,
                        ...listChat,
                    ])
                }
            }
        });
        subScription.add(sub);
    };

    useEffect(() => {
        onRegisterEventBus();
        return () => {
            subScription?.unsubscribe?.();
        };
    }, [listChat, workId]);

    useEffect(() => {
        if (showModal) {
            getListChat()
        }
    }, [showModal])

    return (
        <Modal
            {...PROP.MODAL}
            open={showModal}
            onCancel={onClose}
            width='100vh'
        >
            {isLoading ? <Loading />
                :
                <>
                    <div className='box-chat-work'>
                        {listChat.map((item) => {
                            const isUserChat = item.user_id === userData.user_id;
                            return (
                                <React.Fragment key={item.work_chat_id}>
                                    <div className={isUserChat ? "chat-work-message-user" : "chat-work-message-guest"}>
                                        <TooltipUser data={item} className='avatar-message' />
                                        <div className={isUserChat ? "chat-work-message-item-user" : "chat-work-message-item-guest"}>
                                            <Tooltip
                                                {...PROP.TOOLTIP}
                                                overlayClassName='tooltip-user'
                                                title={
                                                    <div className='item-center'>
                                                        <p className='color-white bold'>{item.cr_date_time}</p>
                                                    </div>
                                                }
                                                key={item.work_chat_id}
                                            >
                                                {item.type === '1' ?
                                                    <div className='item-center'>
                                                        <img
                                                            src={getAvatar(item.message)}
                                                            className='image-chat pointer'
                                                            onClick={showPreviewImage(item.message)}
                                                            alt='imagechat'
                                                        />
                                                    </div>
                                                    :
                                                    <p className="mgb-0">{item.message}</p>
                                                }
                                            </Tooltip>
                                        </div>
                                    </div>
                                </React.Fragment>
                            )
                        })}
                    </div>
                    <div className='input-chat-work-container'>
                        <input
                            className='input-chat-work bg-transparent'
                            placeholder='Nhập nôi dung'
                            value={inputChat}
                            onChange={({ target: { value } }) => setinputChat(value)}
                            onFocus={() => setShowEmoji(false)}
                            onKeyDown={e => {
                                if (e.keyCode === 13) {
                                    sendChat();
                                    e.preventDefault();
                                }
                            }}
                        />
                        <Icon
                            name='smile-o'
                            className={'emoji-chat-work-select ' + (showEmoji ? 'success' : null)}
                            onClick={toggleShowEmoji}
                            onMouseEnter={() => setShowEmoji(true)}
                        />
                        <Icon
                            name='picture-o'
                            className='emoji-chat-work-select'
                            onClick={() => inputImageRef.current?.click()}
                        />
                        <Icon
                            name='paper-plane'
                            className='emoji-chat-work-select'
                            style={{ marginRight: '2vh' }}
                            onClick={sendChat}
                        />
                        {showEmoji &&
                            <Emoji onPick={handlePickEmoji} onEndPick={() => setShowEmoji(true)} />
                        }
                    </div>
                    <input
                        type='file'
                        accept='image/*'
                        hidden={true}
                        ref={inputImageRef}
                        onChange={handleUploadImage}
                    />
                    {imagePreview &&
                        <FsLightbox
                            toggler={imagePreview !== ''}
                            sources={[imagePreview]}
                            disableLocalStorage={true}
                            openOnMount={true}
                            onClose={() => setImagePreview('')}
                            key={0}
                            type='image'
                        />
                    }
                </>
            }
        </Modal>
    )
}

const mapStateToProps = (state: models.GlobalState) => ({
    userData: userDataSelector(state)
})

const mapDispatchToProps = {
    getCountData: userActionCreators.getCountData
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalWorkChat)