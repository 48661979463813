import { Button, Modal } from "antd";
import React, { useState } from "react"
import { connect } from "react-redux";
import { IMAGES } from "../../assets"
import school from "../../models/school";
import ModalCreatePost from "../modals/ModalCreatePost";
import ModalCreateVideo from "../modals/ModalCreateVideo";
import ModalCreateWork from "../modals/ModalCreateWork";

type Props = {
    school: school.SchoolData;
    onCreateSuccess: () => void
}

const SchoolCreate = (props: Props) => {
    const [showModalCreateWork, setShowModalCreateWork] = useState<boolean>(false)
    const [showModalCreatePost, setShowModalCreatePost] = useState<boolean>(false)
    const [showModalCreateVideo, setShowModalCreateVideo] = useState<boolean>(false)

    const { school, onCreateSuccess } = props

    const toggleModalCreateWork = () =>{
        setShowModalCreateWork(!showModalCreateWork)
    }

    const toggleModalCreatePost = () =>{
        setShowModalCreatePost(!showModalCreatePost)
    }

    const toggleModalCreateVideo = () =>{
        setShowModalCreateVideo(!showModalCreateVideo)
    }

    return (
        <>
            <div className="row row-button-action">
                <div className="col-md-4 col-sm-4 col-xs-6 item-center">
                    <div
                        className="pointer"
                        onClick={toggleModalCreateWork}
                    >
                        <img
                            src={IMAGES.CREATE_WORK}
                            className='image-action'
                            alt='work-action'
                        />
                        <p className="text-work-action">Tạo việc</p>
                    </div>
                </div>
                <div className="col-md-4 col-sm-4 col-xs-6 item-center">
                    <div
                        className="pointer"
                        onClick={toggleModalCreatePost}
                    >
                        <img
                            src={IMAGES.POST_PHOTO}
                            className='image-action'
                            alt='work-action'
                        />
                        <p className="text-work-action">Đăng ảnh và bài viết</p>
                    </div>
                </div>
                <div className="col-md-4 col-sm-4 col-xs-6 item-center">
                    <div
                        className="pointer"
                        onClick={toggleModalCreateVideo}
                    >
                        <img
                            src={IMAGES.POST_VIDEO}
                            className='image-action'
                            alt='work-action'
                        />
                        <p className="text-work-action">Đăng Video</p>
                    </div>
                </div>
            </div>
            <ModalCreateWork
                showModal={showModalCreateWork}
                onClose={toggleModalCreateWork}
                onCreateSuccess={onCreateSuccess}
                school={school}
            />
            <ModalCreatePost
                showModal={showModalCreatePost}
                onClose={toggleModalCreatePost}
                onCreateSuccess={onCreateSuccess}
                school={school}
            />
            <ModalCreateVideo
                showModal={showModalCreateVideo}
                onClose={toggleModalCreateVideo}
                onCreateSuccess={onCreateSuccess}
                school={school}
            />
        </>
    )
}

export default connect()(SchoolCreate);