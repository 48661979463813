import { combineReducers } from "redux";
import location from "../../models/locaion";
import persistReducerUtil from "../../utils/common";
import { locationActions, locationActionTypes } from "../actions/location";

export const LOCATION_INITIAL_STATE: location.State = {
    provinces: []
};

const provinces = (
    state: location.Province[] = LOCATION_INITIAL_STATE.provinces,
    action: locationActionTypes.getListProvinceSuccessActionType
) => {
    switch (action.type) {
        case locationActions.GET_LIST_PROVINCE_SUCCESS:
            return action.payload;
        default:
            return state;
    }
}

const schoolReducers = combineReducers<location.State>({
    provinces,
});

export default persistReducerUtil(
    'location',
    schoolReducers,
    ['provinces'],
);
