import React from 'react';

type Props = {
    className?: string;
    children: React.ReactNode
}

const Title =  (props: Props) =>(
    <div className={`flex-column item-center `}>
        <p className='bold title-primary'>{props.children}</p>
    </div>
)

export default Title