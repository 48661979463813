import axios from 'axios';
import { END_POINTS } from '..';
import report from '../../models/report';
import models from '../../models';
const qs = require('qs');

export const updateReportApi = (params: report.ReportUpdateRequest) =>{
    const url = END_POINTS.updateReport;
	return axios.post(url, qs.stringify(params)).then((res: { data: models.DefaultResponse }) => res.data);
}

export const getListReportApi = (params: report.ReportGetListRequest) =>{
    const url = END_POINTS.getListReport;
	return axios.get(url, {params}).then((res: { data: report.ReportResponse }) => res.data);
}
