import { Button } from "antd";
import React, { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getListWorkApi } from "../apis/work";
import Loading from "../components/common/Loading";
import ListNewfeed from "../components/newfeed/item";
import SchoolDetail from "../components/school/SchoolDetail";
import models from "../models";
import newfeed from "../models/newfeed";
import school from "../models/school";
import work from "../models/work";
import { schoolActionCreators } from "../redux/actions/school";
import { schoolSelectedSelector, schoolsSelector } from "../redux/selectors/school";
import { COLLAPSE_TYPE, DEFAULT_PAGE_LIMIT, LIST_WORK, MESSAGES, RESPONSE_CODE, SCHOOL_STATUS, USER_ROLE_TYPE } from "../utils/constants";
import { showNotification } from "../utils/notification";
import Icon from 'react-fontawesome';
import { userDataSelector } from "../redux/selectors/user";
import user from "../models/user";
import { getUserRole } from "../utils/common";
import { getListBookPersonalApi } from "../apis/book";
import { getListNewfeedAdminApi } from "../apis/newfeed";
import { NewfeedBlock } from "../components/newfeed";

const TAB = {
    DOING: 1,
    DONE: 2,
    ALL: 3,
    BOOK: 4,
    NEWFEED: 5
}

interface Props extends RouteComponentProps {
    getListSchool: typeof schoolActionCreators.getListSchool;
    selectSchool: typeof schoolActionCreators.selectSchool;
    userData: user.User;
}

const Personal = React.memo((props: Props) => {
    const { getListSchool } = props;
    const [isLoading, setIsLoading] = useState<boolean>(true)

    const [tab, setTab] = useState<number>(TAB.DOING)
    const [workType, setWorkType] = useState<string>('')
    const [bookType, setBookType] = useState<string>('')

    const [endList, setEndList] = useState<boolean>(false)
    const [works, setWorks] = useState<work.Work[]>([])
    const [books, setBooks] = useState<newfeed.Book[]>([])
    const [newfeeds, setNewfeeds] = useState<newfeed.NewfeedData[]>([])
    const [collapseType, setCollapseType] = useState<number>(COLLAPSE_TYPE.COLLAPSE)
    const pageIdRef = useRef<number>(1)

    const { isManager } = getUserRole();

    const handleSetTab = (selectTab: number) => () => {
        setTab(selectTab)
    }

    const getListWork = async (pageId = 1) => {
        try {
            const response = await getListWorkApi({
                page_id: pageId,
                is_done: tab === TAB.DONE ? 1 : 0,
                work_type: workType,
                is_all: tab === TAB.ALL ? 1 : 0
            });
            pageIdRef.current = pageId;
            setWorks(pageId === 1 ? response.data : [...works, ...response.data]);
            setEndList(response?.data?.length < DEFAULT_PAGE_LIMIT)
            setIsLoading(false)
        } catch (error) {
            showNotification(MESSAGES.ERROR, RESPONSE_CODE.ERROR);
        }
    }

    const handleLoadMore = () => {
        if (!endList) {
            if (tab === TAB.BOOK) {
                getListBook(pageIdRef.current + 1)
            }
            else if (tab === TAB.NEWFEED) {
                getListNewfeed(pageIdRef.current + 1)
            }
            else getListWork(pageIdRef.current + 1)
        }
    }

    const handleChange = (data: any) => {
        const dataChange = works.map(item => {
            if (item.work_id === data.work_id) {
                return {
                    ...item,
                    ...data
                }
            }
            return item
        })
        setWorks(dataChange)
    }

    const handleChangeCollapseType = (type: number) => () => {
        setCollapseType(type)
    }

    const getListBook = async (pageId = 1) => {
        try {
            const response = await getListBookPersonalApi({
                page_id: pageId,
                book_type: bookType
            });
            pageIdRef.current = pageId;
            setBooks(pageId === 1 ? response.data : [...books, ...response.data])
            setEndList(response?.data?.length < DEFAULT_PAGE_LIMIT)
            setIsLoading(false)
        } catch (error) {
            showNotification(MESSAGES.ERROR, RESPONSE_CODE.ERROR);
        }
    }

    const getListNewfeed = async (pageId = 1) => {
        try {
            const response = await getListNewfeedAdminApi({
                page_id: pageId,
            });
            pageIdRef.current = pageId;
            setNewfeeds(pageId === 1 ? response.data : [...newfeeds, ...response.data])
            setEndList(response?.data?.length < DEFAULT_PAGE_LIMIT)
            setIsLoading(false)
        } catch (error) {
            showNotification(MESSAGES.ERROR, RESPONSE_CODE.ERROR);
        }
    }

    const renderData = () => {
        if (tab === TAB.BOOK) {
            return (
                <>
                    <select
                        className="form-control mgt-2"
                        value={bookType}
                        onChange={({ target: { value } }) => setBookType(value)}
                    >
                        <option value='1'>Sắp đo</option>
                        <option value='2'>Đã đo</option>
                    </select>
                    <InfiniteScroll
                        loadMore={handleLoadMore}
                        hasMore={!endList && !isLoading}
                        loader={<Loading />}
                        threshold={20}
                    >
                        {books.map((item) =>
                            <div
                                className="box-newfeed"
                                key={item.book_id}
                            >
                                <ListNewfeed.Book
                                    data={item as newfeed.NewfeedData & newfeed.Book}
                                    collapseType={collapseType}
                                />
                            </div>
                        )}
                    </InfiniteScroll>
                </>
            )
        }
        if (tab === TAB.NEWFEED) {
            return (
                <InfiniteScroll
                    loadMore={handleLoadMore}
                    hasMore={!endList}
                    loader={<Loading />}
                    threshold={20}
                >
                    {newfeeds.map((item) =>
                        <div className="box-newfeed" key={item.newfeed_id}>
                            <NewfeedBlock data={item} onChange={() => null} />
                        </div>
                    )}
                </InfiniteScroll>
            )
        }
        return (
            <>
                <select
                    className="form-control mgt-2"
                    value={workType}
                    onChange={({ target: { value } }) => setWorkType(value)}
                >
                    <option value=''>Tất cả các công việc</option>
                    {LIST_WORK.map((item, key) =>
                        <option key={key} value={item.status}>{item.name}</option>
                    )}
                </select>
                <InfiniteScroll
                    loadMore={handleLoadMore}
                    hasMore={!endList && !isLoading}
                    loader={<Loading />}
                    threshold={20}
                >
                    {works.map((item) =>
                        <div
                            className="box-newfeed"
                            key={item.work_id}
                        >
                            <ListNewfeed.Work
                                data={item as newfeed.NewfeedData & work.Work}
                                onChange={handleChange}
                                isPersonal={true}
                                collapseType={collapseType}
                            />
                        </div>
                    )}
                </InfiniteScroll>
            </>
        )
    }

    useEffect(() => {
        getListSchool({
            status: SCHOOL_STATUS.TODO,
            is_personal: true,
            is_book: tab === TAB.BOOK ? 1 : 0
        })
    }, [tab])

    useEffect(() => {
        if (tab === TAB.BOOK) {
            getListBook();
        }
        else if (tab === TAB.NEWFEED) {
            getListNewfeed();
        }
        else getListWork()
    }, [workType, tab, bookType])

    return (
        <div>
            <div className="flex item-center flex-wrap">
                <Button
                    className={`btn-doing mgr ${tab === TAB.DOING ? 'bg-warning' : ''}`}
                    onClick={handleSetTab(TAB.DOING)}
                >
                    Việc đang làm
                </Button>
                <Button
                    className={`btn-doing mgr ${tab === TAB.BOOK ? 'bg-warning' : ''}`}
                    onClick={handleSetTab(TAB.BOOK)}
                >
                    Lịch đo
                </Button>
                <Button
                    className={`btn-doing mgl ${tab === TAB.DONE ? 'bg-warning' : ''}`}
                    onClick={handleSetTab(TAB.DONE)}
                >
                    Việc đã hoàn tất
                </Button>
                {isManager &&
                    <>
                        <Button
                            className={`btn-doing mgl ${tab === TAB.ALL ? 'bg-warning' : ''}`}
                            onClick={handleSetTab(TAB.ALL)}
                        >
                            Việc toàn công ty
                        </Button>
                        <Button
                            className={`btn-doing mgl ${tab === TAB.NEWFEED ? 'bg-warning' : ''}`}
                            onClick={handleSetTab(TAB.NEWFEED)}
                        >
                            Bảng tin
                        </Button>
                    </>
                }
            </div>
            <div className="work-select-collapse">
                <Icon
                    name='file-text-o'
                    className={`icon-select-collapse ${collapseType === COLLAPSE_TYPE.COLLAPSE ? 'warning' : ''}`}
                    onClick={handleChangeCollapseType(COLLAPSE_TYPE.COLLAPSE)}
                />
                <Icon
                    name='calendar'
                    className={`icon-select-collapse ${collapseType === COLLAPSE_TYPE.FULL ? 'warning' : ''}`}
                    onClick={handleChangeCollapseType(COLLAPSE_TYPE.FULL)}
                />
            </div>
            {renderData()}
        </div>
    )
})

const mapStateToProps = (state: models.GlobalState) => ({
    userData: userDataSelector(state),
});

const mapDispatchToProps = {
    getListSchool: schoolActionCreators.getListSchool,
    selectSchool: schoolActionCreators.selectSchool
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Personal));