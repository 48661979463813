import { combineReducers } from "redux";
import school from "../../models/school";
import persistReducerUtil from "../../utils/common";
import { SCHOOL_STATUS } from "../../utils/constants";
import { schoolActions, schoolActionTypes } from "../actions/school";

export const SCHOOL_INITIAL_STATE: school.State = {
    schools: [],
    selectedSchool: '',
    selectedTab: SCHOOL_STATUS.WAITING,
    branchs: []
};

const schools = (
    state: school.SchoolData[] = SCHOOL_INITIAL_STATE.schools,
    action: schoolActionTypes.getListSchoolSuccessActionType | schoolActionTypes.updateSchoolActionType
) => {
    switch (action.type) {
        case schoolActions.GET_LIST_SCHOOL_SUCCESS:
            return action.payload;
        case schoolActions.UPDATE_SCHOOL:
            const school = action.payload;
            return state.map(item =>{
                if(item.school_id === school.school_id){
                    return{
                        ...item,
                        ...school
                    }
                }
                return item;
            })
        default:
            return state;
    }
}

const selectedSchool = (
    state: school.SchoolData['school_id'] = SCHOOL_INITIAL_STATE.selectedSchool,
    action: schoolActionTypes.getListSchoolSuccessActionType | schoolActionTypes.selectSchoolActionType | schoolActionTypes.getListSchoolActionType
) => {
    switch (action.type) {
        case schoolActions.GET_LIST_SCHOOL_SUCCESS:
            if(action.payload.length > 0){
                return action.payload[0].school_id;
            }
            return state;
        case schoolActions.SELECT_SCHOOL:
            return action.payload;
        default:
            return state;
    }
}

const branchs = (
    state: school.Branch[] = SCHOOL_INITIAL_STATE.branchs,
    action: schoolActionTypes.getListBranchSuccessActionType
) => {
    switch (action.type) {
        case schoolActions.GET_LIST_BRANCH_SUCCESS:
            return action.payload;
        default:
            return state;
    }
}

const selectedTab = (
    state: string = SCHOOL_INITIAL_STATE.selectedTab,
    action: schoolActionTypes.getListSchoolActionType
) => {
    switch (action.type) {
        case schoolActions.GET_LIST_SCHOOL:
            const payload = action.payload;
            return payload.status;
        default:
            return state;
    }
}

const schoolReducers = combineReducers<school.State>({
    schools,
    selectedSchool,
    selectedTab,
    branchs
});

export default persistReducerUtil(
    'school',
    schoolReducers,
    ['schools'],
);
