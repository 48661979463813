import school from './school';
import location from './location';
import user from './user';

const GLOBAL_STATE = {
    school,
    location,
    user
};

export default GLOBAL_STATE;