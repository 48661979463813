import React, { useState } from "react";
import { connect } from "react-redux";
import models from "../../../models";
import { userDataSelector } from "../../../redux/selectors/user";
import newfeed from "../../../models/newfeed";
import TooltipUser from "../../common/TooltipUser";
import { convertDateTime, getYoutubeId } from "../../../utils/common";
import { URI_PATH } from "../../../utils/constants";
import FsLightbox from 'fslightbox-react';
import { EventBusName, onPushEventBus } from "../../../services/event-bus";

interface Props {
    data: newfeed.Video
}

const Video = (props: Props) => {
    const { data } = props;

    const handleClickSchool = (schoolId: string) => () =>{
        onPushEventBus(EventBusName.SELECT_SCHOOL_MODAL, schoolId)
    }

    return (
        <div>
            <div className="flex-row">
                <TooltipUser data={data} />
                <div className='flex-column mgl-2'>
                    <p className="black text-large bold">{data.user_name}</p>
                    <p className="text-time silver">{convertDateTime(data.cr_date_time)}</p>
                    <p
                        className="mgb-0 pointer"
                        onClick={handleClickSchool(data.school_id)}
                    >
                        <span className="gray">Tại </span>
                        <u><span className="bold">{data.name}</span></u>
                    </p>
                </div>
            </div>
            <p className="mgt-3">{data.video_title}</p>
            <div className='relative'>
                <div className='blur-video' />
                <iframe
                    className='mgt'
                    style={{ width: '100%', height: '80vh', borderRadius: '2vh' }}
                    src={"https://www.youtube.com/embed/" + getYoutubeId(data.video_url)}
                    allow="encrypted-media"
                    allowFullScreen={true}
                    title='video'
                />
            </div>
        </div>
    )
}

const mapStateToProps = (state: models.GlobalState) => ({
    userData: userDataSelector(state)
})

export default connect(mapStateToProps)(Video)