import axios from 'axios';
import { END_POINTS } from '..';
import models from '../../models';
import map from '../../models/map';
import school from '../../models/school';
import book from '../../models/book';
import newfeed from '../../models/newfeed';
const qs = require('qs');

export const updateBookApi = (params: book.UpdateBookParams) =>{
    const url = END_POINTS.updateBook;
	return axios.post(url, qs.stringify(params)).then((res: { data: models.DefaultResponse }) => res.data);
}

export const getListBookApi = (params: {date: string}) =>{
    const url = END_POINTS.getListBook;
	return axios.get(url, {params}).then((res: { data: book.GetListBookResponse }) => res.data);
}

export const deleteBookApi = (params: {book_id: string}) =>{
    const url = END_POINTS.deleteBook;
	return axios.post(url, qs.stringify(params)).then((res: { data: models.DefaultResponse }) => res.data);
}

export const addBookExecAndSupportApi = (params: book.AddBookExecAndSupportRequest) =>{
    const url = END_POINTS.addBookExecAndSupport;
	return axios.post(url, qs.stringify(params)).then((res: { data: models.DefaultResponse }) => res.data);
}

export const getListBookPersonalApi = (params: {page_id: number, book_type: string}) =>{
    const url = END_POINTS.getListBookPersonal;
	return axios.get(url, {params}).then((res: { data: book.BookPersonalResponse }) => res.data);
}

export const unlockBookApi = (params: book.UnlockBookRequest) =>{
    const url = END_POINTS.unlockBook;
	return axios.post(url, qs.stringify(params)).then((res: { data: models.DefaultResponse }) => res.data);
}
