import { GENDER_NAME, RELATIONSHIP_NAME } from "../../utils/constants";

const UserExtendForm = (props: any) => {
    const {
        salaryLink, setSalaryLink,
        bankInfo, setBankInfo,
        gender, setGender,
        mst, setMst,
        bhxh, setBhxh,
        cccd, setCccd,
        addressCurrent, setAddressCurrent,
        contractLink, setContractLink,
        contractDay, setContractDay,
        contractMonth, setContractMonth,
        contractYear, setContractYear,
        contractExpireDay, setContractExpireDay,
        contractExpireMonth, setContractExpireMonth,
        contractExpireYear, setContractExpireYear,
        relationship, setRelationship,
        childrenNum, setChildrenNum,
        jobLink, setJobLink,
        severanceDay, setSeveranceDay,
        severanceMonth, setSeveranceMonth,
        severanceYear, setSeveranceYear,
        resignLink, setResignLink,
    } = props

    return (
        <>
            <div className="row align-center mgt-2">
                <div className="col-md-3 col-sm-12 col-xs-12">
                    <p className="text-edit-school">Bảng lương:</p>
                </div>
                <div className="col-md-9 col-sm-12 col-xs-12">
                    <input
                        className="form-control"
                        value={salaryLink}
                        onChange={({ target: { value } }) => setSalaryLink(value)}
                        placeholder="Link"
                    />
                </div>
            </div>
            <div className="row align-center mgt-2">
                <div className="col-md-3 col-sm-12 col-xs-12">
                    <p className="text-edit-school">STK Ngân hàng:</p>
                </div>
                <div className="col-md-9 col-sm-12 col-xs-12">
                    <input
                        className="form-control"
                        value={bankInfo}
                        onChange={({ target: { value } }) => setBankInfo(value)}
                    />
                </div>
            </div>
            <div className="row align-center mgt-2">
                <div className="col-md-3 col-sm-12 col-xs-12">
                    <p className="text-edit-school">Giới tính:</p>
                </div>
                <div className="col-md-9 col-sm-12 col-xs-12">
                    <select
                        className="form-control select-left"
                        value={gender}
                        onChange={({ target: { value } }) => setGender(value)}
                    >
                        <option value=''>Chọn giới tính</option>
                        {Object.keys(GENDER_NAME).map((item, key) =>
                            <option value={item} key={key}>{GENDER_NAME[item]}</option>
                        )}
                    </select>
                </div>
            </div>
            <div className="row align-center mgt-2">
                <div className="col-md-3 col-sm-12 col-xs-12">
                    <p className="text-edit-school">MST Cá nhân:</p>
                </div>
                <div className="col-md-9 col-sm-12 col-xs-12">
                    <input
                        className="form-control"
                        value={mst}
                        onChange={({ target: { value } }) => setMst(value)}
                        type="number"
                    />
                </div>
            </div>
            <div className="row align-center mgt-2">
                <div className="col-md-3 col-sm-12 col-xs-12">
                    <p className="text-edit-school">Số sổ BHXH:</p>
                </div>
                <div className="col-md-9 col-sm-12 col-xs-12">
                    <input
                        className="form-control"
                        value={bhxh}
                        onChange={({ target: { value } }) => setBhxh(value)}
                        type="number"
                    />
                </div>
            </div>
            <div className="row align-center mgt-2">
                <div className="col-md-3 col-sm-12 col-xs-12">
                    <p className="text-edit-school">Số CCCD:</p>
                </div>
                <div className="col-md-9 col-sm-12 col-xs-12">
                    <input
                        className="form-control"
                        value={cccd}
                        onChange={({ target: { value } }) => setCccd(value)}
                    />
                </div>
            </div>
            <div className="row align-center mgt-2">
                <div className="col-md-3 col-sm-12 col-xs-12">
                    <p className="text-edit-school">Chỗ ở hiện nay:</p>
                </div>
                <div className="col-md-9 col-sm-12 col-xs-12">
                    <input
                        className="form-control"
                        value={addressCurrent}
                        onChange={({ target: { value } }) => setAddressCurrent(value)}
                    />
                </div>
            </div>
            <div className="row align-center mgt-2">
                <div className="col-md-3 col-sm-12 col-xs-12">
                    <p className="text-edit-school">Hợp đồng lao động:</p>
                </div>
                <div className="col-md-9 col-sm-12 col-xs-12">
                    <input
                        className="form-control"
                        value={contractLink}
                        onChange={({ target: { value } }) => setContractLink(value)}
                        placeholder="Link"
                    />
                </div>
            </div>
            <div className="row align-center mgt-2">
                <div className="col-md-3 col-sm-12 col-xs-12">
                    <p className="text-edit-school">Ngày ký HĐ:</p>
                </div>
                <div className="col-md-9 col-sm-12 col-xs-12">
                    <div className='row' style={{ flexDirection: 'row' }}>
                        <div className='col-xs-4'>
                            <select
                                className='form-control input-authen'
                                style={{ width: '99%' }}
                                onChange={({ target: { value } }) => setContractDay(value)}
                                value={contractDay}
                            >
                                <option value=''>Chọn ngày</option>
                                {new Array(31).fill(undefined).map((item, key) => (
                                    <option value={key + 1} key={key + 1}>Ngày {key + 1}</option>
                                ))}
                            </select>
                        </div>
                        <div className='col-xs-4'>
                            <select
                                className='form-control input-authen'
                                style={{ width: '99%' }}
                                onChange={({ target: { value } }) => setContractMonth(value)}
                                value={contractMonth}
                            >
                                <option value=''>Chọn tháng</option>
                                {new Array(12).fill(undefined).map((item, key) => (
                                    <option value={key + 1} key={key + 1}>Tháng {key + 1}</option>
                                ))}
                            </select>
                        </div>
                        <div className='col-xs-4'>
                            <select
                                className='form-control input-authen'
                                style={{ width: '99%' }}
                                onChange={({ target: { value } }) => setContractYear(value)}
                                value={contractYear}
                            >
                                <option value=''>Chọn năm</option>
                                {new Array(50).fill(undefined).map((item, key) => (
                                    <option value={`${key + 2010}`} key={key + 2010}>Năm {key + 2010}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row align-center mgt-2">
                <div className="col-md-3 col-sm-12 col-xs-12">
                    <p className="text-edit-school">Ngày hết hạn:</p>
                </div>
                <div className="col-md-9 col-sm-12 col-xs-12">
                    <div className='row' style={{ flexDirection: 'row' }}>
                        <div className='col-xs-4'>
                            <select
                                className='form-control input-authen'
                                style={{ width: '99%' }}
                                onChange={({ target: { value } }) => setContractExpireDay(value)}
                                value={contractExpireDay}
                            >
                                <option value=''>Chọn ngày</option>
                                {new Array(31).fill(undefined).map((item, key) => (
                                    <option value={key + 1} key={key + 1}>Ngày {key + 1}</option>
                                ))}
                            </select>
                        </div>
                        <div className='col-xs-4'>
                            <select
                                className='form-control input-authen'
                                style={{ width: '99%' }}
                                onChange={({ target: { value } }) => setContractExpireMonth(value)}
                                value={contractExpireMonth}
                            >
                                <option value=''>Chọn tháng</option>
                                {new Array(12).fill(undefined).map((item, key) => (
                                    <option value={key + 1} key={key + 1}>Tháng {key + 1}</option>
                                ))}
                            </select>
                        </div>
                        <div className='col-xs-4'>
                            <select
                                className='form-control input-authen'
                                style={{ width: '99%' }}
                                onChange={({ target: { value } }) => setContractExpireYear(value)}
                                value={contractExpireYear}
                            >
                                <option value=''>Chọn năm</option>
                                {new Array(50).fill(undefined).map((item, key) => (
                                    <option value={key + 2010} key={key + 2010}>Năm {key + 2010}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row align-center mgt-2">
                <div className="col-md-3 col-sm-12 col-xs-12">
                    <p className="text-edit-school">Tình trạng hôn nhân:</p>
                </div>
                <div className="col-md-9 col-sm-12 col-xs-12">
                    <select
                        className="form-control select-left"
                        value={relationship}
                        onChange={({ target: { value } }) => setRelationship(value)}
                    >
                        <option value=''>Chọn tình trạng</option>
                        {Object.keys(RELATIONSHIP_NAME).map((item, key) =>
                            <option value={item} key={key}>{RELATIONSHIP_NAME[item]}</option>
                        )}
                    </select>
                </div>
            </div>
            <div className="row align-center mgt-2">
                <div className="col-md-3 col-sm-12 col-xs-12">
                    <p className="text-edit-school">Số con:</p>
                </div>
                <div className="col-md-9 col-sm-12 col-xs-12">
                    <input
                        className="form-control"
                        value={childrenNum}
                        onChange={({ target: { value } }) => setChildrenNum(value)}
                        type="number"
                    />
                </div>
            </div>
            <div className="row align-center mgt-2">
                <div className="col-md-3 col-sm-12 col-xs-12">
                    <p className="text-edit-school">Hồ sơ xin việc:</p>
                </div>
                <div className="col-md-9 col-sm-12 col-xs-12">
                    <input
                        className="form-control"
                        value={jobLink}
                        onChange={({ target: { value } }) => setJobLink(value)}
                        placeholder="Link"
                    />
                </div>
            </div>
            <div className="row align-center mgt-2">
                <div className="col-md-3 col-sm-12 col-xs-12">
                    <p className="text-edit-school">Ngày thôi việc:</p>
                </div>
                <div className="col-md-9 col-sm-12 col-xs-12">
                    <div className='row' style={{ flexDirection: 'row' }}>
                        <div className='col-xs-4'>
                            <select
                                className='form-control input-authen'
                                style={{ width: '99%' }}
                                onChange={({ target: { value } }) => setSeveranceDay(value)}
                                value={severanceDay}
                            >
                                <option value=''>Chọn ngày</option>
                                {new Array(31).fill(undefined).map((item, key) => (
                                    <option value={key + 1} key={key + 1}>Ngày {key + 1}</option>
                                ))}
                            </select>
                        </div>
                        <div className='col-xs-4'>
                            <select
                                className='form-control input-authen'
                                style={{ width: '99%' }}
                                onChange={({ target: { value } }) => setSeveranceMonth(value)}
                                value={severanceMonth}
                            >
                                <option value=''>Chọn tháng</option>
                                {new Array(12).fill(undefined).map((item, key) => (
                                    <option value={key + 1} key={key + 1}>Tháng {key + 1}</option>
                                ))}
                            </select>
                        </div>
                        <div className='col-xs-4'>
                            <select
                                className='form-control input-authen'
                                style={{ width: '99%' }}
                                onChange={({ target: { value } }) => setSeveranceYear(value)}
                                value={severanceYear}
                            >
                                <option value=''>Chọn năm</option>
                                {new Array(50).fill(undefined).map((item, key) => (
                                    <option value={key + 2010} key={key + 2010}>Năm {key + 2010}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row align-center mgt-2">
                <div className="col-md-3 col-sm-12 col-xs-12">
                    <p className="text-edit-school">Quyết định thôi việc:</p>
                </div>
                <div className="col-md-9 col-sm-12 col-xs-12">
                    <input
                        className="form-control"
                        value={resignLink}
                        onChange={({ target: { value } }) => setResignLink(value)}
                        placeholder="Link"
                    />
                </div>
            </div>
        </>
    )
}

export default UserExtendForm