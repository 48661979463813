import { memo, useEffect } from 'react';
import { connect } from 'react-redux';
//@ts-ignore
import socketIO from 'socket.io-client';
import { URI_SOCKET } from '../../utils/constants';
import work from '../../models/work';
import { EventBusName, onPushEventBus } from '../../services/event-bus';

export const socket = socketIO(URI_SOCKET, {jsonp: false});

const Socket = () => {
    useEffect(() => {
        const reconnectSocket = setInterval(() => {
            if (socket.disconnected) {
                socket.connect();
            }
        }, 3000);

        socket.on('revice_chat_work_happyidem', (data: work.Chat) => {
            console.log(data);
            
            onPushEventBus(EventBusName.RECEIVE_SOCKET_WORK_CHAT, data)
        });

        return (() => {
            clearInterval(reconnectSocket);
            socket.close();
        })
    }, [])

    return null;
};

const mapStateToProps = () => ({
    
});

const mapDispatchToProps = {
    
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(Socket));
