import { all, put, takeEvery } from "redux-saga/effects";
import { getListBranchApi, getListSchoolApi } from "../../apis/school";
import school from "../../models/school";
import { MESSAGES } from "../../utils/constants";
import { schoolActions, schoolActionTypes, schoolActionCreators } from "../actions/school";

export function* schoolRuntime() {
    yield all([
        takeEvery(schoolActions.GET_LIST_SCHOOL, getListSchoolRuntime),
        takeEvery(schoolActions.GET_LIST_BRANCH, getListBranch),
    ]);
}

function* getListSchoolRuntime(action: schoolActionTypes.getListSchoolActionType){
    try {
        const params = action.payload;
        const response: school.SchoolResponse = yield getListSchoolApi(params);
        if(response.code === 1){
            yield put(schoolActionCreators.getListSchoolSuccess(response.data));
            if(params.is_personal) yield put(schoolActionCreators.selectSchool(''));
        }
        else put(schoolActionCreators.getListSchoolFailure(response.message));
    } catch (err) {
        yield put(schoolActionCreators.getListSchoolFailure(MESSAGES.ERROR));
    }
}

function* getListBranch(){
    try {
        const response: school.BranchResponse = yield getListBranchApi();
        if(response.code === 1){
            yield put(schoolActionCreators.getListBranchSuccess(response.data));
        }
        else put(schoolActionCreators.getListBranchFailure(response.message));
    } catch (err) {
        yield put(schoolActionCreators.getListBranchFailure(MESSAGES.ERROR));
    }
}