import { Button, Modal } from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { getListMapApi } from "../../apis/school";
import { IMAGES } from "../../assets";
import map from "../../models/map";
import school from "../../models/school";
import { convertDateTime, openNewTab } from "../../utils/common";
import { MESSAGES, PROP, RESPONSE_CODE, SCHOOL_STATUS_NAME } from "../../utils/constants";
import TagsInput, { TagInputRef } from "../common/TagInput";
import { changeCuratorsApi } from "../../apis/school";
import { showNotification } from "../../utils/notification";
import { schoolActionCreators } from "../../redux/actions/school";
import { connect } from "react-redux";

type Props = {
    showModal: boolean;
    onClose: () => void;
    school: school.SchoolData;
    updateSchool: typeof schoolActionCreators.updateSchool
}

const ModalAddUserSchool = (props: Props) =>{
    const { showModal, onClose, school, updateSchool } = props;
    const curatorsRef = useRef<TagInputRef>(null)
    const [isPress, setIsPress] = useState<boolean>(false)

    const handleConfirm = async() =>{
        try {
            if(!isPress){
                const curatorInput = curatorsRef.current?.getValue();
                const listCurator = curatorInput?.map(i => i.user_id) || [];
                setIsPress(true);
                const response = await changeCuratorsApi({list_curator: listCurator, school_id: school.school_id});
                showNotification(response.message, response.code)
                if(response.code === RESPONSE_CODE.SUCCESS){
                    updateSchool({
                        ...school,
                        list_curator: curatorInput?.map(item => ({
                            school_curator_id: "",
                            school_id: school.school_id,
                            user_id: item.user_id,
                            cr_date_time: convertDateTime(moment().toDate()),
                            user_name: item.user_name,
                            avatar: item.avatar
                        }))
                    })
                    onClose()
                }
                setIsPress(false)
            }

        } catch (error) {
            showNotification(MESSAGES.ERROR, RESPONSE_CODE.ERROR)
            setIsPress(false)
        }
    }

    return(
        <Modal
            {...PROP.MODAL}
            open={showModal}
            onCancel={onClose}
        >
            <p className="black">Chọn người phụ trách</p>
            <TagsInput ref={curatorsRef} data={school.list_curator} />
            <div className="item-center mgt-3">
                <Button onClick={handleConfirm} loading={isPress}>Xác nhận</Button>
            </div>
        </Modal>
    )
}

const mapDispatchToProps = {
    updateSchool: schoolActionCreators.updateSchool,
};

export default connect(undefined, mapDispatchToProps)(ModalAddUserSchool)