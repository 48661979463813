import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { getListUserApi } from '../../apis/user';
import user from '../../models/user';
import { PROP } from '../../utils/constants';
import TooltipUser from './TooltipUser';

interface Props {
    data?: user.UserSearch[];
    disabled?: boolean;
    onSelect?: (data: user.UserSearch[]) => void;
    placeholder?: string
}

export interface TagInputRef {
    getValue: () => user.UserSearch[];
}

const TagsInput = (props: Props, ref: React.Ref<TagInputRef>) => {
    const { data, disabled, onSelect, placeholder } = props;
    const [tags, setTags] = useState<user.UserSearch[]>([])
    const [itemSearch, setItemSearch] = useState<user.UserSearch[]>([]);
    const inputRef = useRef<any>(null)

    useEffect(() => {
        if (data) setTags(data)
    }, [data])

    useImperativeHandle(ref, () => ({
        getValue: () => {
            return tags;
        }
    }));

    const handleClickInput = () => {
        inputRef.current.getInstance().focus();
    }

    const removeTag = (index: number) => {
        const currentTags = tags.filter((_, i) => i !== index)
        setTags(currentTags)
        onSelectTags(currentTags)
    }

    const handleSearch = async (searchText: string) => {
        const response = await getListUserApi({ searchText })
        setItemSearch(response.data)
    }

    const handleSelected = (selected: user.UserSearch[]) => {
        const listTagAppend = [
            ...tags.filter(i => i.user_id !== selected[0].user_id),
            ...selected
        ]
        setTags(listTagAppend)
        inputRef.current.getInstance().clear();
        onSelectTags(listTagAppend)
    }

    const onSelectTags = (currentTags: user.UserSearch[]) =>{
        onSelect && onSelect(currentTags)
    }

    return (
        <div
            className="tags-input-container"
            onClick={handleClickInput}
        >
            {tags.map((tag, index) => (
                <div className="tag-item" key={index}>
                    <TooltipUser data={tag} component={
                        <>
                            <span className="tag-text">{tag.user_name}</span>
                            {!disabled &&
                                <span className="tag-close" onClick={() => removeTag(index)}>&times;</span>
                            }
                        </>
                    } />
                </div>
            ))}
            <AsyncTypeahead
                {...PROP.TYPE_AHEAD}
                onSearch={handleSearch}
                options={itemSearch}
                onChange={handleSelected}
                ref={inputRef}
                disabled={disabled}
                minLength={1}
                placeholder={placeholder}
            />
        </div>
    )
}

export default forwardRef(TagsInput)