import { Button, Modal } from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { getListMapApi, updateSchoolApi } from "../../apis/school";
import { IMAGES } from "../../assets";
import map from "../../models/map";
import school from "../../models/school";
import { getAvatar, getImage64, isBase64Image, openNewTab } from "../../utils/common";
import { CLASSIFY_NAME, MESSAGES, PROP, RESPONSE_CODE, SCHOOL_STATUS_NAME, URI_PATH } from "../../utils/constants";
import { showNotification } from "../../utils/notification";
import Icon from 'react-fontawesome';
import { connect } from "react-redux";
import { schoolActionCreators } from "../../redux/actions/school";
import location from "../../models/locaion";
import { getListDistrictApi, getListWardApi } from "../../apis/location";
import { provinceSelector } from "../../redux/selectors/location";
import models from "../../models";

type Props = {
    showModal: boolean;
    onClose: () => void;
    school: school.SchoolData;
    maps: map.MapData[];
    updateSchool: typeof schoolActionCreators.updateSchool;
    onUpdateSuccess: (maps: map.MapData[]) => void;
    provinces: location.Province[]
}

const ModalEditSchool = (props: Props) => {
    const { showModal, onClose, school, maps, updateSchool, onUpdateSuccess, provinces } = props;

    const [avatarEdit, setAvatarEdit] = useState<string>('')
    const [classifyEdit, setClassify] = useState<school.SchoolData['classify']>('1')
    const [mstEdit, setMstEdit] = useState<string>('')
    const [nameEdit, setNameEdit] = useState<string>('')
    const [addressEdit, setAddressEdit] = useState<string>('')
    const [contactInfoEdit, setContactInfoEdit] = useState<string>('')
    const [baseTotalEdit, setBaseTotalEdit] = useState<string>('')
    const [classTotalEdit, setClassTotalEdit] = useState<string>('')
    const [studentTotalEdit, setStudentTotalEdit] = useState<string>('')
    const [dataLinkEdit, setDataLinkEdit] = useState<string>('')
    const [zaloLinkEdit, setZaloLinkEdit] = useState<string>('')
    const [provinceEdit, setProvinceEdit] = useState<string>('')
    const [districtEdit, setDistrictEdit] = useState<string>('')
    const [wardEdit, setWardEdit] = useState<string>('')

    const [mapsEdit, setMapsEdit] = useState<map.MapData[]>([]);

    const [isPress, setIsPress] = useState<boolean>(false)

    const [districts, setDistricts] = useState<location.District[]>([])
    const [wards, setWards] = useState<location.Ward[]>([])

    const avatarRef = useRef<HTMLInputElement>(null)

    const handleChangeMapNameEdit = (value: string, key: number) => {
        const changeArr = mapsEdit.map((i, k) => {
            if (k === key) return {
                ...i,
                map_name: value
            }
            return i;
        });
        setMapsEdit(changeArr)
    }

    const handleChangeMapLinkEdit = (value: string, key: number) => {
        const changeArr = mapsEdit.map((i, k) => {
            if (k === key) return {
                ...i,
                map_link: value
            }
            return i;
        });
        setMapsEdit(changeArr)
    }

    const handleAddMap = () => {
        setMapsEdit([
            ...mapsEdit,
            {} as map.MapData
        ])
    }

    const getListDistrict = async (province_id: string) => {
        const response = await getListDistrictApi({ province_id });
        setDistricts(response.data);
    }

    const getListWard = async (district_id: string) => {
        const response = await getListWardApi({ district_id });
        setWards(response.data);
    }

    useEffect(() => {
        if (showModal) {
            getListDistrict(provinceEdit)
            getListWard(districtEdit)
        }
    }, [showModal, provinceEdit, districtEdit])

    useEffect(() => {
        setAvatarEdit(school.avatar)
        setClassify(school.classify)
        setMstEdit(school.mst)
        setNameEdit(school.name)
        setAddressEdit(school.address)
        setContactInfoEdit(school.contact_info)
        setBaseTotalEdit(school.base_total)
        setClassTotalEdit(school.class_total)
        setStudentTotalEdit(school.student_total)
        setDataLinkEdit(school.data_link)
        setZaloLinkEdit(school.zalo_link)
        setProvinceEdit(school.province_id);
        setDistrictEdit(school.district_id);
        setWardEdit(school.ward_id);
    }, [school])

    const handleUpdate = async () => {
        try {
            setIsPress(true);
            const schoolUpdate = {
                school_id: school.school_id,
                avatar: avatarEdit,
                name: nameEdit,
                classify: classifyEdit,
                mst: mstEdit,
                address: addressEdit,
                contact_info: contactInfoEdit,
                base_total: baseTotalEdit,
                class_total: classTotalEdit,
                student_total: studentTotalEdit,
                data_link: dataLinkEdit,
                zalo_link: zaloLinkEdit,
                status: school.status,
                ward_id: wardEdit,
                district_id: districtEdit,
                province_id: provinceEdit
            }
            const response = await updateSchoolApi({ school: schoolUpdate, maps: mapsEdit });
            setIsPress(false);
            showNotification(response.message, response.code);
            if (response.code === RESPONSE_CODE.SUCCESS) {
                updateSchool({
                    ...schoolUpdate,
                    ward_name: wards.find(i => i.ward_id === wardEdit)?.ward_name,
                    district_name: districts.find(i => i.district_id === districtEdit)?.district_name,
                    province_name: provinces.find(i => i.province_id === provinceEdit)?.province_name,
                });
                onUpdateSuccess(mapsEdit)
            }
        } catch (error) {
            setIsPress(false);
            showNotification(MESSAGES.ERROR, RESPONSE_CODE.ERROR);
        }
    }

    const deleteMap = (key: number) => () => {
        setMapsEdit(mapsEdit.filter((_, k) => k !== key))
    }

    const proccessSelectWard = async (districtSelect: string) => {
        const responseWard = await getListWardApi({ district_id: districtSelect });
        const wardList = responseWard.data;
        const wardSelect = wardList[0].ward_id;
        setWards(wardList)
        setWardEdit(wardSelect)
    }

    const handleChangeProvince = async ({ target: { value } }: React.ChangeEvent<HTMLSelectElement>) => {
        setProvinceEdit(value)
        const responseProvince = await getListDistrictApi({ province_id: value });
        const districtList = responseProvince.data;
        const districtSelect = districtList[0].district_id;
        setDistricts(districtList);
        setDistrictEdit(districtSelect);
        proccessSelectWard(districtSelect)
    }

    const handleChangeDistrict = ({ target: { value } }: React.ChangeEvent<HTMLSelectElement>) => {
        setDistrictEdit(value);
        proccessSelectWard(value);
    }

    const handleChangeAvatar = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event?.target?.files?.[0] as File;
        getImage64(file, image64 => {
            setAvatarEdit(image64)
        });
        if (avatarRef.current) {
            avatarRef.current.value = ''
        }
    }

    const handleClickAvatar = () => {
        avatarRef.current?.click()
    }

    useEffect(() => {
        if (maps) setMapsEdit(maps)
    }, [maps])

    return (
        <Modal
            {...PROP.MODAL}
            width='100vh'
            open={showModal}
            onCancel={onClose}
            centered={false}
            destroyOnClose
        >
            <div className="flex item-center mgb-3">
                <p className="text-edit-school">Logo trường</p>
                <img
                    src={getAvatar(avatarEdit)}
                    className="avatar-school-edit"
                    alt="avatar-school-edit"
                    onClick={handleClickAvatar}
                />
            </div>
            <div className="row">
                <div className="col-md-6 col-sm-12 col-xs-12" style={{ padding: '1vh' }}>
                    <p className="text-center bold mgt">Thông tin</p>
                    <div className="row align-center">
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <p className="text-edit-school">Phân loại:</p>
                        </div>
                        <div className="col-md-8 col-sm-12 col-xs-12">
                            <select
                                className="form-control-edit-school"
                                value={classifyEdit}
                                onChange={({ target: { value } }) => setClassify(value as school.SchoolData['classify'])}
                            >
                                {Object.keys(CLASSIFY_NAME).map((key) => 
                                    <option value={key} key={key}>{CLASSIFY_NAME[key]}</option>
                                )}
                            </select>
                        </div>
                    </div>
                    <div className="row align-center mgt">
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <p className="text-edit-school">MST:</p>
                        </div>
                        <div className="col-md-8 col-sm-12 col-xs-12">
                            <input
                                className="form-control-edit-school"
                                value={mstEdit}
                                onChange={({ target: { value } }) => setMstEdit(value)}
                                type='number'
                            />
                        </div>
                    </div>
                    <div className="row align-center mgt">
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <p className="text-edit-school">Tên trường:</p>
                        </div>
                        <div className="col-md-8 col-sm-12 col-xs-12">
                            <input
                                className="form-control-edit-school"
                                value={nameEdit}
                                onChange={({ target: { value } }) => setNameEdit(value)}
                            />
                        </div>
                    </div>
                    <div className="row align-center mgt">
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <p className="text-edit-school">Địa chỉ chi tiết:</p>
                        </div>
                        <div className="col-md-8 col-sm-12 col-xs-12">
                            <input
                                className="form-control-edit-school"
                                value={addressEdit}
                                onChange={({ target: { value } }) => setAddressEdit(value)}
                            />
                        </div>
                    </div>
                    <div className="row align-center mgt">
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <p className="text-edit-school">Thông tin liên hệ:</p>
                        </div>
                        <div className="col-md-8 col-sm-12 col-xs-12">
                            <input
                                className="form-control-edit-school"
                                value={contactInfoEdit}
                                onChange={({ target: { value } }) => setContactInfoEdit(value)}
                            />
                        </div>
                    </div>
                    <div className="row align-center mgt">
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <p className="text-edit-school">Số lượng cơ sở:</p>
                        </div>
                        <div className="col-md-8 col-sm-12 col-xs-12">
                            <input
                                className="form-control-edit-school"
                                value={baseTotalEdit}
                                onChange={({ target: { value } }) => setBaseTotalEdit(value)}
                                type='number'
                            />
                        </div>
                    </div>
                    <div className="row align-center mgt">
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <p className="text-edit-school">Số lớp:</p>
                        </div>
                        <div className="col-md-8 col-sm-12 col-xs-12">
                            <input
                                className="form-control-edit-school"
                                value={classTotalEdit}
                                onChange={({ target: { value } }) => setClassTotalEdit(value)}
                                type='number'
                            />
                        </div>
                    </div>
                    <div className="row align-center mgt">
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <p className="text-edit-school">SL Học sinh:</p>
                        </div>
                        <div className="col-md-8 col-sm-12 col-xs-12">
                            <input
                                className="form-control-edit-school"
                                value={studentTotalEdit}
                                onChange={({ target: { value } }) => setStudentTotalEdit(value)}
                                type='number'
                            />
                        </div>
                    </div>
                    <div className="row align-center mgt">
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <p className="text-edit-school">Tỉnh/TP:</p>
                        </div>
                        <div className="col-md-8 col-sm-12 col-xs-12">
                            <select
                                className="form-control-edit-school"
                                value={provinceEdit}
                                onChange={handleChangeProvince}
                            >
                                {provinces.map((item) =>
                                    <option value={item.province_id} key={item.province_id}>{item.province_name}</option>
                                )}
                            </select>
                        </div>
                    </div>
                    <div className="row align-center mgt">
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <p className="text-edit-school">Quận/Huyện:</p>
                        </div>
                        <div className="col-md-8 col-sm-12 col-xs-12">
                            <select
                                className="form-control-edit-school"
                                value={districtEdit}
                                onChange={handleChangeDistrict}
                            >
                                {districts.map((item) =>
                                    <option value={item.district_id} key={item.district_id}>{item.district_name}</option>
                                )}
                            </select>
                        </div>
                    </div>
                    <div className="row align-center mgt">
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <p className="text-edit-school">Xã/Phường:</p>
                        </div>
                        <div className="col-md-8 col-sm-12 col-xs-12">
                            <select
                                className="form-control-edit-school"
                                value={wardEdit}
                                onChange={({ target: { value } }) => setWardEdit(value)}
                            >
                                {wards.map((item) =>
                                    <option value={item.ward_id} key={item.ward_id}>{item.ward_name}</option>
                                )}
                            </select>
                        </div>
                    </div>
                    <div className="row align-center mgt">
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <p className="text-edit-school">Đường link dữ liệu:</p>
                        </div>
                        <div className="col-md-8 col-sm-12 col-xs-12">
                            <input
                                className="form-control-edit-school"
                                value={dataLinkEdit}
                                onChange={({ target: { value } }) => setDataLinkEdit(value)}
                            />
                        </div>
                    </div>
                    <div className="row align-center mgt">
                        <div className="col-md-4 col-sm-12 col-xs-12">
                            <p className="text-edit-school">Đường link Zalo:</p>
                        </div>
                        <div className="col-md-8 col-sm-12 col-xs-12">
                            <input
                                className="form-control-edit-school"
                                value={zaloLinkEdit}
                                onChange={({ target: { value } }) => setZaloLinkEdit(value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-sm-12 col-xs-12" style={{ padding: '1vh' }}>
                    <p className="text-center bold mgt">Bản đồ</p>
                    {mapsEdit.map((item, key) => (
                        <div className={`row relative ${key === 0 ? '' : 'mgt'}`} key={key}>
                            <div className="col-md-6 col-sm-12 col-xs-12" style={{ padding: '0 0.5vh' }}>
                                <input
                                    className="form-control-edit-school"
                                    value={item.map_name}
                                    onChange={({ target: { value } }) => handleChangeMapNameEdit(value, key)}
                                    placeholder={`Ghi chú bản đồ`}
                                />
                            </div>
                            <div className="col-md-6 col-sm-12 col-xs-12" style={{ padding: '0 0.5vh' }}>
                                <input
                                    className="form-control-edit-school"
                                    value={item.map_link}
                                    placeholder={`Link bản đồ`}
                                    onChange={({ target: { value } }) => handleChangeMapLinkEdit(value, key)}
                                />
                            </div>
                            <img
                                src={IMAGES.DELETE}
                                className='icon-delete-map'
                                alt='icon-delete-map'
                                onClick={deleteMap(key)}
                            />
                        </div>
                    ))}
                    <div
                        className="item-center flex mgt-2 pointer"
                        onClick={handleAddMap}
                    >
                        <Icon name='plus-circle mgr' className="icon-add-map" />
                        <p className="text-edit-school">Thêm bản đồ cho trường</p>
                    </div>
                </div>
            </div>
            <div className="mgt-3 item-center">
                <Button
                    onClick={handleUpdate}
                    loading={isPress}
                >
                    Cập nhật
                </Button>
            </div>
            <input
                type='file'
                accept="image/png, image/jpeg"
                hidden={true}
                ref={avatarRef}
                onChange={handleChangeAvatar}
            />
        </Modal>
    )
}

const mapStateToProps = (state: models.GlobalState) => ({
    provinces: provinceSelector(state)
})

const mapDispatchToProps = {
    updateSchool: schoolActionCreators.updateSchool,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalEditSchool)