import React, { useEffect, useRef, useState } from 'react';
import { Affix, Button, Modal } from 'antd';
import { CLASSIFY_NAME, LIST_PROGRESS, MESSAGES, PROP, RESPONSE_CODE, ROUTES } from '../../utils/constants';
import { connect } from 'react-redux';
import { schoolActionCreators } from '../../redux/actions/school';
import models from '../../models';
import { branchsSelector, schoolSelectedSelector, schoolSelectedTabSelector, schoolsSelector } from '../../redux/selectors/school';
import school from '../../models/school';
import { IMAGES } from '../../assets';
import { applyToken, convertDate, getAvatar, getUserRole } from '../../utils/common';
import { provinceSelector } from '../../redux/selectors/location';
import location from '../../models/locaion';
import { getListDistrictApi } from '../../apis/location';
import { countDataSelector, userDataSelector } from '../../redux/selectors/user';
import user from '../../models/user';
import { Collapse } from 'react-collapse';
import ModalAddUserSchool from '../modals/ModalAddUserSchool';
import { useLocation } from 'react-router-dom';
import ModalSchoolDetail from '../modals/ModalSchoolDetail';
import moment from 'moment';
import ModalAddSchool from '../modals/ModalAddSchool';
import { changeBranchApi } from '../../apis/user';
import { showNotification } from '../../utils/notification';
import { userActionCreators } from '../../redux/actions/user';
import EventBus, { BaseEventType, EventBusName } from '../../services/event-bus';
import { Subscription } from 'rxjs';
import ReactDatePicker from 'react-datepicker';
import TagsInput, { TagInputRef } from "../common/TagInput";

const TYPE_FILTER_NAME = {
    LACK_INFO: '1',
    NEXT_DATE_ESTIMATE: '2',
    NEXT_DATE: '3',
    NAME_SALE: '4'
}
interface Props {
    schools: school.SchoolData[];
    selectSchool: typeof schoolActionCreators.selectSchool;
    selectedSchool: string;
    getListSchool: typeof schoolActionCreators.getListSchool;
    selectedTab: string;
    provinces: location.Province[];
    userData: user.User;
    toggleDrawer?: () => void;
    getUserDataSuccess: typeof userActionCreators.getUserDataSuccess;
    branchs: school.Branch[];
    getListBranch: typeof schoolActionCreators.getListBranch;
    changeBranch: typeof userActionCreators.changeBranch;
    isMobile?: boolean;
    countData: user.CountData
}

const NavLeft = React.memo((props: Props) => {
    const { schools, selectSchool, selectedSchool, getListSchool, selectedTab,
        provinces, toggleDrawer, userData, isMobile, branchs, getListBranch, changeBranch, countData } = props;
    const [inputSearch, setInputSearch] = useState('');
    const timeoutRef = useRef<NodeJS.Timeout>()
    const inputRef = useRef<HTMLInputElement>(null)
    const [provinceSelected, setProvinceSelected] = useState<string>('');
    const [districtSelected, setDistrictSelected] = useState<string>('');
    const [districts, setDistricts] = useState<location.District[]>([]);
    const [isOpenCollapseSearch, setIsOpenCollapseSearch] = useState<boolean>(false);
    const [progressStatusSelected, setProgressStatusSelected] = useState<string>('');
    const [classifySelected, setClassifySelected] = useState<string>('');
    const [showModalAddUserSchool, setShowModalAddUserSchool] = useState<boolean>(false);
    const [showModalSchoolDetail, setShowModalSchoolDetail] = useState<boolean>(false);
    const [schoolSelectId, setSchoolSelectId] = useState<string>('');
    const [dateFrom, setDateFrom] = useState<Date | null>(null)
    const [dateTo, setDateTo] = useState<Date | null>(null)
    const [showModalFilter, setShowModalFilter] = useState<boolean>(false);
    const [filterType, setFilterType] = useState<string>('');

    const [showModalAddSchool, setShowModalAddSchool] = useState<boolean>(false)

    const schoolSelect = schools.find(i => i.school_id === schoolSelectId) || {school_id: schoolSelectId} as school.SchoolData

    const subScription = new Subscription();

    const route = useLocation();

    const isPersonalRoute = route.pathname === ROUTES.PERSONAL

    const { isManager, isGeneralManager } = getUserRole()

    const curatorsRef = useRef<TagInputRef>(null)

    const toggleModalAddUserSchool = () => {
        setShowModalAddUserSchool(!showModalAddUserSchool)
    }

    const getCountTotalSchool = () => {
        switch (selectedTab) {
            case '1':
                return countData.countSchoolTodo
            case '2':
                return countData.countSchoolDoing
            case '3':
                return countData.countSchoolWaiting
            case '4':
                return countData.countSchoolCompany
        }
        return 0;
    }

    const handleSelectSchool = (school: school.SchoolData) => () => {
        //check drawer
        if (toggleDrawer) {
            toggleDrawer()
        }
        //
        if (isPersonalRoute) {
            setSchoolSelectId(school.school_id)
            setShowModalSchoolDetail(true)
        }
        else {
            selectSchool(school.school_id)
        }
    }

    const handleSearch = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
        setInputSearch(value)
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
            getListSchool({
                status: selectedTab,
                name: value,
                province_id: provinceSelected,
                district_id: districtSelected,
                progress_status: progressStatusSelected,
                ...getDataFilter()
            })
        }, 800)
    }

    const handleChangeProvince = async ({ target: { value } }: React.ChangeEvent<HTMLSelectElement>) => {
        setProvinceSelected(value)
        setDistrictSelected('')
        getListSchool({
            status: selectedTab,
            name: inputSearch,
            province_id: value,
            progress_status: progressStatusSelected,
            ...getDataFilter()
        })
        const responseProvince = await getListDistrictApi({ province_id: value });
        const districtList = responseProvince.data;
        setDistricts(districtList);
    }

    const handleChangeDistrict = ({ target: { value } }: React.ChangeEvent<HTMLSelectElement>) => {
        setDistrictSelected(value)
        getListSchool({
            status: selectedTab,
            name: inputSearch,
            province_id: provinceSelected,
            district_id: value,
            progress_status: progressStatusSelected,
            ...getDataFilter()
        })
    }

    const handleChangeProgressStatus = ({ target: { value } }: React.ChangeEvent<HTMLSelectElement>) => {
        setProgressStatusSelected(value)
        getListSchool({
            status: selectedTab,
            name: inputSearch,
            province_id: provinceSelected,
            district_id: districtSelected,
            progress_status: value,
            ...getDataFilter()
        })
    }

    const toggleOpenCollapse = () => {
        setIsOpenCollapseSearch(!isOpenCollapseSearch)
    }

    const toggleModalSchoolDetail = () => {
        setShowModalSchoolDetail(!showModalSchoolDetail)
    }

    const handleChangeBranch = ({ target: { value } }: React.ChangeEvent<HTMLSelectElement>) => {
        changeBranch(value)
    }

    const handleChangeClassify = ({ target: { value } }: React.ChangeEvent<HTMLSelectElement>) => {
        setClassifySelected(value)
        getListSchool({
            status: selectedTab,
            name: inputSearch,
            province_id: provinceSelected,
            district_id: districtSelected,
            progress_status: progressStatusSelected,
            classify: value,
            ...getDataFilter()
        })
    }

    const handleChangeDateFrom = (date: Date | null) => {
        getListSchool({
            status: selectedTab,
            name: inputSearch,
            province_id: provinceSelected,
            district_id: districtSelected,
            progress_status: progressStatusSelected,
            classify: classifySelected,
            ...getDataFilter()
        })
        setDateFrom(date)
    }

    const handleChangeDateTo = (date: Date | null) => {
        getListSchool({
            status: selectedTab,
            name: inputSearch,
            province_id: provinceSelected,
            district_id: districtSelected,
            progress_status: progressStatusSelected,
            classify: classifySelected,
            ...getDataFilter()
        })
        setDateTo(date)
    }

    const handleChooseUser = (e: React.MouseEvent<HTMLElement>, schoolData: school.SchoolData) => {
        toggleModalAddUserSchool()
        e.stopPropagation()
        setSchoolSelectId(schoolData.school_id)
    }

    const toggleModalAddSchool = () => setShowModalAddSchool(!showModalAddSchool)

    const onRegisterEventBus = () => {
        const sub = EventBus.getInstance().events.subscribe((res: BaseEventType) => {
            if (res.type === EventBusName.CHANGE_BRANCH) {
                getListSchool({
                    status: selectedTab,
                    name: inputSearch,
                    province_id: provinceSelected,
                    district_id: districtSelected,
                    progress_status: progressStatusSelected,
                    classify: classifySelected, 
                    ...getDataFilter()
                })
            }
            else if (res.type === EventBusName.SELECT_SCHOOL_MODAL) {
                setSchoolSelectId(res.payload)
                setShowModalSchoolDetail(true)
            }
        });
        subScription.add(sub);
    };

    const toggleModalFilter = () => {
        setShowModalFilter(!showModalFilter);
        if(showModalFilter){
            setFilterType('')
        }
    }

    const getDataFilter = () =>{
        const curatorInput = curatorsRef.current?.getValue();
        const listCurator = curatorInput?.map(i => i.user_id) || [];
        return{
            filter_type: filterType,
            date_from: dateFrom ? moment(dateFrom, 'd-M-yyyy').format('YYYY-MM-DD') : undefined,
            date_to: dateTo ? moment(dateTo, 'd-M-yyyy').format('YYYY-MM-DD') : undefined,
            list_curator: listCurator.length > 0 ? listCurator : undefined
        }
    }

    const handleFilter = () =>{
        setShowModalFilter(false);
        getListSchool({
            status: selectedTab,
            name: inputSearch,
            province_id: provinceSelected,
            district_id: districtSelected,
            progress_status: progressStatusSelected,
            classify: classifySelected,
            ...getDataFilter()
        })
    }

    useEffect(() => {
        if (isGeneralManager && branchs.length === 0) {
            getListBranch()
        }
    }, [userData, branchs])

    useEffect(() => {
        if (inputRef.current) inputRef.current.value = ''
        setDistrictSelected('')
        setProvinceSelected('')
        setInputSearch('')
        setFilterType('')
        setClassifySelected('');
    }, [selectedTab])

    useEffect(() => {
        if (!isMobile) {
            onRegisterEventBus();
            return () => {
                subScription?.unsubscribe?.();
            };
        }
    }, [selectedTab, inputSearch, provinceSelected, districtSelected, progressStatusSelected, classifySelected]);

    return (
        <div className='nav-left'>
            <Affix>
                <div className='header-nav'>
                    {isGeneralManager &&
                        <select
                            className='header-branch-select'
                            value={userData.branch_id}
                            onChange={handleChangeBranch}
                            disabled={isPersonalRoute}
                        >
                            {branchs.map((item) =>
                                <option value={item.branch_id} key={item.branch_id}>{item.branch_name}</option>
                            )}
                        </select>
                    }
                </div>
            </Affix>
            <div className='nav-left-list-container'>
                {isPersonalRoute ?
                    <div className='mgt-2' />
                    :
                    <>
                        <div className='line-nav-left'></div>
                        <div className='nav-left-form-container'>
                            <input
                                className='form-control mgb'
                                placeholder='Tìm kiếm tên trường 🔎'
                                onChange={handleSearch}
                                ref={inputRef}
                                value={inputSearch}
                            />
                        </div>
                        <Collapse isOpened={isOpenCollapseSearch}>
                            <div className='row row-select-nav-left'>
                                <div className={!provinceSelected ? 'col-md-12 col-sm-12 col-xs-12' : 'col-md-6 col-sm-6 col-xs-12 pd-select-nav-left'}>
                                    <select
                                        className='form-control'
                                        value={provinceSelected}
                                        onChange={handleChangeProvince}
                                    >
                                        <option value=''>Tất cả Tỉnh/TP</option>
                                        {provinces.map((item, key) =>
                                            <option
                                                value={item.province_id}
                                                key={key}
                                            >
                                                {item.province_name}
                                            </option>
                                        )}
                                    </select>
                                </div>
                                {provinceSelected &&
                                    <div className='col-md-6 col-sm-6 col-xs-12 pd-select-nav-left'>
                                        <select
                                            className='form-control'
                                            value={districtSelected}
                                            onChange={handleChangeDistrict}
                                        >
                                            <option value=''>Tất cả Quận/Huyện</option>
                                            {districts.map((item, key) =>
                                                <option
                                                    value={item.district_id}
                                                    key={key}
                                                >
                                                    {item.district_name}
                                                </option>
                                            )}
                                        </select>
                                    </div>
                                }
                            </div>
                            <div className='row row-select-nav-left'>
                                <select
                                    className='form-control'
                                    onChange={handleChangeProgressStatus}
                                    value={progressStatusSelected}
                                >
                                    <option value=''>Tất cả trạng thái</option>
                                    {LIST_PROGRESS.map((item, key) =>
                                        <option key={key} value={item.status}>{item.name}</option>
                                    )}
                                </select>
                            </div>
                            <div className='row row-select-nav-left'>
                                <select
                                    className='form-control'
                                    onChange={handleChangeClassify}
                                    value={classifySelected}
                                >
                                    <option value=''>Tất cả cấp học</option>
                                    {Object.keys(CLASSIFY_NAME).map((key) =>
                                        <option value={key} key={key}>{CLASSIFY_NAME[key]}</option>
                                    )}
                                </select>
                            </div>
                            {/* <div className='row row-select-nav-left'>
                                <div className='col-md-6 col-sm-6 col-xs-12 pd-select-nav-left'>
                                    <ReactDatePicker
                                        selected={dateFrom}
                                        onChange={handleChangeDateFrom}
                                        dateFormat="d-M-yyyy"
                                        placeholderText="Từ ngày"
                                        showTimeSelect={false}
                                    />
                                </div>
                                <div className='col-md-6 col-sm-6 col-xs-12 pd-select-nav-left'>
                                    <ReactDatePicker
                                        selected={dateTo}
                                        onChange={handleChangeDateTo}
                                        dateFormat="d-M-yyyy"
                                        placeholderText="Đến ngày"
                                        showTimeSelect={false}
                                    />
                                </div>
                            </div> */}
                            <div className='row row-select-nav-left'>
                                <Button className={`full-width ${filterType ? '' :'button-outline'}`} onClick={toggleModalFilter}>Lọc nâng cao</Button>
                            </div>
                        </Collapse>
                        <div className='relative'>
                            <div className='item-center pointer' onClick={toggleOpenCollapse}>
                                {isOpenCollapseSearch ?
                                    <img
                                        alt='chev'
                                        src={IMAGES.CHEV_UP_BLUE}
                                        className='chev-collapse-nav'
                                    />
                                    :
                                    <img
                                        alt='chev'
                                        src={IMAGES.CHEV_DOWN_BLUE}
                                        className='chev-collapse-nav'
                                    />
                                }
                            </div>
                            <div className='count-total-school-container'>
                                <p className='bold'>{schools?.length} / {getCountTotalSchool()}</p>
                            </div>
                        </div>
                    </>
                }
                <div className='nav-left-list-school'>
                    {isManager &&
                        <div className='item-center mgt mgb'>
                            <img
                                alt='icon-add'
                                src={IMAGES.PLUS}
                                className='icon-add-school'
                                onClick={toggleModalAddSchool}
                            />
                        </div>
                    }
                    {schools.map((item, key) =>
                        <div
                            className={`nav-left-school ${item.school_id === selectedSchool ? 'select-school-nav' : ''}`}
                            key={key}
                            onClick={handleSelectSchool(item)}
                        >
                            <p className='bold text-large'>{item.name}</p>
                            <p>{item.address}</p>
                            <p className='text-small'>{item.branch_name} {moment(item.next_estimate_date).isValid() && item.status === '3' ? `- ${convertDate(item.next_estimate_date)}` : ''}</p>
                            {item.list_curator && item.list_curator.length > 0 ?
                                <div className="flex flex-wrap">
                                    {item.list_curator?.map((curator, index) =>
                                        <div onClick={(e) => handleChooseUser(e, item)} key={index} className={`${index > 0 ? 'avatar-collapse-work-container' : ''}`}>
                                            <img
                                                src={getAvatar(curator.avatar)}
                                                className='avatar-list-school'
                                                alt='user-avatar'
                                            />
                                        </div>
                                    )}
                                </div>
                                :
                                <img
                                    alt='user-school'
                                    src={IMAGES.USER_EMPTY}
                                    className='user-school-nav-left'
                                    onClick={(e) => handleChooseUser(e, item)}
                                />
                            }
                        </div>
                    )}
                </div>
            </div>
            <ModalAddUserSchool
                showModal={showModalAddUserSchool}
                onClose={toggleModalAddUserSchool}
                school={schoolSelect}
            />
            <ModalSchoolDetail
                school={schoolSelect}
                showModal={showModalSchoolDetail}
                onClose={toggleModalSchoolDetail}
            />
            <ModalAddSchool
                showModal={showModalAddSchool}
                onClose={toggleModalAddSchool}
            />
            <Modal
                {...PROP.MODAL}
                open={showModalFilter}
                onCancel={toggleModalFilter}
            >
                <div className='row row-select-nav-left'>
                <select
                    className='form-control mgt-3'
                    value={filterType}
                    onChange={({ target: { value } }) => setFilterType(value)}
                >
                    <option>Chọn bộ lọc</option>
                    <option value={TYPE_FILTER_NAME.LACK_INFO}>Các trường thiếu thông tin</option>
                    <option value={TYPE_FILTER_NAME.NEXT_DATE_ESTIMATE}>Ngày tái đo</option>
                    <option value={TYPE_FILTER_NAME.NEXT_DATE}>Ngày đo gần nhất</option>
                    <option value={TYPE_FILTER_NAME.NAME_SALE}>Tên Sale</option>
                </select>
                </div>
                {[TYPE_FILTER_NAME.NEXT_DATE_ESTIMATE, TYPE_FILTER_NAME.NEXT_DATE].includes(filterType)  &&
                    <div className='row row-select-nav-left'>
                        <div className='col-md-6 col-sm-6 col-xs-12 pd-select-nav-left'>
                            <ReactDatePicker
                                selected={dateFrom}
                                onChange={handleChangeDateFrom}
                                dateFormat="d-M-yyyy"
                                placeholderText="Từ ngày"
                                showTimeSelect={false}
                            />
                        </div>
                        <div className='col-md-6 col-sm-6 col-xs-12 pd-select-nav-left'>
                            <ReactDatePicker
                                selected={dateTo}
                                onChange={handleChangeDateTo}
                                dateFormat="d-M-yyyy"
                                placeholderText="Đến ngày"
                                showTimeSelect={false}
                            />
                        </div>
                    </div>
                }
                {filterType === TYPE_FILTER_NAME.NAME_SALE &&
                    <div className='row row-select-nav-left'>
                        <TagsInput ref={curatorsRef} placeholder='Nhập tên Sale' />
                    </div>
                }
                <div className='text-center mgt-3'>
                    <Button onClick={handleFilter}>Xác nhận</Button>
                </div>
            </Modal>
        </div>
    )
})

const mapDispatchToProps = {
    selectSchool: schoolActionCreators.selectSchool,
    getListSchool: schoolActionCreators.getListSchool,
    getUserDataSuccess: userActionCreators.getUserDataSuccess,
    getListBranch: schoolActionCreators.getListBranch,
    changeBranch: userActionCreators.changeBranch,
};

const mapStateToProps = (state: models.GlobalState) => ({
    schools: schoolsSelector(state),
    selectedSchool: schoolSelectedSelector(state),
    selectedTab: schoolSelectedTabSelector(state),
    provinces: provinceSelector(state),
    userData: userDataSelector(state),
    branchs: branchsSelector(state),
    countData: countDataSelector(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(NavLeft)