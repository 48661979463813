import { Button, Modal } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getListMapApi, updateSchoolApi } from "../../apis/school";
import { IMAGES } from "../../assets";
import map from "../../models/map";
import school from "../../models/school";
import { schoolActionCreators } from "../../redux/actions/school";
import { openNewTab } from "../../utils/common";
import { MESSAGES, PROP, RESPONSE_CODE, SCHOOL_STATUS, SCHOOL_STATUS_NAME } from "../../utils/constants";
import { showNotification } from "../../utils/notification";
import DatePicker from "react-datepicker";
import Title from "../common/Title";

type Props = {
    showModal: boolean;
    onClose: () => void;
    school: school.SchoolData;
    maps: map.MapData[];
    updateSchool: typeof schoolActionCreators.updateSchool;
    isEditStatus?: boolean
}

const ModalChangeStatusSchool = (props: Props) => {
    const { showModal, onClose, school, maps, updateSchool, isEditStatus } = props;
    const [inputStatus, setInputStatus] = useState<school.SchoolData['status']>(school.status)
    const [inputNextDate, setInputNextDate] = useState<Date | null>(moment(school.next_date).isValid() ? moment(school.next_date).toDate() : null)
    const [inputNextEstimateDate, setInputEstimateNextDate] = useState<Date | null>(moment(school.next_estimate_date).isValid() ? moment(school.next_estimate_date).toDate() : null)
    const [isPress, setIsPress] = useState<boolean>(false)

    const isWaiting = inputStatus === SCHOOL_STATUS.WAITING

    const handleConfirm = async () => {
        try {
            setIsPress(true);
            const { avatar, name, classify, mst, address, contact_info, base_total,
                class_total, student_total, data_link, zalo_link, ward_id, district_id, province_id, school_id, next_date, next_estimate_date } = school;
            const schoolUpdate = {
                avatar,
                name,
                classify,
                mst,
                address,
                contact_info,
                base_total,
                class_total,
                student_total,
                data_link,
                zalo_link,
                ward_id,
                district_id,
                province_id,
                school_id,
                next_date,
                next_estimate_date,
                status: inputStatus,
            }
            if (isWaiting) {
                if(isEditStatus){
                    schoolUpdate.next_date = moment(inputNextDate).format("YYYY-MM-DD HH:mm")
                    schoolUpdate.next_estimate_date = moment(inputNextDate).add(5, 'months').format("YYYY-MM-DD HH:mm")
                }
                else {
                    schoolUpdate.next_estimate_date = moment(inputNextEstimateDate).format("YYYY-MM-DD HH:mm")
                }
            }
            else delete schoolUpdate.next_date;
            const response = await updateSchoolApi({ school: schoolUpdate, maps: maps });
            setIsPress(false);
            showNotification(response.message, response.code);
            if (response.code === RESPONSE_CODE.SUCCESS) {
                updateSchool({
                    ...school,
                    status: inputStatus,
                    next_date: moment(inputNextDate).format("YYYY-MM-DD HH:mm"),
                    next_estimate_date: isEditStatus ? moment(inputNextDate).add(5, 'months').format("YYYY-MM-DD HH:mm") : moment(inputNextEstimateDate).format("YYYY-MM-DD HH:mm"),
                });
                onClose()
            }
        } catch (error) {
            setIsPress(false);
            showNotification(MESSAGES.ERROR, RESPONSE_CODE.ERROR);
        }
    }

    useEffect(() => {
        if (showModal) {
            setInputStatus(school.status)
            setInputNextDate(moment(school.next_date).isValid() ? moment(school.next_date).toDate() : null)
        }
    }, [showModal])

    return (
        <Modal
            {...PROP.MODAL}
            open={showModal}
            onCancel={onClose}
        >
            {isEditStatus ?
                <select
                    value={inputStatus}
                    className='form-control mgt-2'
                    onChange={({ target: { value } }) => setInputStatus(value as school.SchoolData['status'])}
                >
                    {Object.keys(SCHOOL_STATUS_NAME).map((item, key) =>
                        <option key={key} value={item}>{SCHOOL_STATUS_NAME[item]}</option>
                    )}
                </select>
                :
                <Title>Chọn ngày dự kiến đo lại</Title>
            }
            {isWaiting &&
                <>
                    {isEditStatus ?
                        <DatePicker
                            selected={inputNextDate}
                            onChange={setInputNextDate}
                            timeFormat="HH:mm"
                            timeIntervals={60}
                            timeCaption="time"
                            dateFormat="d-M-yyyy"
                            minDate={new Date()}
                            placeholderText="Chọn ngày tái triển khai"
                            className="text-center mgt-2"
                            showTimeSelect={false}
                        />
                        :
                        <DatePicker
                            selected={inputNextEstimateDate}
                            onChange={setInputEstimateNextDate}
                            timeFormat="HH:mm"
                            timeIntervals={60}
                            timeCaption="time"
                            dateFormat="d-M-yyyy"
                            minDate={new Date()}
                            placeholderText="Chọn ngày dự kiến đo lại"
                            className="text-center mgt-2"
                            showTimeSelect={false}
                        />
                    }
                </>
            }
            <div className="item-center mgt-3">
                <Button onClick={handleConfirm} loading={isPress}>Xác nhận</Button>
            </div>
        </Modal>
    )
}

const mapDispatchToProps = {
    updateSchool: schoolActionCreators.updateSchool,
};

export default connect(undefined, mapDispatchToProps)(ModalChangeStatusSchool)