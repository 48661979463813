import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { IMAGES } from "../assets";
import models from "../models";
import user from "../models/user";
import { userDataSelector } from "../redux/selectors/user";
import { convertDate, getAvatar, getImage64 } from "../utils/common";
import { GENDER_NAME, MESSAGES, RELATION_SHIP_NAME, RESPONSE_CODE, USER_ROLE_NAME } from "../utils/constants";
import { showNotification } from "../utils/notification";
import { getUserDataExtendApi, uploadAvatarApi } from "../apis/user";
import Loading from "../components/common/Loading";
import { userActionCreators } from "../redux/actions/user";

interface Props extends RouteComponentProps {
    userData: user.User;
    getUserDataSuccess: typeof userActionCreators.getUserDataSuccess
}

const User = React.memo((props: Props) => {
    const { userData, getUserDataSuccess } = props;
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [userExtend, setUserExtend] = useState<user.UserExtend>({} as user.UserExtend)

    const avatarRef = useRef<HTMLInputElement>(null)

    const getUserExtend = async () => {
        try {
            const response = await getUserDataExtendApi();
            if (response.code === RESPONSE_CODE.SUCCESS) {
                setUserExtend(response.data || {})
                setIsLoading(false)
            }
        } catch (error) {
            showNotification(MESSAGES.ERROR, RESPONSE_CODE.ERROR);
        }
    }

    const handleUploadAvatar = (event: React.ChangeEvent<HTMLInputElement>) => {
        try {
            const file = event?.target?.files?.[0] as File;
            getImage64(file, async(avatar) => {
                const response = await uploadAvatarApi({avatar})
                showNotification(response.message, response.code)
                if(response.code === RESPONSE_CODE.SUCCESS){
                    getUserDataSuccess({
                        ...userData,
                        avatar: response.data
                    })
                }
                if (avatarRef.current) {
                    avatarRef.current.value = ''
                }
            });
        } catch (error) {
            showNotification(MESSAGES.ERROR, RESPONSE_CODE.ERROR);
            if (avatarRef.current) {
                avatarRef.current.value = ''
            }
        }
    }

    useEffect(() => {
        getUserExtend()
    }, [])

    return (
        <div className="user-container">
            <div className="row">
                <div className="col-md-4 col-sm-12 col-xs-12 flex-column">
                    <div className="item-center flex-column">
                        <div className="relative">
                            <img
                                alt='avatar-user'
                                src={getAvatar(userData.avatar)}
                                className='avatar-user-profile pointer'
                                onClick={() => avatarRef.current?.click()}
                            />
                            <img
                                alt="upload-avatar"
                                src={IMAGES.UPLOAD_AVATAR}
                                className="upload-avatar-img"
                            />
                        </div>
                        <div className="flex item-center">
                            <p className="text-gigantic bold mgt-2">{userData.user_name}</p>
                        </div>
                        <p className="text-large bold mgt">{userData.position_name}</p>
                    </div>
                    <div className="col-info-user">
                        <p className="bold">• Số điện thoại: {userData.phone_number}</p>
                        <p className="bold">• Ngày sinh: {convertDate(userData.birthday)}</p>
                        <p className="bold">• Quê quán: {userData.address}</p>
                        <p className="bold">• Ngày vào công ty: {convertDate(userData.join_date)}</p>
                    </div>
                </div>
                <div className="col-md-8 col-sm-12 col-xs-12">
                    {isLoading ?
                        <Loading />
                        :
                        <div className="box-user-info">
                            <p className='bold'>• BẢNG LƯƠNG:
                                {userExtend.salary_link && <a target="_blank" rel="noreferrer" href={userExtend.salary_link}> Link</a>}
                            </p>
                            <p className='bold'>• SỐ TK NGÂN HÀNG: {userExtend.bank_info}</p>
                            <p className='bold'>• GIỚI TÍNH: {GENDER_NAME[userExtend.gender]}</p>
                            <p className='bold'>• MST CÁ NHÂN: {userExtend.mst}</p>
                            <p className='bold'>• SỐ SỔ BHXH: {userExtend.bhxh}</p>
                            <p className='bold'>• SỐ CCCD: {userExtend.cccd}</p>
                            <p className='bold'>• CHỖ Ở HIỆN NAY: {userExtend.address_current}</p>
                            <p className='bold'>• HỢP ĐỒNG LAO ĐỘNG:
                                {userExtend.contract_link && <a target="_blank" rel="noreferrer" href={userExtend.contract_link}> Link</a>}
                            </p>
                            <p className='bold'>• NGÀY KÝ HĐ: {convertDate(userExtend.contract_date)}</p>
                            <p className='bold'>• NGÀY HẾT HẠN: {convertDate(userExtend.contract_expire_date)}</p>
                            <p className='bold'>• TÌNH TRẠNG HÔN NHÂN: {RELATION_SHIP_NAME[userExtend.relationship]}</p>
                            <p className='bold'>• SỐ CON: {userExtend.children_num}</p>
                            <p className='bold'>• HỒ SƠ XIN VIỆC:
                                {userExtend.job_link && <a target="_blank" rel="noreferrer" href={userExtend.job_link}> Link</a>}
                            </p>
                            <p className='bold'>• NGÀY THÔI VIỆC: {convertDate(userExtend.severance_day)}</p>
                            <p className='bold'>• QUYẾT ĐỊNH THÔI VIỆC:
                                {userExtend.resign_link && <a target="_blank" rel="noreferrer" href={userExtend.resign_link}> Link</a>}
                            </p>
                        </div>
                    }
                </div>
            </div>
            <input
                type='file'
                accept="image/png, image/jpeg"
                hidden={true}
                ref={avatarRef}
                onChange={handleUploadAvatar}
            />
        </div>
    )
})

const mapStateToProps = (state: models.GlobalState) => ({
    userData: userDataSelector(state)
});

const mapDispatchToProps = {
    getUserDataSuccess: userActionCreators.getUserDataSuccess
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(User));