import Work from "./Work";
import Post from "./Post";
import Video from "./Video";
import SchoolLog from "./SchoolLog";
import Book from "./Book";

const ListNewfeed = {
    Work,
    Post,
    Video,
    SchoolLog,
    Book
}

export default ListNewfeed