import { Button } from "antd"
import { useEffect, useRef, useState } from "react"
import { IMAGES } from "../../../assets"
import { COLLAPSE_TYPE, LIST_WORK, MESSAGES, RESPONSE_CODE } from "../../../utils/constants"
import DatePicker from "react-datepicker";
import TagsInput, { TagInputRef } from "../../common/TagInput";
import newfeed from "../../../models/newfeed";
import work from "../../../models/work";
import moment from "moment";
import { connect } from "react-redux";
import { userDataSelector } from "../../../redux/selectors/user";
import models from "../../../models";
import user from "../../../models/user";
import { editWorkApi } from "../../../apis/work";
import { showNotification } from "../../../utils/notification";
import { convertDateTime } from "../../../utils/common";
import TooltipUser from "../../common/TooltipUser";
import ModalWorkChat from "../../modals/ModalWorkChat";
import ModalMarkWork from "../../modals/ModalMarkWork";
import Rating from 'react-rating';
import { Collapse } from 'react-collapse';
import TextareaAutosize from 'react-textarea-autosize';
import EventBus, { BaseEventType, EventBusName, onPushEventBus } from "../../../services/event-bus";
import { Subscription } from "rxjs";

type Props = {
    data: newfeed.NewfeedData & work.Work;
    userData: user.User;
    onChange: (data: any) => void;
    isPersonal?: boolean;
    collapseType?: number;
}

const Work = (props: Props) => {
    const { data, userData, onChange, isPersonal, collapseType } = props;

    const [workName, setWorkName] = useState<string>(data.work_name)
    const [workType, setWorkType] = useState<string | undefined>(data.work_type)
    const [workDesc, setWorkDesc] = useState<string | undefined>(data.work_desc)
    const [workResult, setWorkResult] = useState<string | undefined>(data.work_result)
    const [deadlineFrom, setDeadlineFrom] = useState<Date | null>(moment(data.deadline_from).isValid() ? moment(data.deadline_from).toDate() : null)
    const [deadlineTo, setDeadlineTo] = useState<Date | null>(moment(data.deadline_to).isValid() ? moment(data.deadline_to).toDate() : null)
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [isPress, setIsPress] = useState<boolean>(false)
    const [showModalChat, setShowModalChat] = useState<boolean>(false)
    const [showModalMark, setShowModalMark] = useState<boolean>(false)
    const [isOpenCollapse, setIsOpenCollapse] = useState<boolean>(Boolean(isPersonal) && !Boolean(collapseType && collapseType === COLLAPSE_TYPE.COLLAPSE))

    const execInputRef = useRef<TagInputRef>(null)
    const supportInputRef = useRef<TagInputRef>(null)
    const testAndSupportInputRef = useRef<TagInputRef>(null)

    const toggleShowModalChat = () => setShowModalChat(!showModalChat)
    const toggleShowModalMark = () => setShowModalMark(!showModalMark)

    const isUserAccept = data.list_accept?.some(i => i.user_id === userData.user_id)

    const subScription = new Subscription();

    const handleEdit = async () => {
        try {
            if (!isPress) {
                const execInput = execInputRef.current?.getValue();
                const supportInput = supportInputRef.current?.getValue();
                const testAndSupportInput = testAndSupportInputRef.current?.getValue();
                const listExec = execInput?.map(i => i.user_id) || [];
                const listAccept = testAndSupportInput?.map(i => i.user_id) || [];
                const listSupport = supportInput?.map(i => i.user_id) || [];
                let deadlineFromConvert: string | null = moment(deadlineFrom).format("YYYY-MM-DD HH:mm");
                deadlineFromConvert = deadlineFromConvert === 'Invalid date' ? '' : deadlineFromConvert;
                let deadlineToConvert: string | null = moment(deadlineTo).format("YYYY-MM-DD HH:mm");
                deadlineToConvert = deadlineToConvert === 'Invalid date' ? '' : deadlineToConvert;
                if (workName) {
                    setIsPress(true)
                    setIsEdit(true)
                    const response = await editWorkApi({
                        work_id: data.work_id,
                        work_name: workName,
                        work_type: workType,
                        work_desc: workDesc,
                        work_result: workResult,
                        deadline_from: deadlineFromConvert,
                        deadline_to: deadlineToConvert,
                        list_exec: listExec,
                        list_accept: listAccept,
                        list_support: listSupport,
                        school_id: data.school_id
                    });
                    setIsPress(false)
                    showNotification(response.message, response.code)
                    if (response.code === RESPONSE_CODE.SUCCESS) {
                        setIsEdit(false)
                        onChange({
                            work_id: data.work_id,
                            work_name: workName,
                            work_type: workType,
                            work_desc: workDesc,
                            work_result: workResult,
                            deadline_from: deadlineFromConvert,
                            deadline_to: deadlineToConvert,
                            list_exec: execInput,
                            list_accept: testAndSupportInput,
                            list_support: supportInput,
                            school_id: data.school_id
                        })
                    }
                }
                else showNotification('Tên công việc không được để trống', 0)
            }
        } catch (error) {
            showNotification(MESSAGES.ERROR, RESPONSE_CODE.ERROR)
            setIsPress(false)
        }
    }

    const handleMarkSuccess = (point: number) => {
        setShowModalMark(false)
        onChange({
            point: `${point}`,
            newfeed_id: data.newfeed_id,
            work_id: data.work_id
        })
    }

    const renderEdit = () => {
        if (isEdit) {
            return (
                <Button
                    className="mgr-2"
                    onClick={handleEdit}
                    loading={isPress}
                >
                    Lưu
                </Button>
            )
        }
        return (
            <img
                src={IMAGES.EDIT}
                alt='edit'
                className="edit-work-img mgr-2"
                onClick={() => setIsEdit(true)}
            />
        )
    }

    const toggleCollapse = () => setIsOpenCollapse(!isOpenCollapse)

    const renderButtonChat = () => (
        <div className="relative">
            {data.list_user_id_read.length > 0 && !data.list_user_id_read.includes(userData.user_id) &&
                <div className="dot-chat-work" />
            }
            <Button
                onClick={toggleShowModalChat}
            >
                {data.count_chat} trao đổi
            </Button>
        </div>
    )

    const renderWorkType = () => {
        const workTypeName = LIST_WORK.find(i => i.status === workType)?.name
        return (
            <Button className="button-border mgl">
                {workTypeName}
            </Button>
        )
    }

    const renderListUserWork = () => {
        //remove duplicate & merge
        const listUser = data.list_accept?.concat(data.list_exec || [], data.list_support || []).filter((v, i, a) => a.findIndex(v2 => (v2.user_id === v.user_id)) === i)
        return (
            <div className="flex">
                {listUser?.map((item, key) =>
                    <div key={key} className={`${key > 0 ? 'avatar-collapse-work-container' : ''}`}>
                        <TooltipUser
                            data={item}
                            className='avatar-collapse-work'
                        />
                    </div>
                )}
            </div>
        )
    }

    const renderCollapseAction = () => {
        return (
            <img
                src={isOpenCollapse ? IMAGES.CHEV_DOWN : IMAGES.CHEV_UP}
                alt='chev'
                className="chev-work"
                onClick={toggleCollapse}
            />
        )
    }

    useEffect(() => {
        if (collapseType && collapseType === COLLAPSE_TYPE.COLLAPSE) {
            setIsOpenCollapse(false)
        }
        else if (collapseType) setIsOpenCollapse(true)
    }, [collapseType])

    const onRegisterEventBus = () => {
        const sub = EventBus.getInstance().events.subscribe((res: BaseEventType) => {
            if (res.type === EventBusName.RECEIVE_SOCKET_WORK_CHAT) {
                if(data.work_id === res.payload.work_id){
                    onChange({
                        work_id: data.work_id,
                        count_chat: res.payload.count_chat,
                        newfeed_id: data.newfeed_id,
                        list_user_id_read: res.payload.list_user_id_read
                    })
                }
            }
        });
        subScription.add(sub);
    };

    const handleOnChat = (result: work.Chat) =>{
        onChange({
            work_id: data.work_id,
            count_chat: result.count_chat,
            newfeed_id: data.newfeed_id,
            list_user_id_read: result.list_user_id_read
        })
    }

    const handleClickSchool = (schoolId: string) => () =>{
        onPushEventBus(EventBusName.SELECT_SCHOOL_MODAL, schoolId)
    }

    useEffect(() => {
        onRegisterEventBus();
        return () => {
            subScription?.unsubscribe?.();
        };
    }, [data]);

    return (
        <div className="flex">
            <div className="full-width">
                {!isOpenCollapse ?
                    <div className="row">
                        <div className="col-md-6 col-sm-12 col-xs-12 flex-row">
                            <TooltipUser data={data} />
                            <div className='flex-column mgl-2'>
                                <p className="mgb-0">
                                    <span className="text-large black bold">{data.user_name}</span>
                                    <span className="black"> giao việc </span>
                                    <span className="text-large bold">{workName}</span>
                                </p>
                                <p className="mgb-0">
                                    <span className="gray">Deadline từ </span>
                                    <span className="bold">{deadlineFrom ? convertDateTime(deadlineFrom) : 'chưa đặt'}</span>
                                    <span className="gray"> đến </span>
                                    <span className="bold">{deadlineTo ? convertDateTime(deadlineTo) : 'chưa đặt'}</span>
                                </p>
                                <p
                                    className="mgb-0 pointer"
                                    onClick={handleClickSchool(data.school_id)}
                                >
                                    <span className="gray">Tại </span>
                                    <u><span className="bold">{data.name}</span></u>
                                </p>
                                <div className="mgt">
                                    {renderListUserWork()}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12 col-xs-12">
                            <div className="row-collapse-action">
                                {renderButtonChat()}
                                {renderWorkType()}
                                {renderCollapseAction()}
                            </div>
                        </div>
                    </div>
                    :
                    <div className="row">
                        <div className="col-md-6 col-sm-12 col-xs-12 flex-row">
                            <TooltipUser data={data} />
                            <div className='flex-column mgl-2'>
                                <p className="text-large black bold mgb-0">{data.user_name}</p>
                                <p className="text-time gray">{convertDateTime(data.cr_date_time)}</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12 col-xs-12 flex-row flex-2 align-center flex-end wrap">
                            {data.user_id === userData.user_id &&
                                renderEdit()
                            }
                            {data.point === '0' ?
                                <Button
                                    className={`mgr-2 ${isUserAccept ? '' : 'button-border'}`}
                                    onClick={() => isUserAccept && toggleShowModalMark()}
                                >
                                    {isUserAccept ? 'Nghiệm thu ngay' : 'Chờ nghiệm thu'}
                                </Button>
                                :
                                <>
                                    <div className="align-center mgr mgl">
                                        {/* @ts-ignore */}
                                        <Rating
                                            emptySymbol='fa fa-star-o fa-1x mgr'
                                            fullSymbol='fa fa-star fa-1x mgr'
                                            initialRating={Number(data.point)}
                                            readonly
                                        />
                                    </div>
                                </>
                            }
                            {renderButtonChat()}
                            {renderCollapseAction()}
                        </div>
                    </div>
                }
                <Collapse isOpened={isOpenCollapse}>
                    <div className="row mgt">
                        <div className="col-md-6 col-sm-12 col-xs-12 pdlr">
                            <p className="black">Tên công việc: <span className="danger">*</span></p>
                            <input
                                className="form-control"
                                value={workName}
                                onChange={({ target: { value } }) => setWorkName(value)}
                                disabled={!isEdit}
                            />
                        </div>
                        <div className="col-md-6 col-sm-12 col-xs-12 pdlr">
                            <p className="black">Phân loại:</p>
                            <select
                                className="form-control"
                                value={workType}
                                onChange={({ target: { value } }) => setWorkType(value)}
                                disabled={!isEdit}
                            >
                                {LIST_WORK.map((item, key) =>
                                    <option key={key} value={item.status}>{item.name}</option>
                                )}
                            </select>
                        </div>
                    </div>
                    <div className="mgt">
                        <p className="black">Mô tả công việc:</p>
                        <TextareaAutosize
                            className="textarea-form-control"
                            value={workDesc}
                            onChange={({ target: { value } }) => setWorkDesc(value)}
                            disabled={!isEdit}
                        />
                    </div>
                    <div className="mgt">
                        <p className="black">Mô tả kết quả:</p>
                        <TextareaAutosize
                            className="textarea-form-control"
                            value={workResult}
                            onChange={({ target: { value } }) => setWorkResult(value)}
                            disabled={!isEdit}
                        />
                    </div>
                    <div className="row mgt">
                        <div className="col-md-4 col-sm-6 col-xs-12 pdlr">
                            <p className="black">Người thực hiện</p>
                            <TagsInput ref={execInputRef} data={data.list_exec} disabled={!isEdit} />
                        </div>
                        <div className="col-md-4 col-sm-6 col-xs-12 pdlr">
                            <p className="black">Người hỗ trợ</p>
                            <TagsInput ref={supportInputRef} data={data.list_support} disabled={!isEdit} />
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12 pdlr">
                            <p className="black">Người kiểm tra & nghiệm thu</p>
                            <TagsInput ref={testAndSupportInputRef} data={data.list_accept} disabled={!isEdit} />
                        </div>
                    </div>
                    <div className="row mgt">
                        <div className="col-md-6 col-sm-6 col-xs-12 pdlr">
                            <p className="black">Deadline từ</p>
                            <DatePicker
                                selected={deadlineFrom}
                                onChange={setDeadlineFrom}
                                timeFormat="HH:mm"
                                timeIntervals={60}
                                timeCaption="time"
                                dateFormat="d-M-yyyy HH:mm"
                                minDate={new Date()}
                                showTimeSelect
                                disabled={!isEdit}
                            />
                        </div>
                        <div className="col-md-6 col-sm-6 col-xs-12 pdlr">
                            <p className="black">đến</p>
                            <DatePicker
                                selected={deadlineTo}
                                onChange={setDeadlineTo}
                                timeFormat="HH:mm"
                                timeIntervals={60}
                                timeCaption="time"
                                dateFormat="d-M-yyyy HH:mm"
                                minDate={new Date()}
                                showTimeSelect
                                disabled={!isEdit}
                            />
                        </div>
                    </div>
                </Collapse>

                <ModalWorkChat
                    showModal={showModalChat}
                    onClose={toggleShowModalChat}
                    workId={data.work_id}
                    onChat={handleOnChat}
                />
                <ModalMarkWork
                    showModal={showModalMark}
                    onClose={toggleShowModalMark}
                    workId={data.work_id}
                    onSuccess={handleMarkSuccess}
                />
            </div>
        </div>
    )
}

const mapStateToProps = (state: models.GlobalState) => ({
    userData: userDataSelector(state)
})

export default connect(mapStateToProps)(Work)