import models from "../../models";
import user from "../../models/user";

class UserActions {
    public readonly GET_USER_DATA = 'GET_USER_DATA';
    public readonly GET_USER_DATA_SUCCESS = 'GET_USER_DATA_SUCCESS';
    public readonly GET_USER_DATA_FAILURE = 'GET_USER_DATA_FAILURE';

    public readonly GET_COUNT_DATA = 'GET_COUNT_DATA';
    public readonly GET_COUNT_DATA_SUCCESS = 'GET_COUNT_DATA_SUCCESS';
    public readonly GET_COUNT_DATA_FAILURE = 'GET_COUNT_DATA_FAILURE';

    public readonly CHANGE_BRANCH = 'CHANGE_BRANCH';
    public readonly CHANGE_BRANCH_SUCCESS = 'CHANGE_BRANCH_SUCCESS';
}

export declare namespace userActionTypes {
    type getUserDataActionType = models.TypedActionEmpty<typeof userActions.GET_USER_DATA>;
    type getUserDataSuccessActionType = models.TypedAction<typeof userActions.GET_USER_DATA_SUCCESS, user.User>;
    type getUserDataFailureActionType = models.TypedActionError<typeof userActions.GET_USER_DATA_FAILURE, string>;

    type getCountDataActionType = models.TypedActionEmpty<typeof userActions.GET_COUNT_DATA>;
    type getCountDataSuccessActionType = models.TypedAction<typeof userActions.GET_COUNT_DATA_SUCCESS, user.CountData>;
    type getCountDataFailureActionType = models.TypedActionError<typeof userActions.GET_COUNT_DATA_FAILURE, string>;

    type changeBranchActionType = models.TypedAction<typeof userActions.CHANGE_BRANCH, string>;
    type changeBranchSuccessActionType = models.TypedAction<typeof userActions.CHANGE_BRANCH_SUCCESS, string>;
}

class UserActionsCreators {
    public getUserData = (): userActionTypes.getUserDataActionType => ({ type: userActions.GET_USER_DATA });
    public getUserDataSuccess = (payload: user.User): userActionTypes.getUserDataSuccessActionType => ({ type: userActions.GET_USER_DATA_SUCCESS, payload });
    public getUserDataFailure = (error: string): userActionTypes.getUserDataFailureActionType => ({ type: userActions.GET_USER_DATA_FAILURE, error });

    public getCountData = (): userActionTypes.getCountDataActionType => ({ type: userActions.GET_COUNT_DATA });
    public getCountDataSuccess = (payload: user.CountData): userActionTypes.getCountDataSuccessActionType => ({ type: userActions.GET_COUNT_DATA_SUCCESS, payload });
    public getCountDataFailure = (error: string): userActionTypes.getCountDataFailureActionType => ({ type: userActions.GET_COUNT_DATA_FAILURE, error });

    public changeBranch = (payload: string): userActionTypes.changeBranchActionType => ({ type: userActions.CHANGE_BRANCH, payload });
    public changeBranchSuccess = (payload: string): userActionTypes.changeBranchSuccessActionType => ({ type: userActions.CHANGE_BRANCH_SUCCESS, payload });
}

export const userActions = new UserActions();
export const userActionCreators = new UserActionsCreators();