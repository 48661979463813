import { Button, Modal } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { getListMapApi } from "../../apis/school";
import { IMAGES } from "../../assets";
import map from "../../models/map";
import school from "../../models/school";
import { openNewTab } from "../../utils/common";
import { MESSAGES, PROP, RESPONSE_CODE, SCHOOL_STATUS_NAME } from "../../utils/constants";

type Props = {
    showModal: boolean;
    onClose: () => void;
    maps: map.MapData[]
}

const ModalMap = (props: Props) =>{
    const { showModal, onClose, maps } = props;

    const openMap = (link: string) => () =>{
        openNewTab(link)
    }

    return(
        <Modal
            {...PROP.MODAL}
            open={showModal}
            onCancel={onClose}
        >
            {maps.map((item) =>
                <div className="col-map-container row item-center" key={item.map_id}>
                    <div className="col-md-5 col-sm-5">
                        <div className="box-map">
                            <p className="mgb-0">{item.map_name}</p>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-3 item-center">
                        <Button onClick={openMap(item.map_link)}>Mở</Button>
                    </div>
                </div>
            )}
        </Modal>
    )
}

export default ModalMap