import axios from 'axios';
import { END_POINTS } from '..';
import models from '../../models';
import map from '../../models/map';
import school from '../../models/school';
import user from '../../models/user';
import work from '../../models/work';
const qs = require('qs');

export const createWorkApi = (params: work.CreateWorkRequest) =>{
    const url = END_POINTS.createWork;
	return axios.post(url, qs.stringify(params)).then((res: { data: models.DefaultResponse }) => res.data);
}

export const editWorkApi = (params: work.CreateWorkRequest) =>{
    const url = END_POINTS.editWork;
	return axios.post(url, qs.stringify(params)).then((res: { data: models.DefaultResponse }) => res.data);
}

export const sendChatApi = (params: work.SendChatRequest) =>{
    const url = END_POINTS.sendChat;
	return axios.post(url, qs.stringify(params)).then((res: { data: work.SendChatResponse }) => res.data);
}

export const getListChatApi = (params: {work_id: string}) =>{
    const url = END_POINTS.getListChat;
	return axios.get(url, {params}).then((res: { data: work.ChatResponse }) => res.data);
}

export const markWorkApi = (params: work.MarkWorkRequest) =>{
    const url = END_POINTS.markWork;
	return axios.post(url, qs.stringify(params)).then((res: { data: models.DefaultResponse }) => res.data);
}

export const getListWorkApi = (params: work.GetListWorkRequest) =>{
    const url = END_POINTS.getListWork;
	return axios.get(url, {params}).then((res: { data: work.ListWorkReponse }) => res.data);
}