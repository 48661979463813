import { createSelector } from 'reselect';
import models from '../../models';
import location from '../../models/locaion';
import { LOCATION_INITIAL_STATE } from '../reducers/location';

const locationSelector = (state: models.GlobalState) => state.location;

export const provinceSelector = createSelector(
    locationSelector,
    data => data.provinces as location.Province[] ?? LOCATION_INITIAL_STATE.provinces
)