import { Button, Modal } from "antd";
import { MESSAGES, PROP, RESPONSE_CODE, USER_PROPERTY_NAME } from "../../utils/constants";
import Title from "../common/Title";
import { useEffect, useState } from "react";
import { showNotification } from "../../utils/notification";
import { updateUserApi } from "../../apis/user";
import user from "../../models/user";
import moment from "moment";
import UserExtendForm from "../user/UserExtendForm";

type Props = {
    showModal: boolean;
    onClose: () => void;
    onSuccess: () => void;
    userSelected: user.UserFromManager
}

const ModalAddUser = (props: Props) => {
    const { showModal, onClose, onSuccess, userSelected } = props;
    const [name, setName] = useState<string>('')
    const [phoneNumber, setPhoneNumber] = useState<string>('')
    const [dayBirth, setDayBirth] = useState<string>('1')
    const [monthBirth, setMonthBirth] = useState<string>('1')
    const [yearBirth, setYearBirth] = useState<string>('1998')
    const [address, setAddress] = useState<string>('')
    const [joinDay, setJoinDay] = useState<string>('')
    const [joinMonth, setJoinMonth] = useState<string>('')
    const [joinYear, setJoinYear] = useState<string>('')
    const [roleId, setRoleId] = useState<string>('1')
    const [position, setPosition] = useState<string>('')
    const [userLogin, setUserLogin] = useState<string>('')
    const [passwordDefault, setPasswordDefault] = useState<string>('')

    const [salaryLink, setSalaryLink] = useState<string>('')
    const [bankInfo, setBankInfo] = useState<string>('')
    const [gender, setGender] = useState<string>('')
    const [mst, setMst] = useState<string>('')
    const [bhxh, setBhxh] = useState<string>('')
    const [cccd, setCccd] = useState<string>('')
    const [addressCurrent, setAddressCurrent] = useState<string>('')
    const [contractLink, setContractLink] = useState<string>('')
    const [contractDay, setContractDay] = useState<string>('')
    const [contractMonth, setContractMonth] = useState<string>('')
    const [contractYear, setContractYear] = useState<string>('')
    const [contractExpireDay, setContractExpireDay] = useState<string>('')
    const [contractExpireMonth, setContractExpireMonth] = useState<string>('')
    const [contractExpireYear, setContractExpireYear] = useState<string>('')
    const [relationship, setRelationship] = useState<string>('')
    const [childrenNum, setChildrenNum] = useState<string>('')
    const [jobLink, setJobLink] = useState<string>('')
    const [severanceDay, setSeveranceDay] = useState<string>('')
    const [severanceMonth, setSeveranceMonth] = useState<string>('')
    const [severanceYear, setSeveranceYear] = useState<string>('')
    const [resignLink, setResignLink] = useState<string>('')
    const [userProperty, setUserProperty] = useState<string>('0')

    const [isPress, setIsPress] = useState<boolean>(false)

    const handleAddUser = async () => {
        const userLoginConvert = userLogin.replace(/ /g, '')
        if (!name.trim()) return showNotification('Tên giám đốc không được để trống', RESPONSE_CODE.ERROR);
        if (!phoneNumber.trim()) return showNotification('Số điện thoại không được để trống', RESPONSE_CODE.ERROR);
        if (!position.trim()) return showNotification('Chức vụ không được để trống', RESPONSE_CODE.ERROR);
        if (!address.trim()) return showNotification('Quê quán không được để trống', RESPONSE_CODE.ERROR);
        if (!userLoginConvert.trim()) return showNotification('ID Đăng nhập không được để trống', RESPONSE_CODE.ERROR);
        if (!passwordDefault.trim()) return showNotification('Mật khẩu ban đầu không được để trống', RESPONSE_CODE.ERROR);
        try {
            if (!isPress) {
                setIsPress(true)
                const response = await updateUserApi({
                    user: {
                        user_id: userSelected.user_id,
                        user_name: name.trim(),
                        phone_number: phoneNumber.trim(),
                        birthday: yearBirth + '-' + monthBirth + '-' + dayBirth,
                        join_date: moment(joinYear + '-' + joinMonth + '-' + joinDay, "YYYY-MM-DD").isValid() ? joinYear + '-' + joinMonth + '-' + joinDay : '',
                        user_login: userLoginConvert,
                        user_pass_default: passwordDefault,
                        role_id: roleId,
                        address,
                        position_name: position
                    },
                    user_extends: {
                        user_property: userProperty,
                        salary_link: salaryLink,
                        bank_info: bankInfo,
                        gender: gender,
                        mst,
                        bhxh,
                        cccd,
                        address_current: addressCurrent,
                        contract_link: contractLink,
                        contract_date: moment(contractYear + '-' + contractMonth + '-' + contractDay, "YYYY-MM-DD").isValid() ? contractYear + '-' + contractMonth + '-' + contractDay : '',
                        contract_expire_date: moment(contractExpireYear + '-' + contractExpireMonth + '-' + contractExpireDay, "YYYY-MM-DD").isValid() ? contractExpireYear + '-' + contractExpireMonth + '-' + contractExpireDay : '',
                        relationship,
                        children_num: childrenNum,
                        job_link: jobLink,
                        severance_day: moment(severanceYear + '-' + severanceMonth + '-' + severanceDay, "YYYY-MM-DD").isValid() ? severanceYear + '-' + severanceMonth + '-' + severanceDay : '',
                        resign_link: resignLink,
                    }
                });
                showNotification(response.message, response.code)
                setIsPress(false)
                if (response.code === RESPONSE_CODE.SUCCESS) {
                    if (userSelected?.user_id) {
                        onClose()
                    }
                    onSuccess();
                    setData({} as user.UserFromManager)
                }
            }
        } catch (error) {
            setIsPress(false)
            showNotification(MESSAGES.ERROR, RESPONSE_CODE.ERROR)
        }
    }

    const setData = (userSelect: user.UserFromManager) => {
        setName(userSelect?.user_name || '')
        setPhoneNumber(userSelect?.phone_number || '')
        setDayBirth(userSelect?.birthday ? moment(userSelect?.birthday, "YYYY-MM-DD").format('D') : '1')
        setMonthBirth(userSelect?.birthday ? moment(userSelect?.birthday, "YYYY-MM-DD").format('M') : '1')
        setYearBirth(userSelect?.birthday ? moment(userSelect?.birthday, "YYYY-MM-DD").format('YYYY') : '1998')
        setAddress(userSelect?.address || '')
        setJoinDay(userSelect?.join_date ? moment(userSelect?.join_date, "YYYY-MM-DD").format('D') : '')
        setJoinMonth(userSelect?.join_date ? moment(userSelect?.join_date, "YYYY-MM-DD").format('M') : '')
        setJoinYear(userSelect?.join_date ? moment(userSelect?.join_date, "YYYY-MM-DD").format('YYYY') : '')
        setUserLogin(userSelect?.user_login || '')
        setPasswordDefault(userSelect?.user_pass_default || '')
        setRoleId(userSelect.role_id || '1')
        setPosition(userSelect?.position_name || '')
        //
        setUserProperty(userSelect?.user_property || '0')
        setSalaryLink(userSelect?.salary_link || '')
        setBankInfo(userSelect?.bank_info || '')
        setGender(userSelect?.gender || '')
        setMst(userSelect?.mst || '')
        setBhxh(userSelect?.bhxh || '')
        setCccd(userSelect?.cccd || '')
        setAddressCurrent(userSelect?.address_current || '')
        setContractLink(userSelect?.contract_link || '')
        setContractDay(userSelect?.contract_date ? moment(userSelect?.contract_date, "YYYY-MM-DD").format('D') : '')
        setContractMonth(userSelect?.contract_date ? moment(userSelect?.contract_date, "YYYY-MM-DD").format('M') : '')
        setContractYear(userSelect?.contract_date ? moment(userSelect?.contract_date, "YYYY-MM-DD").format('YYYY') : '')
        setContractExpireDay(userSelect?.contract_expire_date ? moment(userSelect?.contract_expire_date, "YYYY-MM-DD").format('D') : '')
        setContractExpireMonth(userSelect?.contract_expire_date ? moment(userSelect?.contract_expire_date, "YYYY-MM-DD").format('M') : '')
        setContractExpireYear(userSelect?.contract_expire_date ? moment(userSelect?.contract_expire_date, "YYYY-MM-DD").format('YYYY') : '')
        setRelationship(userSelect?.relationship || '')
        setChildrenNum(userSelect?.children_num || '')
        setJobLink(userSelect?.job_link || '')
        setSeveranceDay(userSelect?.severance_day ? moment(userSelect?.severance_day, "YYYY-MM-DD").format('D') : '')
        setSeveranceMonth(userSelect?.severance_day ? moment(userSelect?.severance_day, "YYYY-MM-DD").format('M') : '')
        setSeveranceYear(userSelect?.severance_day ? moment(userSelect?.severance_day, "YYYY-MM-DD").format('YYYY') : '')
        setResignLink(userSelect?.resign_link || '')
    }

    useEffect(() => {
        setData(userSelected)
    }, [userSelected])

    return (
        <Modal
            {...PROP.MODAL}
            open={showModal}
            onCancel={onClose}
            width={'160vh'}
        >
            <Title>{userSelected?.user_id ? 'Sửa nhân sự' : 'Thêm nhân sự'}</Title>
            <div className="row">
                <div className="col-md-6 col-sm-12 col-xs-12 row-modal-add-user">
                    <div className="row align-center mgt-2">
                        <div className="col-md-3 col-sm-12 col-xs-12">
                            <p className="text-edit-school">Tên nhân sự:<span className="danger">*</span></p>
                        </div>
                        <div className="col-md-9 col-sm-12 col-xs-12">
                            <input
                                className="form-control"
                                value={name}
                                onChange={({ target: { value } }) => setName(value)}
                            />
                        </div>
                    </div>
                    <div className="row align-center mgt">
                        <div className="col-md-3 col-sm-12 col-xs-12">
                            <p className="text-edit-school">Số điện thoại:<span className="danger">*</span></p>
                        </div>
                        <div className="col-md-9 col-sm-12 col-xs-12">
                            <input
                                className="form-control"
                                value={phoneNumber}
                                onChange={({ target: { value } }) => setPhoneNumber(value)}
                            />
                        </div>
                    </div>
                    <div className="row align-center mgt">
                        <div className="col-md-3 col-sm-12 col-xs-12">
                            <p className="text-edit-school">Chức vụ: <span className="danger">*</span></p>
                        </div>
                        <div className="col-md-9 col-sm-12 col-xs-12">
                            <input
                                className="form-control"
                                value={position}
                                onChange={({ target: { value } }) => setPosition(value)}
                            />
                        </div>
                    </div>
                    <div className="row align-center mgt">
                        <div className="col-md-3 col-sm-12 col-xs-12">
                            <p className="text-edit-school">Ngày sinh:<span className="danger">*</span></p>
                        </div>
                        <div className="col-md-9 col-sm-12 col-xs-12">
                            <div className='row' style={{ flexDirection: 'row' }}>
                                <div className='col-xs-4'>
                                    <select
                                        className='form-control input-authen'
                                        style={{ width: '99%' }}
                                        onChange={({ target: { value } }) => setDayBirth(value)}
                                        value={dayBirth}
                                    >
                                        {new Array(31).fill(undefined).map((item, key) => (
                                            <option value={key + 1} key={key + 1}>Ngày {key + 1}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className='col-xs-4'>
                                    <select
                                        className='form-control input-authen'
                                        style={{ width: '99%' }}
                                        onChange={({ target: { value } }) => setMonthBirth(value)}
                                        value={monthBirth}
                                    >
                                        {new Array(12).fill(undefined).map((item, key) => (
                                            <option value={key + 1} key={key + 1}>Tháng {key + 1}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className='col-xs-4'>
                                    <select
                                        className='form-control input-authen'
                                        style={{ width: '99%' }}
                                        onChange={({ target: { value } }) => setYearBirth(value)}
                                        value={yearBirth}
                                    >
                                        {new Array(70).fill(undefined).map((item, key) => (
                                            <option value={key + 1950} key={key + 1950}>Năm {key + 1950}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row align-center mgt">
                        <div className="col-md-3 col-sm-12 col-xs-12">
                            <p className="text-edit-school">Ngày vào công ty:</p>
                        </div>
                        <div className="col-md-9 col-sm-12 col-xs-12">
                            <div className='row' style={{ flexDirection: 'row' }}>
                                <div className='col-xs-4'>
                                    <select
                                        className='form-control input-authen'
                                        style={{ width: '99%' }}
                                        onChange={({ target: { value } }) => setJoinDay(value)}
                                        value={joinDay}
                                    >
                                        <option value=''>Chọn ngày</option>
                                        {new Array(31).fill(undefined).map((item, key) => (
                                            <option value={key + 1} key={key + 1}>Ngày {key + 1}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className='col-xs-4'>
                                    <select
                                        className='form-control input-authen'
                                        style={{ width: '99%' }}
                                        onChange={({ target: { value } }) => setJoinMonth(value)}
                                        value={joinMonth}
                                    >
                                        <option value=''>Chọn tháng</option>
                                        {new Array(12).fill(undefined).map((item, key) => (
                                            <option value={key + 1} key={key + 1}>Tháng {key + 1}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className='col-xs-4'>
                                    <select
                                        className='form-control input-authen'
                                        style={{ width: '99%' }}
                                        onChange={({ target: { value } }) => setJoinYear(value)}
                                        value={joinYear}
                                    >
                                        <option value=''>Chọn năm</option>
                                        {new Array(50).fill(undefined).map((item, key) => (
                                            <option value={key + 2010} key={key + 2010}>Năm {key + 2010}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row align-center mgt">
                        <div className="col-md-3 col-sm-12 col-xs-12">
                            <p className="text-edit-school">Quê quán:</p>
                        </div>
                        <div className="col-md-9 col-sm-12 col-xs-12">
                            <input
                                className="form-control"
                                value={address}
                                onChange={({ target: { value } }) => setAddress(value)}
                            />
                        </div>
                    </div>
                    <div className="row align-center mgt">
                        <div className="col-md-3 col-sm-12 col-xs-12">
                            <p className="text-edit-school">Quyền hệ thống:<span className="danger">*</span></p>
                        </div>
                        <div className="col-md-9 col-sm-12 col-xs-12">
                            <select
                                className='form-control select-left'
                                style={{ width: '99%' }}
                                onChange={({ target: { value } }) => setRoleId(value)}
                                value={roleId}
                            >
                                <option value='1'>Nhân sự</option>
                                <option value='2'>Trợ lý giám đốc</option>
                            </select>
                        </div>
                    </div>
                    <div className="row align-center mgt">
                        <div className="col-md-3 col-sm-12 col-xs-12">
                            <p className="text-edit-school">Tính chất nhân sự:</p>
                        </div>
                        <div className="col-md-9 col-sm-12 col-xs-12">
                            <select
                                className='form-control select-left'
                                style={{ width: '99%' }}
                                onChange={({ target: { value } }) => setUserProperty(value)}
                                value={userProperty}
                            >
                                {Object.keys(USER_PROPERTY_NAME).map((key) => 
                                    <option value={key}>{USER_PROPERTY_NAME[key]}</option>
                                )}
                            </select>
                        </div>
                    </div>
                    <div style={{ height: '10vh' }} />
                    <div className="row align-center mgt">
                        <div className="col-md-3 col-sm-12 col-xs-12">
                            <p className="text-edit-school">ID Đăng nhập:<span className="danger">*</span></p>
                        </div>
                        <div className="col-md-9 col-sm-12 col-xs-12">
                            <input
                                className="form-control"
                                value={userLogin}
                                onChange={({ target: { value } }) => setUserLogin(value)}
                            />
                        </div>
                    </div>
                    <div className="row align-center mgt">
                        <div className="col-md-3 col-sm-12 col-xs-12">
                            <p className="text-edit-school">Mật khẩu ban đầu:<span className="danger">*</span></p>
                        </div>
                        <div className="col-md-9 col-sm-12 col-xs-12">
                            <input
                                className="form-control"
                                value={passwordDefault}
                                onChange={({ target: { value } }) => setPasswordDefault(value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-sm-12 col-xs-12 row-modal-add-user">
                    <UserExtendForm
                        salaryLink={salaryLink}
                        setSalaryLink={setSalaryLink}
                        bankInfo={bankInfo}
                        setBankInfo={setBankInfo}
                        gender={gender}
                        setGender={setGender}
                        mst={mst}
                        setMst={setMst}
                        bhxh={bhxh}
                        setBhxh={setBhxh}
                        cccd={cccd}
                        setCccd={setCccd}
                        addressCurrent={addressCurrent}
                        setAddressCurrent={setAddressCurrent}
                        contractLink={contractLink}
                        setContractLink={setContractLink}
                        contractDay={contractDay}
                        setContractDay={setContractDay}
                        contractMonth={contractMonth}
                        setContractMonth={setContractMonth}
                        contractYear={contractYear}
                        setContractYear={setContractYear}
                        contractExpireDay={contractExpireDay}
                        setContractExpireDay={setContractExpireDay}
                        contractExpireMonth={contractExpireMonth}
                        setContractExpireMonth={setContractExpireMonth}
                        contractExpireYear={contractExpireYear}
                        setContractExpireYear={setContractExpireYear}
                        relationship={relationship}
                        setRelationship={setRelationship}
                        childrenNum={childrenNum}
                        setChildrenNum={setChildrenNum}
                        jobLink={jobLink}
                        setJobLink={setJobLink}
                        severanceDay={severanceDay}
                        setSeveranceDay={setSeveranceDay}
                        severanceMonth={severanceMonth}
                        setSeveranceMonth={setSeveranceMonth}
                        severanceYear={severanceYear}
                        setSeveranceYear={setSeveranceYear}
                        resignLink={resignLink}
                        setResignLink={setResignLink}
                    />
                </div>
            </div>
            <div className="item-center mgt-4">
                <Button onClick={handleAddUser} loading={isPress}>Lưu</Button>
            </div>
        </Modal>
    )
}

export default ModalAddUser