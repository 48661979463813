import React from "react";
import {Route, Redirect} from "react-router-dom";
import {isExistToken} from '../../utils/common';
import { ROUTES } from "../../utils/constants";

interface Props {
    children: React.ReactNode;
    path: string;
}

const PrivateRoute = ({ children, path, ...rest }: Props) => {
    return (
        <Route
            {...rest}
            path={path}
            render={({ location }) =>
                isExistToken() ? (children) : (<Redirect to={{pathname: ROUTES.LOGIN, state: { from: location }}}/>)
            }
        />
    );
}

export default React.memo(PrivateRoute);